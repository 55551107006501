import configJson from './config.json';

class Config {
  BRAND_NAME: string = '';
  API_DOCUMENTATION_URL: string = '';

  constructor(config?: any) {
    if (config) {
      Object.assign(this, config);
    }
  }
}

const config = new Config(configJson);

export default config;

export const BRAND_NAME = config.BRAND_NAME;

export const API_DOCUMENTATION_URL = config.API_DOCUMENTATION_URL;
