import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';
import useResizeObserver from 'use-resize-observer';

import {entities} from '../../../../api/proto';
import useOpacityTransition from '../../../../hooks/useOpacityTransition';
import Channel from '../../../../stores/Channel';
import ChannelScreenType from '../../../../stores/Channel/ChannelScreenType';
import ChannelCreationStepType from '../ChannelCreationStepType';
import TelegramCreationModalHeader from '../TelegramCreationForm/TelegramCreationModalHeader';
import InstagramFormsWrapper from './InstagramFormsWrapper';

interface InstagramCreationFormProps {
  title?: string;
  subTitle?: string;
  modalHeader?: boolean;
  reactivationMode?: boolean;
  onChangeStep?: (step: ChannelCreationStepType) => void;
  onClose?: () => void;
  type: entities.OzekonChannelType;
  onChangeType?: (type: entities.OzekonChannelType) => void;
  channel?: Channel;
  channelScreenType?: ChannelScreenType;
}

export const InstagramCreationForm: React.FC<InstagramCreationFormProps> = observer(({
  title,
  subTitle,
  modalHeader,
  reactivationMode,
  //onChangeStep,
  onClose,
  //type,
  //onChangeType,
  channel,
  channelScreenType,
}) => {

  const {ref: innerRef, height} = useResizeObserver<HTMLDivElement>();

  const initialStep = channelScreenType === ChannelScreenType.CALLBACK ? ChannelCreationStepType.TWO_FACTOR_AUTH : ChannelCreationStepType.CHANNEL_CONFIRMATION;
  const [stepNumber, setStepNumber] = React.useState<number>(initialStep);
  const [newChannel, setNewChannel] = React.useState<Channel | null | undefined>(channel);

  const transitions = useOpacityTransition(stepNumber);

  const handleChangeStep = (nextStep: ChannelCreationStepType, channel?: Channel | null) => {
    setStepNumber(nextStep);
    if (channel) {
      setNewChannel(channel);
    }
  };

  return (
    <div
      className={cn('channel-dialog-form', `page-${stepNumber}`, {
        reactivation: reactivationMode,
        'with-header': modalHeader,
        'with-title': title,
      })}
    >
      <div style={{height: height && height > 40 ? height : undefined}} className="channel-dialog-form__body">
        {transitions((styles, step) => (
          <animated.div
            ref={step === stepNumber ? innerRef : null}
            style={styles}
            className="animated-form"
          >
            {modalHeader ? <TelegramCreationModalHeader onClose={onClose} /> : null}
            <InstagramFormsWrapper
              title={title}
              subTitle={subTitle}
              step={step}
              channel={newChannel}
              onChangeStep={handleChangeStep}
              onClose={onClose}
            />
          </animated.div>
        ))}
      </div>
    </div>
  );
});

export default InstagramCreationForm;
