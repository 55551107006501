import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import useWorkspaceNavigate from '../../../../hooks/useWorkspaceNavigate';
import Paths from '../../../../routes/Paths';
import useStore from '../../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../../utils/format';
import formatMoney from '../../../../utils/format/formatMoney';
import PaymentMethodIcon from '../PaymentMethodIcon';
import useTariff from './useTariff';

import {ReactComponent as SuccessIcon} from '../../../../assets/images/success-circle.svg';

interface PaymentSuccessFormProps {
  className?: string;
  style?: React.CSSProperties;
  tariffId?: string | null;
  onClose?: () => void;
}

export const PaymentSuccessForm = observer(({
  className,
  style,
  tariffId,
  onClose,
}: PaymentSuccessFormProps) => {
  const {t} = useTranslation();

  const {activeWorkspace: {billing}, anal} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const tariff = useTariff(tariffId);

  React.useEffect(() => {
    if (tariff) {
      anal.tariffPaymentSuccess(tariff);
    }
  }, [
    anal,
    tariff,
  ]);

  const handleGoHomeClick = () => {
    workspaceNavigate(Paths.Chat);
    onClose?.();
  };

  const handleCloseClick = () => {
    workspaceNavigate(Paths.Billing);
    onClose?.();
  };

  return (
    <div
      className={cn(
        'bg-background-primary d-inline-flex flex-column radius-8 border-primary py-5',
        className,
      )}
      style={style}
    >
      <SuccessIcon />

      <div className="px-6 d-inline-flex align-items-center">
        <span className="h3-medium">
          {t('settings_billing_payment_successful_title')}
        </span>
      </div>

      <div className="px-6 mt-1-5 d-inline-flex align-items-center">
        {t('settings_billing_payment_successful_subtitle')}
      </div>

      <div className="px-6 mt-5">
        <div className="d-flex flex-column border-primary radius-8">

          <div className="d-flex flex-column flex-sm-row border-primary-bottom p-5">

            <div className="d-flex flex-column pr-5">
              <div className="body3-regular color-body-secondary">
                {t('settings_billing_payment_successful_current_plan')}
              </div>
              <div>
                {tariff?.name}
              </div>
            </div>

            <div className="d-flex flex-column mt-5 mt-sm-0">
              <div className="body3-regular color-body-secondary">
                {t('settings_billing_payment_successful_next_billing_date')}
              </div>
              <div>
                {billing?.expiresAt?.greaterThan(Long.ZERO) ? formatDateTime(billing.expiresAt, true, BILLING_DATE_FORMAT) : null}
              </div>
            </div>

          </div>

          <div className="d-flex flex-column p-5">

            <div className="d-flex flex-column pr-5">
              <div className="body3-regular color-body-secondary">
                {t('settings_billing_payment_successful_payment_method')}
              </div>

              <div>
                {billing.cardBrand || billing.cardLastDigits ? (
                  <div className="d-inline-flex flex-row align-items-center border-primary radius-8 pr-3 mt-3">
                    {billing.cardBrand ? (
                      <PaymentMethodIcon
                        className="mr-3"
                        type={billing.cardBrand}
                        bordered
                      />
                    ) : null}
                    {billing.cardLastDigits ? (
                      <span className="body2-regular">
                        **** {billing.cardLastDigits}
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex flex-column mt-5">
              <div className="body3-regular color-body-secondary">
                {t('settings_billing_payment_successful_total_amount')}
              </div>
              <div className="body1-bold">
                {formatMoney({
                  value: billing.currentTariff?.price,
                  currency: billing.currentTariff?.currency,
                })}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="px-6 mt-5 d-flex flex-column flex-sm-row">
        <Button
          className="mr-4"
          variant="contained"
          size="large"
          onClick={handleGoHomeClick}
        >
          {t('settings_billing_payment_go_home')}
        </Button>

        <Button
          className="mt-3 mt-sm-0"
          variant="outlined"
          color="tertiary"
          size="large"
          onClick={handleCloseClick}
        >
          {t('settings_billing_payment_view_billing')}
        </Button>
      </div>

    </div>
  );
});

export default PaymentSuccessForm;