import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';

import {
  IMCChannelShownFields,
  IMCGetChannelResponse,
  IMCMethod,
  IMCMethodResponse,
  IMCWebWidgetConfig,
  MCDropChannel,
  MCDropChannelResponse,
  MCEditChannel,
  MCGetChannels,
  MCMethod,
  MCMethodGetChatsPreview,
  MCMethodMarkReadAll,
  MCMethodMarkReadAllResponse,
  MCMethodResponse,
  api,
  entities,
} from '../../api/proto';
import {base64ToUint8Array, equalUint8Arrays, uint8ArrayToBase64, uint8ArrayToUuid} from '../../utils/arrayUtils';
import browserStorage from '../../utils/browserStorage';
import APILayer, {APIRequestOptions} from '../APILayer';
import {ChatsAssignedType} from '../Chat/utils/getChatAssignedType';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import Channel from './Channel';
import Instagram from './platforms/Instagram';
import Telegram from './platforms/Telegram';
import TelegramBot from './platforms/TelegramBot';
import TelegramEmulator from './platforms/TelegramEmulator';
import WebWidget from './platforms/WebWidget';
import {filterAvailableChannelByType} from './utils/channel';
import getChannelActionError from './utils/getChannelActionError';


const TELEGRAM_PIN_LENGTH = 5;

export const getChannelPinCodeLength = (): number => {
  return TELEGRAM_PIN_LENGTH;
};

const SELECTED_CHANNEL_STORE_KEY = 'selectedChannel';

export const genZeroUint8Array = (): Uint8Array => {
  const id = new Uint8Array(16);
  id[id.length - 1] = 1;
  return id;
};

type OneOfChannelsResponse = MCMethodResponse[keyof MCMethodResponse];

export class ChannelsStore extends APILayer {
  @observable isInit = false;

  @action setInit = (isInit: boolean) => {
    this.isInit = isInit;
  };

  constructor(public workspace: WorkspaceStore) {
    super(workspace.app);
    makeObservable(this);
    this.restore();
  }


  public channelsRequest = async <T = OneOfChannelsResponse>(
    data: IMCMethod,
    responseType: keyof MCMethodResponse | null = null,
    options?: APIRequestOptions,
  ) => {
    const response = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: {
          workspaceId: this.workspace.id,
          action: new MCMethod({
            ...data,
          }),
        },
      },
      'channelsResponse',
      options,
    );

    let res: T | null | undefined = null;
    const methodResponse: IMCMethodResponse | null | undefined = response.res?.methodResponse;
    if (responseType && methodResponse?.[responseType]) {
      res = methodResponse[responseType] as T;
    }

    const error = response.error ? response.error : getChannelActionError(methodResponse);

    return {error, res};
  };

  @observable managingChannel: Channel | null = null;

  @action setManagingChannel = (managingChannel: Channel | null) => {
    this.managingChannel = managingChannel;
  };

  @observable public selectedChannelID: string | null = null;

  @observable public selectedChannel: Channel | null = null;

  @computed get allChannelsUnreadCount(): number {
    return this.list.reduce((sum, channel) => sum + channel.unreadNumber, 0);
  }

  @action private setSelectedChannel = (selectedChannel: Channel | null) => {
    if (equalUint8Arrays(this.selectedChannel?.id, selectedChannel?.id)) {
      return;
    }
    console.debug('%c----->ChannelsStore.setSelectedChannel', 'color: blue');
    this.selectedChannel = selectedChannel;
    this.saveSelectedChannelId(selectedChannel);
  };

  public selectChannel = async (
    selectedChannel: Channel | null,
    chatID?: Long | null,
    status?: MCMethodGetChatsPreview.MCChatStatus | null,
    assignedType?: ChatsAssignedType,
  ) => {
    console.debug(
      `%c----->ChannelsStore.selectChannel ${uint8ArrayToUuid(selectedChannel?.id)} ${chatID?.toString()} status=${status?.toString()} assignedType=${assignedType?.toString()}`,
      'color: #afb0b6',
    );
    this.setSelectedChannel(selectedChannel);

    if (selectedChannel) {
      await this.workspace.chatsLoader.select([selectedChannel?.id], chatID, status, assignedType);
    } else {
      await this.workspace.chatsLoader.select(
        this.availableChannelsIds,
        chatID,
        MCMethodGetChatsPreview.MCChatStatus.S_ALL,
      );
    }
  };

  public reactivateChannel = async () => {
    console.debug(`%c----->ChannelsStore.reactivate channel ${uint8ArrayToUuid(this.selectedChannel?.id)}`, 'color: #afb0b6');
    if (this.selectedChannel) {
      await this.workspace.chatsLoader.select([this.selectedChannel?.id]);
    } else {
      await this.workspace.chatsLoader.select(
        this.availableChannelsIds,
        null,
        MCMethodGetChatsPreview.MCChatStatus.S_ALL,
      );
    }
  };

  private saveSelectedChannelId = (channel?: Channel | null) => {
    try {
      const channelID = channel?.id ? uint8ArrayToBase64(channel?.id) : null;
      this.selectedChannelID = channelID;
      browserStorage.session(SELECTED_CHANNEL_STORE_KEY, channelID);
    } catch (e) {
      console.log(e);
    }
  };

  private restore = () => {
    try {
      const channelId = browserStorage.session(SELECTED_CHANNEL_STORE_KEY) || '';
      if (channelId) {
        this.selectedChannelID = channelId;
      }
    } catch (e) {
      console.debug(e);
    }
  };

  @action updateManagingChannel = () => {
    if (this.managingChannel) {
      const foundChannel = this.findChannel(this.managingChannel.id);

      if (foundChannel) {
        this.managingChannel = foundChannel;
      }
    }
  };

  @action setSelectedChannelById = (channelId: string, chatID?: Long | null) => {
    const channel = this.channelsMap.get(channelId);

    if (channel) {
      this.selectChannel(channel, chatID);
    }
  };

  @action setSelectedInternalChannel = () => {
    const internalChannel = this.availableChannels.find((c) => c.type);
    if (internalChannel) {
      this.selectChannel(internalChannel);
    }
  };

  @observable list: Channel[] = [];

  @observable channelsMap: Map<string, Channel> = new Map();

  @computed get availableChannels() {
    return this.list.filter(filterAvailableChannelByType);
  }

  @computed get availableChannelsIds() {
    return this.availableChannels.map((c) => c.id);
  }

  @action setChannels = (channels: Channel[]) => {
    this.list = channels;
  };

  @computed get availableExternalChannels(): Channel[] {
    return this.list.filter(filterAvailableChannelByType);
  }

  public findChannel = (channelId?: Uint8Array | null): Channel | null => {
    return channelId ? this.channelsMap.get(uint8ArrayToBase64(channelId)) || null : null;
  };

  public getType = (channelId?: Uint8Array | null): entities.OzekonChannelType | null => {
    return this.findChannel(channelId)?.type || null;
  };

  public getChannels = (type?: entities.OzekonChannelType | null) => {
    return this.list.filter((c) => c.type === type);
  };

  public load = async () => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChannels: new MCGetChannels({
              fetch: true,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const channelsRes = res?.methodResponse;

    this.processLoadChannels_(channelsRes);

    return {error, res: res?.methodResponse?.getChannels};
  };

  private generteZeroChannel_ = () => {
    return new Channel(
      {
        channelID: genZeroUint8Array(),
        type: entities.OzekonChannelType.OIT_TELEGRAM,
        state: {
          identifiers: {
            shownName: 'Widget',
            phone: 'Widget',
          },
          telegram: {
            state: 10,
          },
        },
        shownFields: {
          name: 'Widget',
        },
      },
      this.workspace,
    );
  };

  private processLoadChannels_ = (res?: IMCMethodResponse | null) => {
    if (!this.app.userStore.isLoggedIn) {
      return;
    }

    const [newChannels, removedChannels] = this.syncChannels_(res?.getChannels?.channels);
    this.syncVersion_(newChannels, removedChannels, res?.versions);

    if (!this.isInit) {
      this.setInit(true);

      const channelIDStr = this.app.initialSelections?.channelId;
      const channelID = base64ToUint8Array(channelIDStr);
      const channel = this.findChannel(channelID);

      const chatIDStr = this.app.initialSelections?.chatId;
      const chatID = chatIDStr ? Long.fromString(chatIDStr) : null;

      if (this.selectedChannelID && channelIDStr && channel) {
        this.setSelectedChannelById(channelIDStr, chatID);
      } else if (this.selectedChannelID && channel) {
        this.setSelectedChannelById(this.selectedChannelID, chatID);
      } else {
        this.workspace.chatsLoader.select(
          this.availableChannelsIds,
          chatID,
          MCMethodGetChatsPreview.MCChatStatus.S_ALL,
        );
      }
    }

    this.updateManagingChannel();
  };

  private syncChannels_ = (
    rawChannelsResponse?: IMCGetChannelResponse[] | null,
  ): [newChannels: Channel[], removedChannels: Channel[]] => {
    const newChannels: Channel[] = [];
    const removedChannels = rawChannelsResponse
      ? this.list.filter((c) => {
        return !rawChannelsResponse.some((channelResponse) =>
          equalUint8Arrays(channelResponse.channel?.channelID, c.id),
        );
      })
      : [];

    const channels =
      rawChannelsResponse?.map((rawChannelResponse) => {
        let channel = this.channelsMap.get(uint8ArrayToBase64(rawChannelResponse.channel?.channelID));
        if (channel) {
          channel.update({...rawChannelResponse.channel, unreadCount: rawChannelResponse.unreadCount});
        } else {
          channel = new Channel(
            {...rawChannelResponse.channel, unreadCount: rawChannelResponse.unreadCount},
            this.workspace,
          );
          newChannels.push(channel);
        }

        this.channelsMap.set(uint8ArrayToBase64(rawChannelResponse.channel?.channelID), channel);
        return channel;
      }) || [];

    this.setChannels(channels);

    removedChannels.forEach((c) => {
      this.channelsMap.delete(uint8ArrayToBase64(c.id));
    });

    if (
      this.isInit &&
      (!this.selectedChannel || removedChannels.some((c) => equalUint8Arrays(this.selectedChannel?.id, c.id)))
    ) {
      this.selectChannel(null);
    }

    return [newChannels, removedChannels];
  };

  private syncVersion_ = (
    newChannels: Channel[],
    removedChannels: Channel[],
    versions?: entities.IChannelVersion[] | null,
  ) => {
    const newVersions =
      newChannels.map((c) => {
        return {
          channelId: c.id,
          version: versions?.find((v) => equalUint8Arrays(v.channelId, c.id))?.version || Long.ZERO,
        };
      }) || [];

    this.workspace.updater.addChannels(newVersions);
    this.workspace.updater.removeChannels(removedChannels.map((c) => c.id));
  };

  private lastRemovedChannel_: Map<string, Channel> = new Map();

  public testRemoveChannel = (channelIDBase64: string) => {
    const channelID = base64ToUint8Array(channelIDBase64);
    const channel = this.findChannel(channelID);

    this.channelsMap.delete(channelIDBase64);

    this.setChannels(this.list.filter((c) => !equalUint8Arrays(channelID, c.id)));

    if (channel) {
      this.lastRemovedChannel_.set(channelIDBase64, channel);

      this.syncVersion_([], [channel]);

      if (!this.selectedChannel || equalUint8Arrays(this.selectedChannel?.id, channel.id)) {
        this.selectChannel(null);
      }
    }
  };

  public testAddChannel = (channelIDBase64: string, version = 0) => {
    const channel = this.lastRemovedChannel_.get(channelIDBase64);

    if (channel) {
      this.channelsMap.set(channelIDBase64, channel);
      this.setChannels([...this.list, channel]);

      this.syncVersion_(
        [channel],
        [],
        [
          {
            channelId: channel.id,
            version: Long.fromNumber(version),
          },
        ],
      );

      if (!this.selectedChannel) {
        this.selectChannel(null);
      }
    }
  };

  public dropChannel = async (channelID?: Uint8Array | null) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            dropChannel: new MCDropChannel({
              channelID,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    this.processDropChannel_(res?.methodResponse);

    return {error, res};
  };

  private processDropChannel_ = (methodResponse?: IMCMethodResponse | null) => {
    if (
      methodResponse?.dropChannel?.result !== MCDropChannelResponse.Result.R_CHANNEL_NOT_FOUND &&
      methodResponse?.dropChannel?.result !== MCDropChannelResponse.Result.R_INTERNAL_SERVER_ERROR
    ) {
      this.load();
    }
  };

  public readAllChannelsChats = async (channels: Channel[]) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const channelsIds_ = channels.map(({id}) => id);

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: {
          workspaceId: this.workspace.id,
          action: {
            markReadAll: new MCMethodMarkReadAll({
              channelIDs: channelsIds_,
            }),
          },
        },
      },
      'channelsResponse',
    );

    this.processReadAllChannelsChats_(channels, res);

    return {error, res: res};
  };

  private processReadAllChannelsChats_ = (channels: Channel[], res?: api.ChannelsResponse | null) => {
    if (res?.methodResponse?.markReadAll?.result === MCMethodMarkReadAllResponse.Result.R_OK) {
      channels.forEach((channel) => {
        channel.processReadAllChats();
      });
    }
  };

  updateChannel = async (data: {
    channel: Channel;
    channelID: Uint8Array;
    shownFields?: IMCChannelShownFields | null;
    webWidgetConfig?: IMCWebWidgetConfig | null;
  }) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const {error, res} = await this.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            editChannel: new MCEditChannel({
              channelID: data.channelID,
              shownFields: data.shownFields,
              webWidgetConfig: data.webWidgetConfig,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    if (res) {
      this.processUpdateChannel_(data);
      this.app.anal.channelSetupEvent(data.channel);
    }

    return {error, res};
  };

  private processUpdateChannel_ = (data: {
    channelID: Uint8Array;
    shownFields?: IMCChannelShownFields | null;
    webWidgetConfig?: IMCWebWidgetConfig | null;
  }) => {
    const channel = this.findChannel(data.channelID);

    channel?.update({
      shownFields: data.shownFields,
      webWidgetConfig: data.webWidgetConfig,
    });
  };

  @action public reset = () => {
    this.setInit(false);
    this.list = [];
    this.channelsMap.clear();
    this.managingChannel = null;

    this.selectChannel(null);
  };

  public telegram = new Telegram(this);
  public telegramBot = new TelegramBot(this);
  public webWidget = new WebWidget(this);
  public instagram = new Instagram(this);

  public telegramEmulator = new TelegramEmulator(this);
}

export default ChannelsStore;
