import React from 'react';

import Button from 'o-ui/Button';
import CookieGroupList from './CookieGroupList';
import cookieCategories, {ICookieCategory, necessaryCategories} from './cookieCategories';
import {
  acceptCookieSelection,
  acceptNecessaryCookies,
  getSelection,
} from './cookiesStateManager';

interface CustomizeConsentDialogProps {
  onBackClick?: () => void;
  onSaveClick?: () => void;
  onAcceptAllClick?: () => void;
  onRejectNonEssentialClick?: () => void;
  onCloseClick?: () => void;
}

const positionStyle: React.CSSProperties = {
  boxShadow: '2px 2px 18px 0px rgba(0, 0, 0, 0.10)',
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  zIndex: 99999,
};


export function CustomizeConsentDialog(props: CustomizeConsentDialogProps) {
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    getSelection().categories || necessaryCategories
  );

  const handleSelectionToggle = (cookieGroup: ICookieCategory) => {
    if (selectedCategories.includes(cookieGroup.id)) {
      setSelectedCategories(selectedCategories.filter((id) => id !== cookieGroup.id));
    } else {
      setSelectedCategories([...selectedCategories, cookieGroup.id]);
    }
  };

  const handleSavePreferences = () => {
    acceptCookieSelection(selectedCategories);
    props.onSaveClick?.();
  };

  const handleAcceptAllClick = () => {
    acceptCookieSelection(cookieCategories.map((c) => c.id));
    props.onAcceptAllClick?.();
  };

  const handleRejectNonEssentialClick = () => {
    acceptNecessaryCookies();
    props.onRejectNonEssentialClick?.();
  };

  return (
    <div
      className="d-flex flex-column bg-background-primary radius-16 border-body-separator p-4"
      style={{
        ...positionStyle,
        maxWidth: 768,
        maxHeight: '90vh',
      }}
    >

      <div className="d-flex flex-row">
        <Button
          onClick={props.onBackClick}
          size="small"
          variant="outlined"
          color="tertiary"
        >
          <i className="tg-icon tg-icon-chevron-left-line font-size-10 mr-4" />
          Back
        </Button>
        <Button
          className="ml-auto color-body-placeholder"
          onClick={props.onCloseClick}
          size="small"
          variant="text"
          name="close-button"
          aria-label="Close dialog"
        >
          <i className="tg-icon tg-icon-cross-line mr-2" />
        </Button>
      </div>

      <div className="body1-bold mt-6">Customize Your Cookie Settings</div>

      <div className="w-100 custom-scroll-y">
        <CookieGroupList
          selected={selectedCategories}
          onChange={handleSelectionToggle}
        />

        <div className="d-flex flex-column align-items-start bg-white radius-8 border-body-separator p-3 mt-2">
          <div className="body3-regular color-body-tertiary">Your consent applies to the following domains:</div>
          <div className="body2-regular">{window.location.host}</div>
        </div>
      </div>

      <div className="mt-4">
        <Button
          className="mr-2"
          onClick={handleSavePreferences}
          size="medium"
          variant="contained"
          color="primary"
        >
          Save Preferences
        </Button>
        <Button
          className="mr-2"
          onClick={handleAcceptAllClick}
          size="medium"
          variant="contained"
          color="secondary"
        >
          Accept All
        </Button>
        <Button
          className="mr-2"
          onClick={handleRejectNonEssentialClick}
          size="medium"
          variant="contained"
          color="tertiary"
        >
          Reject Non-Essential
        </Button>
      </div>
    </div>
  );
}

export default CustomizeConsentDialog;