import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import {PasswordInput} from 'o-ui';
import Button from 'o-ui/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {TelegramAuthStageResponse, entities} from '../../../../api/proto';
import useMountedState from '../../../../hooks/useMountedState';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import getQueryStringParam from '../../../../utils/getQueryStringParam';

interface IProps {
  title?: string;
  subTitle?: string;
  newChannel: Channel;
  onChangeForm?: () => void;
  onChangeSource?: (source: entities.OzekonChannelType) => void;
  onClose?: () => void;
}

export const Telegram2FactorAuthForm = observer((props: IProps) => {
  const isMounted = useMountedState();
  const {t} = useTranslation();
  const {channels, notifications} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const telegram = getQueryStringParam('emulate') ? channels.telegramEmulator : channels.telegram;

  const getStatusTitle = (status?: TelegramAuthStageResponse.EnterPassword.Result | null) => {
    switch (status) {
      case TelegramAuthStageResponse.EnterPassword.Result.AUTH_SUCCESS:
        return null;
      case TelegramAuthStageResponse.EnterPassword.Result.RETRY_LOGIN:
        return t('notification_telegram_auth_error');
      case TelegramAuthStageResponse.EnterPassword.Result.SIGN_UP_REQUIRED:
        return t('notification_telegram_auth_sign_up_required');
      case TelegramAuthStageResponse.EnterPassword.Result.WRONG_PASSWORD:
        return t('notification_telegram_auth_wrong_password');
      case TelegramAuthStageResponse.EnterPassword.Result.UNKNOWN:
        return t('notification_telegram_auth_error');
      default:
        return null;
    }
  };

  const formikCreate = useFormik({
    initialValues: {
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      password: Yup.string().required(t('settings_channel_creation_form_channel_password')),
    }),
    onSubmit: async ({password}) => {
      setApiError('');
      const {error, res} = await telegram.sendPassword({
        channel: props.newChannel,
        password,
      });

      if (!isMounted()) {
        return;
      }

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      }

      const status = res?.status;
      const statusTitle = getStatusTitle(status);

      if (status === TelegramAuthStageResponse.EnterPassword.Result.AUTH_SUCCESS) {
        props.onClose?.();

        setApiError(null);
      } else {
        setApiError(statusTitle);
      }
    },
  });

  const handleChangePassword = (e: React.ChangeEvent) => {
    props.onChangeForm && props.onChangeForm();
    formikCreate.handleChange(e);
  };

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {
      formikCreate.submitForm();
    }
  };

  console.log(getStatusTitle());

  return (
    <>
      <form
        className="channel-dialog-form__form custom-scroll"
        onSubmit={formikCreate.handleSubmit}
        onKeyDown={handleFormKeyDown}
      >
        {props.title ? <div className="h1-bold color-body-primary">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary pb-6">{props.subTitle}</div> : null}

        <div className="body1-regular color-body-primary pb-6">
          {t('settings_channel_creation_form_channel_two_factor_label')}
        </div>

        <PasswordInput
          className="w-100"
          placeholder={t('settings_channel_creation_form_channel_password_placeholder')}
          name="password"
          onChange={handleChangePassword}
          onBlur={formikCreate.handleBlur}
          value={formikCreate.values.password}
          errorHelper={apiError || formikCreate.errors.password || ' '}
          required
          autoFocus
        />
      </form>
      <div className="channel-dialog-form__footer">
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={formikCreate.submitForm}
          loading={telegram.loading}
        >
          {t('settings_channel_creation_form_continue')}
        </Button>
      </div>
    </>
  );
});

export default Telegram2FactorAuthForm;
