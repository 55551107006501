import React from 'react';
import {useTranslation} from 'react-i18next';
import WebWidgetWelcomeMessageSettings from '../pages/Settings/Channels/WebWidgetSettings/WebWidgetWelcomeMessageSettings';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalWebWidgetWelcomeMessageSettings = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_web_widget_edit_welcome_message_title')}
      maxWidth="md"
      PaperProps={{
        className: 'webwidget-settings__paper',
      }}
    >
      {props.modal.data.channel ? (
        <WebWidgetWelcomeMessageSettings
          channel={props.modal.data.channel}
          onClose={handleCloseClick}
        />
      ) : null}
    </ModalDialog>
  );
};

export default ModalWebWidgetWelcomeMessageSettings;
