import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useInView} from 'react-intersection-observer';

import AvatarWrapper from '../../../components/AvatarWrapper';
import ChannelRoundIcon from '../../../components/ChannelRoundIcon';
import useContextMenuHandler from '../../../hooks/useContextMenuHandler';
import MessageAttachment from '../../../stores/Attachment/MessageAttachment';
import Chat from '../../../stores/Chat';
import ContextMenuType from '../../../stores/ContextMenuType';
import Message from '../../../stores/Message';
import useStore from '../../../stores/useStore';
import MessageBody from './MessageBody';

interface MessageItemProps {
  index: number;
  chat: Chat;
  pinnedMode?: boolean;
  isScrolling?: boolean;
  message: Message;
  messageGroup: Message[];
  prevMessage?: Message | null;
  nextMessage?: Message | null;
  searchId?: string;
  isLastMessage?: boolean;
  onChangeVisibility?: (isVisible: boolean, index: number, message: Message) => void;
}

export const MessageItem = observer((props: MessageItemProps) => {
  const {contextMenu, layOutStore, page} = useStore();
  const {chat, message, messageGroup} = props;

  const handleChangeVisibility = React.useCallback(
    (inView: boolean) => {
      props.onChangeVisibility?.(inView, props.index, props.message);

      if (inView) {
        chat.store?.addVisibleMessage(message);
      } else {
        chat.store?.deleteVisibleMessage(message);
      }
    },
    [props, chat, message],
  );

  const {ref: inViewRef, inView} = useInView({
    threshold: 0,
    onChange: handleChangeVisibility,
  });

  React.useEffect(() => {
    const isUnReadInbox = messageGroup.some((m) => !m.isReadInbox && !m.isOwn);
    // console.debug(`%c----->MessageItem-->readMessages localID=${chatMessage.localID.toString()} isObserving=${page.isObserving} inView=${inView} isUnReadInbox=${isUnReadInbox}`, 'color: blue;');
    if (page.isObserving && inView && isUnReadInbox) {
      console.debug(`%c----->MessageItem-->readMessages read localID=${message.localID.toString()}`, 'color: red;');
      message.chat.store?.readMessages(messageGroup);
    }
  }, [page.isObserving, inView, message, messageGroup]);

  const setItemRef = React.useCallback(
    (el: HTMLElement | null) => {
      message.setMessageRef(el);
      messageGroup.forEach((m) => m.setMessageRef(el));
    },
    [message, messageGroup],
  );

  const onMouseDown = (e: React.MouseEvent) => {
    chat.store?.selector?.onMouseDown(e, message);
  };

  const onMouseUp = (e: React.MouseEvent) => {
    chat.store?.selector?.onMouseUp(e, message);
  };

  const onMouseOver = (e: React.MouseEvent) => {
    // setIsMessageItemHover(true);
    chat.store?.selector?.onMouseOver(e, message);
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    // setIsMessageItemHover(false);
    chat.store?.selector?.onMouseLeave(e, message);
  };

  const {...contextMenuHandlers} = useContextMenuHandler((e: React.MouseEvent, attachment?: MessageAttachment) => {
    e.stopPropagation();
    e.preventDefault();

    if (message.menuIsAvailable) {
      contextMenu.open(
        {clientX: e.clientX, clientY: e.clientY},
        message.deleted ? ContextMenuType.MESSAGE_DELETED_MENU : ContextMenuType.MESSAGE_MENU,
        {
          message: message,
          attachment,
        },
      );
    }
  });

  const onMessageBodyClick = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`TODO: not implemented handler "onMessageBodyClick", args:`, e);
  }, []);

  const withAvatar =
    !props.nextMessage ||
    !!props.nextMessage?.serviceMessage?.widgetUserConnected ||
    !!props.nextMessage?.serviceMessage?.widgetUserDisconnected ||
    !!props.nextMessage?.serviceMessage?.widgetOperatorJoined ||
    !!props.nextMessage?.serviceMessage?.widgetOperatorLeft ||
    !(
      (message.peerId && !message.operatorID && props.nextMessage.peerId?.equals(message.peerId)) ||
      (message.operatorID && !message.peerId && props.nextMessage.operatorID?.equals(message.operatorID)) ||
      (message.isUnknownSender && (!props.nextMessage.isUnknownSender || !props.nextMessage.serviceMessage))
    ) ||
    (message.isUnknownSender && !props.nextMessage.isUnknownSender);

  const withTitle =
    !props.prevMessage ||
    !!props.prevMessage.serviceMessage?.widgetUserConnected ||
    !!props.prevMessage.serviceMessage?.widgetOperatorJoined ||
    !!props.prevMessage.serviceMessage?.widgetOperatorLeft ||
    !!props.prevMessage.serviceMessage?.widgetUserDisconnected ||
    !(
      (message.peerId && !message.operatorID && props.prevMessage.peerId?.equals(message.peerId)) ||
      (message.operatorID && !message.peerId && props.prevMessage.operatorID?.equals(message.operatorID)) ||
      (message.isUnknownSender && (!props.prevMessage.isUnknownSender || !props.prevMessage.serviceMessage))
    ) ||
    (message.isUnknownSender && !props.prevMessage.isUnknownSender);

  let senderName = message.senderName || '';
  if (!withTitle) {
    senderName = '';
  }

  const handleAvatarClick = () => {
    layOutStore.rightSideBar.openMessageSenderInfo(message);
  };

  return (
    <div
      ref={setItemRef}
      id={message.id.toString()}
      data-is-visible={inView.toString()}
      data-is-own={message.isOwn}
      data-is-external={message.isExternal}
      data-is-unread-inbox={!message.isReadInbox}
      data-is-unread-outbox={!message.isReadOutbox}
      className={cn('message', {
        right: !message.isExternal,
        'found-message':
          message.id.toString() === props.searchId ||
          (chat.store?.searchMessage?.id && message.id.equals(chat.store?.searchMessage?.id)) ||
          (chat.store?.targetMessage?.id && message.id.equals(chat.store?.targetMessage?.id)),
        // 'selected-message': chatMessage.isSelected,
        // 'without-chat-avatar': !withAvatar,
        // [`message--${com.chatman.MessageType[chatMessage.type].toLowerCase().replace('mt_', '')}`]: true,
      })}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...contextMenuHandlers}
    >
      <div className="message__inner">
        {withAvatar ? (
          <div
            ref={inViewRef}
            className="message__avatar cursor-pointer"
            onClick={handleAvatarClick}
            data-peer-id={message.peerId?.toString()}
          >
            {message.isUnknownSender ? (
              <ChannelRoundIcon
                className="message__avatar-icon"
                type={chat.channel.type}
              />
            ) : (
              <AvatarWrapper
                size="sm"
                attachment={message?.avatar}
                channelID={chat?.channelID}
                name={message.senderName}
              />
            )}
          </div>
        ) : (
          <div ref={inViewRef} className="message__avatar-blank" />
        )}

        <div className="message__content">
          <MessageBody
            searchId={props.searchId}
            isScrolling={props.isScrolling}
            senderName={senderName}
            chat={chat}
            pinnedMode={props.pinnedMode}
            message={message}
            messageGroup={props.messageGroup}
            isVisible={inView}
            isMessageItemHover={false}
            onClick={onMessageBodyClick}
          />
        </div>
        {/* {chat.store?.selectedMessages.length ? <div className="select-mod-message-wrapper" /> : null} */}
      </div>
    </div>
  );
});

export default MessageItem;
