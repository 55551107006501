import Long from 'long';
import {IRawChat} from '../RawChat';
import initDefaultChatFields from './initDefaultChatFields';

export function mergeInReadPointer(currentChat: IRawChat, updates: IRawChat): Long | null | undefined {
  // We take inReadPointer only higher than current
  let inReadPointer = currentChat.inReadPointer;
  if (updates.inReadPointer?.greaterThan(currentChat.inReadPointer || Long.ZERO)) {
    inReadPointer = updates.inReadPointer;
  }

  return inReadPointer;
}

export function mergeChatUpdates(currentChat: IRawChat, updates: IRawChat, intiDefaults?: boolean): IRawChat {
  const inReadPointer = mergeInReadPointer(currentChat, updates);
  const defaults = intiDefaults ? initDefaultChatFields(currentChat, updates) : null;

  return {
    ...currentChat,
    ...defaults,
    inReadPointer,
  };
}

export function applyChatUpdates(currentChat: IRawChat, updates: IRawChat): IRawChat {
  const inReadPointer = mergeInReadPointer(currentChat, updates);

  return {
    ...currentChat,
    ...updates,
    inReadPointer,
  };
}