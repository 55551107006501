import cn from 'classnames';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import useStore from '../../../stores/useStore';
import {BILLING_DATE_FORMAT, formatDateTime} from '../../../utils/format';
import formatMoney from '../../../utils/format/formatMoney';
import PaymentMethodIcon from './PaymentMethodIcon';

interface NextPaymentInfoProps {
  className?: string;
}

export const NextPaymentInfo: React.FC<NextPaymentInfoProps> = observer(({
  className,
}) => {
  const {t} = useTranslation();

  const {activeWorkspace: {billing}, anal} = useStore();
  const {currentTariff} = billing;

  const handleClickPay = async () => {
    currentTariff && anal.tariffUpgrade(currentTariff);

    await billing.payForTariff(currentTariff?.id);
  };

  if (!currentTariff || !billing.currentTariffHasInfo) {
    return null;
  }

  return (
    <div
      className={cn(
        'd-flex flex-row flex-wrap p-6',
        className,
      )}
    >
      <div className="d-flex flex-sm-row flex-column">
        {billing.trialEnabled || billing.paymentError || (billing.currentTariff && billing.autoRenew) ? (
          <div className="mr-8">
            <div className="label-2 color-body-tertiary text-uppercase">
              {t('settings_billing_payment_next_payment_title')}
            </div>

            <div className="mt-1 pt-1">
              <span className="h3-bold">
                {formatMoney({
                  value: currentTariff?.price,
                  currency: currentTariff?.currency,
                })}
              </span>

              <span className="body2-regular color-body-tertiary pl-3">
                {billing.expiresAt?.greaterThan(Long.ZERO) ? t('settings_billing_tariff_expires_at_date', {
                  date: formatDateTime(billing.expiresAt, true, BILLING_DATE_FORMAT),
                }) : null}
              </span>
            </div>

          </div>
        ) : null}

        {billing.currentTariff && !billing.trialEnabled ? (
          <div className="mt-5 mt-sm-0">
            <div className="label-2 color-body-tertiary text-uppercase">
              {t('settings_billing_payment_method_title')}
            </div>

            {billing.cardBrand || billing.cardLastDigits ? (
              <div>
                <div className="d-inline-flex flex-row align-items-center border-primary radius-8 pr-3 mt-3">
                  {billing.cardBrand ? (
                    <PaymentMethodIcon
                      className="mr-3"
                      type={billing.cardBrand}
                      bordered
                    />
                  ) : null}
                  {billing.cardLastDigits ? (
                    <span className="body2-regular">
                      **** {billing.cardLastDigits}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="ml-8">
            <div className="label-2 color-body-tertiary text-uppercase">
              {t('settings_billing_available_payment_methods')}
            </div>

            <div className="h3-bold mt-3 d-flex align-items-center">
              <PaymentMethodIcon className="mr-3" type="visa" bordered />
              <PaymentMethodIcon className="mr-3" type="mastercard" bordered />
              <PaymentMethodIcon className="mr-3" type="maestro" bordered />
            </div>
          </div>
        )}
      </div>

      <div className="ml-auto d-flex align-items-center">
        {billing.trialEnabled ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickPay}
            loading={!!billing.payForTariffInProgress}
          >
            {t('settings_billing_tariff_pay_now')}
          </Button>
        ) : billing.paymentError ? null : (
          <Button
            className="mt-3"
            variant="outlined"
            color="tertiary"
            onClick={billing.openCustomerPortal}
            loading={billing.openCustomerPortalInProgress}
          >
            {t('settings_billing_tariff_manage')}
          </Button>
        )}
      </div>
    </div>
  );
});

export default NextPaymentInfo;