import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import AvatarWrapper from '../../../../components/AvatarWrapper';
import useCompactMode from '../../../../hooks/useCompactMode';
import Chat from '../../../../stores/Chat';
import useStore from '../../../../stores/useStore';
import ChatHeadBackButton from './ChatHeadBackButton';
// import ChatChannelTypeIcon from '../../Tabs/ChatsTab/ChatItem/ChatChannelTypeIcon';
// import PeerStatusTitle from '../../../../components/PeerStatusTitle';

interface IProps {
  chat: Chat;
}

export const ChatHeadTitle: React.FC<IProps> = observer(({chat}) => {
  const app = useStore();
  const {t} = useTranslation();
  const compactMode = useCompactMode();

  const toggleChatSidebar = (e: React.MouseEvent) => {
    e.preventDefault();
    app.layOutStore.rightSideBar.toggle(chat);
  };

  return (
    <div className="chat-head-title">
      <div className="chat-head-title__back-btn-block mr-3">
        <ChatHeadBackButton />
      </div>

      <AvatarWrapper
        className="chat-head-title__avatar"
        attachment={chat?.avatar}
        channelID={chat?.channelID}
        name={chat.displayTitle}
        size={compactMode ? 'sm' : 'md'}
      />

      <div className="chat-head-title__info cursor-pointer overflow-hidden" onClick={toggleChatSidebar}>
        <div className="chat-head-title__top-info text-truncate">
          <span className="chat-head-title__title">{chat.displayTitle}</span>

          {/* {chat.store?.chatUser ? (
            <PeerStatusTitle
              className="chat-head-title__status-title d-none d-lg-inline-block"
              user={chat.store?.chatUser}
            />
          ) : null} */}

          {/* {chat.store?.members?.list.length ? (
            <span
              className="chat-head-title__members d-none d-lg-inline-block"
              id={'chat-members-title-' + chat.id.toString()}
            >
              {t('chat_user_chat_head_members_count', {count: chat.store?.members?.list.length})}
            </span>
          ) : null} */}
        </div>
        <div className="chat-head-title__bottom-info text-truncate">
          <span className="chat-head-title__channel-name-pre">{t('to')}</span>
          <span className="chat-head-title__channel-name">@{chat.channel?.name}</span>
          {/* <ChatChannelTypeIcon className="chat-head-title__source-icon" source={chat.channel?.source} /> */}
        </div>
      </div>
    </div>
  );
});

export default ChatHeadTitle;
