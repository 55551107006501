import React from 'react';
import cn from 'classnames';
import {observer} from 'mobx-react';

import Message from '../../../../stores/Message';
import {niceBytes} from '../../../../utils/format';
import {IGroupMessageSize} from '../useMessageSize';
import MessageAlbumItem from './MessageAlbumItem';
import MessageContent from './MessageContent';


interface MessageAlbumProps {
  className?: string;
  message: Message;
  messageGroup: Message[];
  searchId?: string;
  isVisible?: boolean;
  isScrolling?: boolean;
  messageSize: IGroupMessageSize;
}

export const MessageAlbum: React.FC<MessageAlbumProps> = observer(({
  className,
  message,
  messageGroup,
  isVisible,
  isScrolling,
  searchId,
  messageSize,
}) => {

  if (messageGroup.length > 1) {
    return (
      <div
        className={cn(
          `message-album`,
          {
            'message-album--odd': messageGroup.length % 2 != 0,
            'message-album--images': messageGroup[0].hasImage,
            'message-album--videos': messageGroup[0].hasVideo,
          },
          className,
        )}
        style={{width: messageSize.style.width}}
      >
        {messageGroup.map((m, i) => (
          m.attachments?.map((attachment, j) => (
            <MessageAlbumItem
              key={`${i}-${j}`}
              message={m}
              albumMessage={message}
              attachment={attachment}
              searchId={searchId}
              style={messageSize.items[i]}
            />
          ))
        ))}
      </div>
    );
  }

  const attachments = message.attachments || [];
  if (attachments.length > 1) {
    return (
      <div
        className={cn(
          `message-album`,
          {
            'message-album--odd': messageGroup.length % 2 != 0,
            'message-album--images': messageGroup[0].hasImage,
            'message-album--videos': messageGroup[0].hasVideo,
          },
          className,
        )}
      >
        {attachments.map((attachment, i) => (
          <MessageAlbumItem
            key={i}
            message={message}
            albumMessage={message}
            attachment={attachment}
            searchId={searchId}
          />
        ))}
      </div>
    );
  }

  return attachments.length ? (
    <div
      className={cn(
        `message__body__message-content`,
        className,
      )}
      data-attachment-size={attachments.length > 0 ? niceBytes(attachments[0]?.size?.toNumber()) : undefined}
    >
      <MessageContent
        fixedSize={true}
        blurredBackground={true}
        message={message}
        attachment={attachments[0]}
        isVisible={isVisible}
        isScrolling={isScrolling}
        playButton={true}
        style={messageSize.items[0]}
      />
    </div>
  ) : null;
});

export default MessageAlbum;
