import {TelegramAuthStageResponse} from '../../../api/proto';
import i18n from '../../../i18n';

const {t} = i18n;

const getTelegramAuthResendCodeError = (status?: TelegramAuthStageResponse.ResendCode.Result | null) => {

  if (status === TelegramAuthStageResponse.ResendCode.Result.CODE_RESENT) {
    return null;
  }

  switch (status) {
    case TelegramAuthStageResponse.ResendCode.Result.UNKNOWN:
      return t('api_telegram_auth_resend_code_error_unknown');
    case TelegramAuthStageResponse.ResendCode.Result.CODE_HASH_INVALID:
      return t('api_telegram_auth_resend_code_error_code_hash_invalid');
    case TelegramAuthStageResponse.ResendCode.Result.CODE_RATE_LIMIT:
      return t('api_telegram_auth_resend_code_error_code_rate_limit');
  }

  if (status && TelegramAuthStageResponse.ResendCode.Result[status]) {
    return TelegramAuthStageResponse.ResendCode.Result[status]?.toLowerCase().replace(/_/g, ' ');
  }

  return '';
};

export default getTelegramAuthResendCodeError;