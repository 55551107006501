import {makeObservable} from 'mobx';

import {
  IMCChannelShownFields,
  MCCreateChannelResponse,
  TelegramAuthStageResponse,
  entities
} from '../../../api/proto';
import {randomUint8Array} from '../../../utils/arrayUtils';
import wait from '../../../utils/wait';
import {APILayer} from '../../APILayer';
import TimerStore from '../../TimerStore';
import Channel from '../Channel';
import ChannelsStore from '../ChannelsStore';


export class TelegramEmulator extends APILayer {
  constructor(public channels: ChannelsStore) {
    super(channels.workspace.app);
    makeObservable(this);
  }

  createChannel = async (data: {phone: string; shownFields?: IMCChannelShownFields | null}) => {
    console.debug(data);
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    const createChannelRes = new MCCreateChannelResponse({
      channelID: randomUint8Array(),
      telegramAuthFlow: {
        auth: {
          enterPhone: {
            status: TelegramAuthStageResponse.EnterPhone.Result.CODE_SENT,
            codeHash: '123456',
          },
        },
      },
    });

    let newChannel: Channel | null = null;

    if (createChannelRes) {
      newChannel = new Channel(
        {
          channelID: createChannelRes?.channelID,
          shownFields: data.shownFields,
          type: entities.OzekonChannelType.OIT_TELEGRAM,
          workspaceID: this.channels.workspace.id,
          state: {
            identifiers: {
              phone: data.phone,
            },
          },
        },
        this.channels.workspace,
      );
    }

    this.setLoading(true);
    await wait(3000);
    this.setLoading(false);

    return {error: null, res: createChannelRes, channel: newChannel};
  };

  public resendAuthCodeTimer = new TimerStore();

  resendAuthCode = async (data: {channel: Channel; codeHash: string}) => {
    console.debug(data);
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }

    if (!data.codeHash) {
      return {
        error: null,
        res: {status: TelegramAuthStageResponse.ResendCode.Result.CODE_HASH_INVALID},
      };
    }

    this.resendAuthCodeTimer.run();

    this.setLoading(true);
    await wait(3000);
    this.setLoading(false);

    return {
      error: null,
      res: {status: TelegramAuthStageResponse.ResendCode.Result.CODE_RESENT},
    };
  };

  sendAuthCode = async (data: {channel: Channel; code: string; codeHash: string}) => {
    console.debug(data);
    this.setLoading(true);
    await wait(3000);
    this.setLoading(false);

    if (!data.codeHash) {
      return {
        error: null,
        res: {status: TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_EMPTY},
      };
    }

    return {
      error: null,
      res: {status: TelegramAuthStageResponse.EnterCode.Result.AUTH_SUCCESS},
    };
  };

  sendPassword = async (data: {channel: Channel; password: string}) => {
    console.debug(data);
    this.setLoading(true);
    await wait(3000);
    this.setLoading(false);


    return {
      error: null,
      res: {status: TelegramAuthStageResponse.EnterPassword.Result.AUTH_SUCCESS},
    };
  };

  reactivateChannel = async (data: {channel: Channel; phone: string}) => {
    if (this.loading) {
      return {error: {message: 'In process'}, res: null};
    }
    console.debug(data);

    this.setLoading(true);
    await wait(3000);
    this.setLoading(false);

    return {
      error: null,
      res: {status: TelegramAuthStageResponse.EnterPhone.Result.CODE_SENT, codeHash: '123456'},
    };
  };

}

export default TelegramEmulator;
