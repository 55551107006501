import cn from 'classnames';
import React from 'react';

interface ValueSliderProps {
  className?: string;
  value?: number;
  onChange?: (volume: number) => void;
  orient?: 'horizontal' | 'vertical';
  color?: 'brand' | 'gray' | 'default';
  step?: number;
}

export default function ValueSlider({
  className,
  value = 0,
  onChange,
  orient = 'horizontal',
  color = 'default',
  step = 1,
}: Readonly<ValueSliderProps>) {

  const handleChangeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onChange?.(Number(e.target.value));
  };

  const handleDecClick = () => onChange?.(value ? value - step : 0);

  const handleIncClick = () => onChange?.(value < 100 ? value + step : 100);

  const handleTouchEvent = (e: React.TouchEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      className={cn(
        className,
        'value-slider',
        `value-slider--${orient}`,
        `value-slider--${color}`,
      )}
    >
      <button
        color="none"
        type="button"
        className={cn('value-slider__btn tg-icon')}
        onClick={handleDecClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <rect x="0.5" y="1.39648" width="23" height="23" rx="5.5" stroke="#E5E5E6" />
          <path d="M7 13H17" stroke="#191A2C" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </button>

      <div className="value-slider__slider-wrapper">
        <input
          style={{backgroundSize: (value * 100) / 100 + '% 100%'}}
          className="value-slider__slider"
          type="range"
          min="0"
          max="100"
          value={value}
          step={step}
          onChange={handleChangeVolume}
          onTouchStart={handleTouchEvent}
          onTouchEnd={handleTouchEvent}
        />
      </div>

      <button
        color="none"
        type="button"
        className={cn('value-slider__btn tg-icon')}
        onClick={handleIncClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
          <rect x="0.5" y="1.39648" width="23" height="23" rx="5.5" stroke="#E5E5E6" />
          <path d="M7 13H12M17 13H12M12 13V8V18" stroke="#191A2C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    </div>
  );
}