
export const INSTAGRAM_SUPPORTED_IMAGE_CONTENT_TYPES = new Set([
  'image/png',
  'image/gif',
  'image/jpeg',
]);

export const INSTAGRAM_SUPPORTED_VIDEO_CONTENT_TYPES = new Set([
  'video/mp4',
  'video/ogg',
  'video/x-msvideo',
  'video/quicktime',
  'video/webm',
]);

export const INSTAGRAM_SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/wav',
  'audio/wave',
  'audio/x-wav',

  'audio/aac',
  'audio/x-aac',

  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);