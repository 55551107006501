import {TelegramAuthStageResponse} from '../../../api/proto';
import i18n from '../../../i18n';

const {t} = i18n;

const getTelegramAuthCodeError = (status?: TelegramAuthStageResponse.EnterCode.Result | null) => {

  if (status === TelegramAuthStageResponse.EnterCode.Result.AUTH_SUCCESS) {
    return null;
  }

  switch (status) {
    case TelegramAuthStageResponse.EnterCode.Result.UNKNOWN:
      return t('api_telegram_auth_code_error_unknown');
    case TelegramAuthStageResponse.EnterCode.Result.PASSWORD_REQUIRED:
      return t('api_telegram_auth_code_error_password_required');
    case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_EMPTY:
      return t('api_telegram_auth_code_error_phone_code_empty');
    case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_EXPIRED:
      return t('api_telegram_auth_code_error_phone_code_expired');
    case TelegramAuthStageResponse.EnterCode.Result.PHONE_CODE_INVALID:
      return t('api_telegram_auth_code_error_phone_code_invalid');
    case TelegramAuthStageResponse.EnterCode.Result.PHONE_NUMBER_INVALID:
      return t('api_telegram_auth_code_error_phone_number_invalid');
    case TelegramAuthStageResponse.EnterCode.Result.PHONE_NUMBER_UNOCCUPIED:
      return t('api_telegram_auth_code_error_phone_number_unoccupied');
    case TelegramAuthStageResponse.EnterCode.Result.SIGN_IN_FAILED:
      return t('api_telegram_auth_code_error_sign_in_failed');
    case TelegramAuthStageResponse.EnterCode.Result.SIGN_UP_REQUIRED:
      return t('api_telegram_auth_code_error_sign_up_required');
  }

  if (status && TelegramAuthStageResponse.EnterCode.Result[status]) {
    return TelegramAuthStageResponse.EnterCode.Result[status]?.toLowerCase().replace(/_/g, ' ');
  }

  return '';
};

export default getTelegramAuthCodeError;