import Long from 'long';
import Delta from 'quill-delta';
import {IMCTextEntities, MCTextEntities} from '../../../api/proto';
import findCustomTextEntities from './findCustomTextEntities';
import {stringToUint8Array} from '../../../utils/arrayUtils';

export default function convertDeltaToTextEntities(delta: Delta, text?: string, startIdx = 0): IMCTextEntities[] {
  const newEntities: IMCTextEntities[] = [];
  if (delta?.ops) {
    let offset = startIdx;
    delta.ops.forEach((o) => {
      if (typeof o?.insert === 'string') {
        if (o?.attributes?.bold) {
          newEntities.push({
            type: MCTextEntities.Type.BOLD,
            offset: Long.fromNumber(offset),
            length: Long.fromNumber(o?.insert?.length),
          });
        }
        if (o?.attributes?.underline) {
          newEntities.push({
            type: MCTextEntities.Type.UNDERLINE,
            offset: Long.fromNumber(offset),
            length: Long.fromNumber(o?.insert?.length),
          });
        }
        if (o?.attributes?.italic) {
          newEntities.push({
            type: MCTextEntities.Type.ITALIC,
            offset: Long.fromNumber(offset),
            length: Long.fromNumber(o?.insert?.length),
          });
        }
        if (o?.attributes?.strike) {
          newEntities.push({
            type: MCTextEntities.Type.STRIKETHROUGH,
            offset: Long.fromNumber(offset),
            length: Long.fromNumber(o?.insert?.length),
          });
        }

        if (o?.attributes?.link?.startsWith('tel:')) {
          newEntities.push({
            type: MCTextEntities.Type.PHONE_NUMBER,
            offset: Long.fromNumber(offset),
            length: Long.fromNumber(o?.insert?.length),
          });
        }
         else if (o?.attributes?.link) {
           newEntities.push({
             type: MCTextEntities.Type.LINK,
             offset: Long.fromNumber(offset),
             length: Long.fromNumber(o?.insert?.length),
             payload: stringToUint8Array(o?.attributes?.link),
           });
         }

        offset += o?.insert?.length || 0;
      } else if (o?.insert?.mention?.username) {
        newEntities.push({
          type: MCTextEntities.Type.MENTION,
          offset: Long.fromNumber(offset),
          length: Long.fromNumber(o?.insert?.mention.username.length),
        });
        offset += o?.insert?.mention.username.length || 0;
      } /* else if (o?.insert?.mention?.name) {
        newEntities.push({
          type: MCTextEntities.Type.MENTION_NAME,
          userId: Long.fromString(o?.insert?.mention.userid),
          offset: Long.fromNumber(offset),
          length: Long.fromNumber(o?.insert?.mention.name.length),
        });
        offset += Long.fromNumber(o?.insert?.mention.name.length || 0);
      }*/ else if (o?.retain) {
        offset += o?.retain || 0;
      }
    });
  }

  const customTextEntities = findCustomTextEntities(text).filter(
    (customTextEntity) =>
      !newEntities.some(
        (newEntity) => newEntity.offset === customTextEntity.offset && newEntity.length === customTextEntity.length,
      ),
  );

  return newEntities.concat(customTextEntities);
}