import cn from 'classnames';
import React, {useRef} from 'react';

import IconButton from 'o-ui/Button/IconButton';

import MultiFileUploader from '../../../../components/FileUploader/MultiFileUploader';
import Chat from '../../../../stores/Chat';


interface IProps {
  chat: Chat;
  className?: string;
}

export const ChatUploadMenu: React.FC<IProps> = React.memo(({className, chat}) => {
  const refMultiFilesInput = useRef<HTMLInputElement>(null);

  const handleOpenMenu = () => {
    refMultiFilesInput.current?.click();
  };

  return (
    <>
      <MultiFileUploader chat={chat} className="d-none" ref={refMultiFilesInput} />

      <IconButton
        className={cn(
          'tg-icon-attachment-line line-height-22 font-size-22',
          className,
        )}
        onClick={handleOpenMenu}
      />
    </>
  );
});

export default ChatUploadMenu;
