export const UPDATE_CHECK_INTERVAL = 10 * 60 * 1000;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9._%+-]+\.[A-Z0-9-:]{1,128}$/i;

// Mobile Adaptations
export const INLINE_RIGHT_SIDE_BAR_START_FROM = 1360;

export const MOBILE_WIDTH = 768;

export const COMPACT_MODE_MEDIA_QUERY = '(height <= 765px)';
export const MOBILE_MODE_MEDIA_QUERY = `(max-width: ${MOBILE_WIDTH}px)`;
//end Mobile Adaptations

export const CHAT_ITEM_HEIGHT = 64;
export const CHAT_ITEM_HEIGHT_COMPACT = 56;
export const CHAT_MESSAGES_PAGE_SIZE = 100;

export const SEARCH_MESSAGES_PAGE_SIZE = 100;
export const SEARCH_MESSAGES_MIN_QUERY_SIZE = 4;

export const PEERS_PAGE_SIZE = 100;

export const TIME_1_HOUR = 1 * 60 * 60;
export const TIME_4_HOUR = 4 * 60 * 60;
export const TIME_18_HOUR = 18 * 60 * 60;
export const TIME_3_DAYS = 3 * 24 * 60 * 60;
export const TIME_FOREVER = 10 * 365 * 24 * 60 * 60;

export const DURATION = {
  MINUTES_5: 5 * 60 * 1000,
  MINUTES_30: 30 * 60 * 1000,
  HOURS_1: 1 * 60 * 60 * 1000,
  HOURS_3: 3 * 60 * 60 * 1000,
  HOURS_24: 24 * 60 * 60 * 1000,
  DAYS_3: 3 * 24 * 60 * 60 * 1000,
  WEEK_1: 7 * 24 * 60 * 60 * 1000,
  YEAR_1: 1 * 365 * 24 * 60 * 60 * 1000,
  FOREVER: 10 * 365 * 24 * 60 * 60 * 1000,
};

export const WEB_WIDGET_COLORS = ['#146aeb', '#12b352', '#f59842', '#f61148', '#6312b3'];
export const WEB_WIDGET_DEFAULT_CHAT_BUTTON_SIZE = '96px';
export const WEB_WIDGET_POSIBLE_CHAT_BUTTON_SIZES = ['96px', '80px', '64px', '48px', '32px'];

export const MAX_FORM_FIELD_LENGTH = 256;

export const WEB_WIDGET_FIRST_MESSAGES = [
  'Hello! Do you need help?',
  'Hi! We have discounts now! Can I tell you more?',
];

export const EMPTY_IMG = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
export const EMPTY_IMG_BLACK = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
export const EMPTY_IMG_BLUE =
  'data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAEBAQICAgMDAwQEBAUFBQYGBgcHBwgICAkJCQoKCgsLCwwMDA0NDQ4ODg8PDxAQEBERERISEhMTExQUFBUVFRYWFhcXFxgYGBkZGRoaGhsbGxwcHB0dHR4eHh8fHyAgICEhISIiIiMjIyQkJCUlJSYmJicnJygoKCkpKSoqKisrKywsLC0tLS4uLi8vLzAwMDExMTIyMjMzMzQ0NDU1NTY2Njc3Nzg4ODk5OTo6Ojs7Ozw8PD09PT4+Pj8/P0BAQEFBQUJCQkNDQ0REREVFRUZGRkdHR0hISElJSUpKSktLS0xMTE1NTU5OTk9PT1BQUFFRUVJSUlNTU1RUVFVVVVZWVldXV1hYWFlZWVdbY1VebFNhdVFjfk9lh01nj0tplklrnkdtpUVuq0RvsUJxt0ByvT5zwjx0xzt1zDl10Dh21DZ21zV32zN33TJ44DF44zB45S945y546C146i146yx46yx47Cx47Cx57Sx57Cx57Cx57C156y556i556TB55zF55jJ55DR54jZ64Dh63jp73D172UB81kN91EZ90Up+zU1/ylGAx1aCxFqDwF+EvGSGuWqItW+KsXaMrXyOqYOQpYqToZGWnZmZmZqampubm5ycnJ2dnZ6enp+fn6CgoKGhoaKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq6ysrK2tra6urq+vr7CwsLGxsbKysrOzs7S0tLW1tba2tre3t7i4uLm5ubq6uru7u7y8vL29vb6+vr+/v8DAwMHBwcLCwsPDw8TExMXFxcbGxsfHx8jIyMnJycrKysvLy8zMzM3Nzc7Ozs/Pz9DQ0NHR0dLS0tPT09TU1NXV1dbW1tfX19jY2NnZ2dra2tvb29zc3N3d3d7e3t/f3+Dg4OHh4eLi4uPj4+Tk5OXl5ebm5ufn5+jo6Onp6erq6uvr6+zs7O3t7e7u7u/v7/Dw8PHx8fLy8vPz8/T09PX19fb29vf39/j4+Pn5+fr6+vv7+/z8/P39/f7+/v///yH5BAAAAAAAIf4fR2VuZXJhdGVkIGJ5IG9ubGluZUdJRnRvb2xzLmNvbQAsAAAAAAEAAQAACAQA8wQEADs=';

export const PASSWORD_MIN_LENGTH = 8;

export const SEARCH_TEXT_LENGTH_LIMIT = 128;

export const SNIPPET_SHORTCUT_MAX_LENGTH = 25;
export const SNIPPET_SHORTCUT_MIN_LENGTH = 2;

export const DEFAULT_MEDIA_PREVIEW_WIDTH = 320;
export const DEFAULT_MEDIA_PREVIEW_HEIGHT = 235;

export const MAX_MEDIA_PREVIEW_HEIGHT = 458;
export const MAX_MEDIA_PREVIEW_WIDTH = 320;

export const MAX_MEDIA_PREVIEW_HEIGHT_FOR_COMPACT = 320;
export const MAX_MEDIA_PREVIEW_WIDTH_FOR_COMPACT = 260;

export const MIN_MEDIA_PREVIEW_WIDTH = 110;

export const MAX_GROUP_MEDIA_PREVIEW_WIDTH = 428;
export const MIN_GROUP_MEDIA_PREVIEW_WIDTH = 160;

export const STICKER_IMAGE_SIZE = 208;
export const STICKER_ANIMATED_SIZE = 160;

export const MAX_REPLY_MESSAGE_INNER_WIDTH = 250;

export const MONTH_DELIMITER_FORMAT = 'MMMM yyyy';

export const MAX_MESSAGE_TEXT_LENGTH = 50000;
export const MAX_TELEGRAM_MESSAGE_WITH_MEDIA_TEXT_LENGTH = 1024;
export const MAX_TELEGRAM_MESSAGE_TEXT_LENGTH = 4000;
export const MAX_SNIPPET_TEXT_LENGTH = 2048;

export const GROUP_MESSAGE_ITEMS_LIMIT = 10;

export const WORKSPACE_NAME_LENGTH_LIMIT = 256;


export const PREFERABLE_TARIFF_ID = 'preferableTariffId';
