import cn from 'classnames';
import React from 'react';

import {entities} from '../api/proto';

import {ReactComponent as EmptyIconCircular} from '../assets/image-icons/emptyIconCircular.svg';
import {ReactComponent as TelegramIconCircular} from '../assets/image-icons/telegramIconCircular.svg';
import {ReactComponent as InstagramIconCircular} from '../assets/image-icons/instagramIconCircular.svg';

interface ChannelRoundIconProps {
  className?: string;
  type?: entities.OzekonChannelType | string | null;
}

export default function ChannelRoundIcon(props: ChannelRoundIconProps) {
  switch (props.type) {
    case entities.OzekonChannelType.OIT_TELEGRAM:
      return <TelegramIconCircular className={cn('channel-icon', props.className)} />;
    case entities.OzekonChannelType.OIT_INSTAGRAM:
      return <InstagramIconCircular className={cn('channel-icon', props.className)} />;
  }

  return <EmptyIconCircular className={cn('channel-icon', props.className)} />;
}