import React from 'react';

import Avatar from 'o-ui/Avatar';
import {IMCAttachment} from '../api/proto';
import useAvatarAttachment from '../pages/Chat/useAvatarAttachment';


interface AvatarWrapperProps {
  className?: string;
  size?: string;
  attachment?: IMCAttachment | null;
  channelID?: Uint8Array | null;
  name?: string | null;
  selected?: boolean;
}

export default React.memo(function AvatarWrapper({
  size,
  attachment,
  channelID,
  className,
  name,
  selected,
}: AvatarWrapperProps) {
  const {src} = useAvatarAttachment(attachment, channelID);

  return (
    <Avatar
      className={className}
      imageClassName=""
      size={size}
      src={src}
      name={attachment?.source ? null : name}
      selected={selected}
    />
  );
})
