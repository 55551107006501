import Cookies from 'js-cookie';

const COOKIE_SCRIPT_CONSENT_KEY = 'CookieScriptConsent';

interface CookieSelection {
  action?: 'accept' | 'reject',
  consenttime?: number,
  categories?: string[],
  version?: string,
  consentId?: string,
}

export function getSelection(): CookieSelection {
  const selectionStr = Cookies.get(COOKIE_SCRIPT_CONSENT_KEY);
  try {
    return selectionStr ? JSON.parse(selectionStr) : {};
  } catch (e) {
    console.error(e);
  }
  return {};
}

export function isAcceptedConsent(): boolean {
  const consent = getSelection();
  return consent.action === 'accept';
}

export function isAcceptedPerformance(): boolean {
  const conssent = getSelection();
  return !!conssent.categories?.includes('performance');
}

export function isAcceptedTargeting(): boolean {
  const conssent = getSelection();
  return !!conssent.categories?.includes('targeting');
}

export function isAcceptedOrRejectedConsent(): boolean {
  const consent = getSelection();
  return consent.action === 'accept' || consent.action === 'reject';
}

export function isNotAcceptedOrRejectedConsent(): boolean {
  const consent = getSelection();
  return consent.action !== 'accept' && consent.action !== 'reject';
}
