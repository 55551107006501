import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import ButtonWithTimer from '../components/ButtonWithTimer';
import useMobileMode from '../hooks/useMobileMode';
import ChangePasswordForm, {IChangePasswordFormRef} from '../pages/Settings/PersonalInfo/ChangePasswordForm';
import ModalType from '../stores/ModalType';
import {ModalItem} from '../stores/ModalsStore';
import useStore from '../stores/useStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

export const ModalProfileChangePassword = observer((props: IProps) => {
  const {t} = useTranslation();
  const {userStore, modals} = useStore();

  const modalRef = React.useRef<ModalDialogRef>(null);
  const formRef = React.useRef<IChangePasswordFormRef>(null);

  const handleSubmit = () => {
    if (formRef.current?.submitForm) {
      formRef.current.submitForm();
    }
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleOpenForgotPasswordModal = () => {
    modals.open(ModalType.PROFILE_FORGOT_PASSWORD_CONFIRM_TO_SEND_EMAIL, {email: userStore.user?.email}, true, true);
  };

  const isMobile = useMobileMode();

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('modal_profile_change_password_title')}
      className="modal-profile-change-email"
      maxWidth="md"
    >
      <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
        <ChangePasswordForm ref={formRef} onSubmit={handleCloseClick} />
      </DialogContent>
      <DialogActions className="justify-content-between">
        <ButtonWithTimer
          timer={userStore.changePasswordTimer}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {t('modal_profile_change_password_change_btn')}
        </ButtonWithTimer>

        <ButtonWithTimer
          timer={userStore.forgotPasswordTimer}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleOpenForgotPasswordModal}
        >
          {t('modal_profile_change_password_forgot_password_btn')}
        </ButtonWithTimer>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalProfileChangePassword;
