import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useMatch, useParams} from 'react-router';
import {animated} from 'react-spring';

import CircularProgress from 'o-ui/CircularProgress';
import useSlideTransition from '../../hooks/useSlideTransition';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import Paths from '../../routes/Paths';
import useStore from '../../stores/useStore';
import BillingHistoryTable from './Billing/BillingHistoryTable';
import NoActiveSubscriptionInfo from './Billing/BillingStatus/NoActiveSubscriptionInfo';
import BillingSubscriptionInfo from './Billing/BillingSubscriptionInfo';
import CancelSubscriptionForm from './Billing/PaymentForm/CancelSubscriptionForm';
import CancelSubscriptionSuccess from './Billing/PaymentForm/CancelSubscriptionSuccess';
import PaymentSuccessForm from './Billing/PaymentForm/PaymentSuccessForm';
import TariffSelect from './Billing/PaymentForm/TariffSelect';
import {SettingsHeadState} from './SettingsHead';

enum Pages {
  STATUS = 0,
  PAYMENT_SUCCESS = 1,
  SUBSCRIPTION_CANCEL = 2,
  SUBSCRIPTION_CANCELLED = 3,
}

interface IProps {
  onChangeState?: (state: SettingsHeadState) => void;
}

export interface IBillingTabRef {
  reset?: () => void;
}

export const BillingTab = observer(React.forwardRef<IBillingTabRef, IProps>(({onChangeState}: IProps, ref) => {

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      openPage(Pages.STATUS, Pages.STATUS);
    },
  }));

  const {t} = useTranslation();
  const workspaceNavigate = useWorkspaceNavigate();
  const {animationsEnabled, activeWorkspace} = useStore();
  const {billing} = activeWorkspace;

  React.useEffect(() => {
    billing.init();
  }, [billing]);

  const matchPaySuccess = useMatch(Paths.BillingPaySuccess);
  const {tariffId = ''} = useParams();

  const getInitialPage = () => {
    if (matchPaySuccess) {
      return Pages.PAYMENT_SUCCESS;
    }
    return Pages.STATUS;
  };

  const [prevPageIndex, setPrevPageIndex] = React.useState<Pages>(Pages.STATUS);
  const [pageIndex, setPageIndex] = React.useState<Pages>(getInitialPage());

  const transitions = useSlideTransition(pageIndex, prevPageIndex);

  const openPage = React.useCallback((newPage: Pages, prevPage: Pages) => {
    setPrevPageIndex(prevPage);
    setPageIndex(newPage);
  }, []);

  const goBack = React.useCallback((_pageIndex: Pages) => {
    switch (_pageIndex) {
      case Pages.PAYMENT_SUCCESS:
        workspaceNavigate(Paths.Billing);
        openPage(Pages.STATUS, _pageIndex);
        break;
      default:
        openPage(Pages.STATUS, _pageIndex);
        break;
    }
  }, [openPage, workspaceNavigate]);

  const getPageTitle = React.useCallback((page: Pages) => {
    switch (page) {
      case Pages.STATUS:
        return t('settings_billing_title');
      case Pages.PAYMENT_SUCCESS:
      case Pages.SUBSCRIPTION_CANCEL:
      case Pages.SUBSCRIPTION_CANCELLED:
        return t('settings_billing_subscription_title');
    }
  }, [t]);

  const getPageLinks = React.useCallback((page: Pages) => {
    switch (page) {
      case Pages.STATUS:
        return [];
      case Pages.PAYMENT_SUCCESS:
      case Pages.SUBSCRIPTION_CANCEL:
      case Pages.SUBSCRIPTION_CANCELLED:
        return [t('settings_billing_title')];
    }
  }, [t]);

  const state: SettingsHeadState = React.useMemo(() => {
    return {
      title: getPageTitle(pageIndex),
      links: getPageLinks(pageIndex),
      onBack: () => goBack(pageIndex),
    }
  }, [
    pageIndex,
    goBack,
    getPageTitle,
    getPageLinks,
  ]);

  React.useEffect(() => {
    onChangeState?.(state);
  }, [
    onChangeState,
    state
  ]);

  const handleClosePaymentForm = () => {
    openPage(Pages.STATUS, Pages.STATUS);
  };

  const handleOpenCancelClick = () => {
    openPage(Pages.SUBSCRIPTION_CANCEL, Pages.STATUS);
  };

  const handleSubmitCancelSubscription = () => {
    openPage(Pages.SUBSCRIPTION_CANCELLED, Pages.SUBSCRIPTION_CANCEL);
  };

  const handleCloseCancelSubscription = () => {
    openPage(Pages.STATUS, Pages.SUBSCRIPTION_CANCEL);
  };

  const handleCloseCancelledSubscription = () => {
    openPage(Pages.STATUS, Pages.SUBSCRIPTION_CANCELLED);
  };

  const getPageContent = (page: Pages) => {
    switch (page) {
      case Pages.STATUS:
        return (
          <>
            <NoActiveSubscriptionInfo className="mb-5 mb-sm-3" />

            <BillingSubscriptionInfo className="w-100 mb-5 mb-sm-3" />

            <TariffSelect
              className="w-100"
              itemClassName="mb-5 mb-sm-3"
              onCancelClick={handleOpenCancelClick}
            />

            <BillingHistoryTable />
          </>
        );
      case Pages.PAYMENT_SUCCESS:
        return (
          <PaymentSuccessForm
            className="bg-background-primary mb-auto"
            tariffId={tariffId}
            onClose={handleClosePaymentForm}
          />
        );
      case Pages.SUBSCRIPTION_CANCEL:
        return (
          <CancelSubscriptionForm
            className="bg-background-primary mb-auto"
            onSubmit={handleSubmitCancelSubscription}
            onClose={handleCloseCancelSubscription}
          />
        );
      case Pages.SUBSCRIPTION_CANCELLED:
        return (
          <CancelSubscriptionSuccess
            className="bg-background-primary mb-auto"
            onClose={handleCloseCancelledSubscription}
          />
        );
    }
  };

  return (
    <div className="settings-tab without-footer">
      <div
        className={cn(
          'settings-tab__body',
          'flex-column custom-scroll custom-scroll-y',
          {
            'justify-content-center align-items-center': !billing.isInit,
          },
        )}
      >
        {!billing.isInit ? (
          <CircularProgress
            size={72}
            style={{
              color: 'var(--brand-primary)',
            }}
          />
        ) : animationsEnabled && prevPageIndex !== pageIndex
          ? transitions((styles, item) => (
            <animated.div
              key={item}
              style={styles}
              className={cn('settings-tab__animated-tab')}
            >
              {getPageContent(item)}
            </animated.div>
          ))
          : getPageContent(pageIndex)}
      </div>
    </div>
  );
}));

export default BillingTab;
