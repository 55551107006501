import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import DialogActions from 'o-ui/Dialog/DialogActions';
import FormHelperText from 'o-ui/FormHelperText';
import InputAdornment from 'o-ui/Input/InputAdornment';
import InputLabel from 'o-ui/Input/InputLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';

import {entities} from '../../api/proto';
import ButtonWithTimer from '../../components/ButtonWithTimer';
import SnackMessage from '../../components/SnackMessage';
import {PASSWORD_MIN_LENGTH} from '../../constants';
import Paths from '../../routes/Paths';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import {formatDuration} from '../../utils/format';
import {LoginFormNotification} from './LoginForm';

interface IProps {
  className?: string;
  code: string;
  email: string;
  isProfile?: boolean;
  onSuccess?: () => void;
}

const isExpiredLink = (status?: entities.ResetPasswordResult | null) => {
  return !!(
    status &&
    [
      entities.ResetPasswordResult.RP_INVALID_CODE,
      entities.ResetPasswordResult.RP_CODE_EXPIRED,
      entities.ResetPasswordResult.RP_INVALID_EMAIL,
    ].includes(status)
  );
};

export const ResetPasswordForm = observer((props: IProps) => {
  const navigate = useNavigate();
  const {userStore, notifications, modals} = useStore();
  const {t} = useTranslation();

  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const [responseStatus, setResponseStatus] = useState<entities.ResetPasswordResult | undefined | null>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const formik = useFormik({
    initialValues: {
      code: props.code,
      email: props.email,
      newPassword: '',
      newPasswordConfirm: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required(t('reset_password_new_password_required'))
        .min(PASSWORD_MIN_LENGTH, t('reset_password_new_password_validation_size', {minSize: PASSWORD_MIN_LENGTH}))
        .matches(/[0-9]/, t('reset_password_new_password_validation_require_number'))
        .matches(/[a-z]/, t('reset_password_new_password_validation_require_lowercase_letter'))
        .matches(/[A-Z]/, t('reset_password_new_password_validation_require_uppercase_letter'))
        .matches(/[^\w]/, t('reset_password_new_password_validation_require_symbol')),
      newPasswordConfirm: Yup.string()
        .required(t('reset_password_new_password_confirm_required'))
        .nullable()
        .oneOf([Yup.ref('newPassword'), null], t('reset_password_new_password_confirm_validation')),
    }),
    onSubmit: async (values) => {
      setApiError('');
      setResponseStatus(null);
      const {error, res} = await userStore.resetPassword(values);

      if (error) {
        setApiError(error.message);
        notifications.error(error.message);
      }

      if (res) {
        setResponseStatus(res.status);

        if (isExpiredLink(res.status) && userStore?.user?.isLoggedIn) {
          modals.open(ModalType.PROFILE_FORGOT_PASSWORD_EXPIRED_LINK, {email: userStore.user?.email}, true, true);
        }

        if (res.status === entities.ResetPasswordResult.RP_OK) {
          if (props.isProfile && props.onSuccess) {
            props.onSuccess();
          } else {
            navigate(Paths.Login, {
              replace: true,
              state: {
                notificationType: LoginFormNotification.USE_NEW_PASSWORD,
                email: values.email,
              },
            });
          }
        }
      }
    },
  });

  const handleChange = (e: React.ChangeEvent) => {
    formik.handleChange(e);
    setApiError('');
    setResponseStatus(null);
  };

  return (
    <form className={props.className} onSubmit={formik.handleSubmit}>
      {userStore.resetPasswordTimer.inProgress ? (
        <SnackMessage
          className="mb-6"
          message={t('notification_too_many_request_message')}
          subMessage={t('notification_too_many_request_sub_message', {
            time: formatDuration(userStore.resetPasswordTimer.currentTime * 1000),
          })}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      {isExpiredLink(responseStatus) ? (
        <SnackMessage
          className="mb-6"
          message={t('reset_password_link_expired_message')}
          subMessage={t('reset_password_link_expired_sub_message')}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      <InputLabel className="mb-2 mt-3">{t('reset_password_new_password_label')}</InputLabel>
      <OutlinedTextInput
        className="w-100 mb-5"
        name="newPassword"
        placeholder={t('reset_password_new_password_placeholder')}
        textHelper={t('reset_password_new_password_helper', {minSize: PASSWORD_MIN_LENGTH})}
        type={showPassword ? 'text' : 'password'}
        error={!!(formik.touched.newPassword && formik.errors.newPassword)}
        errorHelper={formik.errors.newPassword || ' '}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.newPassword}
        required
        endAdornment={
          <InputAdornment position="end">
            <VisibilitySwitcher onClick={handleClickShowPassword} visible={showPassword} />
          </InputAdornment>
        }
      />

      <InputLabel className="mb-2 mt-3">{t('reset_password_new_password_confirm_label')}</InputLabel>
      <OutlinedTextInput
        className="w-100 mb-0"
        name="newPasswordConfirm"
        placeholder={t('reset_password_new_password_confirm_placeholder')}
        type={showPasswordConfirmation ? 'text' : 'password'}
        error={!!(formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm)}
        errorHelper={formik.errors.newPasswordConfirm || ' '}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.newPasswordConfirm}
        required
        endAdornment={
          <InputAdornment position="end">
            <VisibilitySwitcher onClick={handleClickShowPasswordConfirmation} visible={showPasswordConfirmation} />
          </InputAdornment>
        }
      />

      <FormHelperText error className="mt-3 mb-3">
        {apiError || ' '}
      </FormHelperText>

      {props.isProfile ? (
        <DialogActions className="p-0 pt-6">
          <ButtonWithTimer
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            loading={userStore.loading}
            timer={userStore.resetPasswordTimer}
          >
            {t('reset_password_submit')}
          </ButtonWithTimer>
        </DialogActions>
      ) : (
        <ButtonWithTimer
          className="w-100"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          loading={userStore.loading}
          timer={userStore.resetPasswordTimer}
        >
          {t('reset_password_submit')}
        </ButtonWithTimer>
      )}

      {!props.isProfile ? (
        <div className="auth-page__join-link">
          {t('reset_password_did_remember')}
          <RouterLink to={Paths.Login} className="auth-page__link">
            {t('reset_password_back_to_login')}
          </RouterLink>
        </div>
      ) : null}
    </form>
  );
});

export default ResetPasswordForm;
