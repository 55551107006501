import React from 'react';
import cookieIcon from "../../assets/images/cookie.svg";


const positionStyle: React.CSSProperties = {
  backgroundColor: 'var(--background-primary)',
  border: ' 1px solid var(--body-separator)',
  borderRadius: '12px',
  boxShadow: '2px 2px 18px 0px rgba(0, 0, 0, 0.10)',
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  zIndex: 99999,
  transitionDuration: '500ms',
  padding: '8px',
  lineHeight: 0,
  fontSize: 0,
  opacity: 1,
  cursor: 'pointer',
};

interface CookieButtonProps {
  onClick: () => void;
}

export function CookieButton(props: CookieButtonProps) {
  return (
    <button
      style={{
        ...positionStyle,
        maxWidth: 460,
      }}
      onClick={props.onClick}
      type="button"
      name="cookie-button"
      aria-label="cookie-manage"
    >
      <img
        src={cookieIcon}
        alt=""
        width={28}
        height={28}
      />
    </button>
  );
}

export default CookieButton;