import {FormikProps, useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';
import * as Yup from 'yup';

import DialogContent from 'o-ui/Dialog/DialogContent';
import {entities} from '../api/proto';
import {EMAIL_REGEX} from '../constants';
import useMobileMode from '../hooks/useMobileMode';
import InvitationAccessForm from '../pages/Settings/WorkspaceMembers/Invitation/InvitationAccessForm';
import InvitationByEmailForm from '../pages/Settings/WorkspaceMembers/Invitation/InvitationByEmailForm';
import {ModalItem} from '../stores/ModalsStore';
import WorkspaceMemberConfig from '../stores/Workspaces/WorkspaceMemberConfig';
import getCreateInviteError from '../stores/Workspaces/utils/getCreateInviteError';
import useStore from '../stores/useStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';


export type InvitationByEmailFormikProps = FormikProps<{
  email: string;
  role: entities.WorkspaceRole.WR_OPERATOR;
  firstName: string;
  lastName: string;
}>;

interface IProps {
  modal: ModalItem;
}

export const ModalInviteMemberByEmail: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const app = useStore();
  const {activeWorkspace, notifications} = app;
  const modalRef = React.useRef<ModalDialogRef>(null);
  const isMobile = useMobileMode();

  const [memberConfigOpen, setMemberConfigOpen] = React.useState(false);

  const [memberConfig] = React.useState<WorkspaceMemberConfig>(new WorkspaceMemberConfig(app.activeWorkspace));

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<string | null | undefined>('');

  const invitationByEmailFormik: InvitationByEmailFormikProps = useFormik({
    initialValues: {
      email: '',
      role: entities.WorkspaceRole.WR_OPERATOR,
      firstName: '',
      lastName: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_REGEX, t('settings_workspace_members_invitation_email_placeholder'))
        .required(t('settings_workspace_members_invitation_email_required')),
      role: Yup.number().required(t('settings_workspace_members_invitation_role_required')),
    }),
    onSubmit: async (values) => {
      setSubmitting(true);
      const {error, res} = await activeWorkspace.invites.invite({
        email: values.email,
        role: values.role,
        access: {
          records: memberConfig.rawChannelAccessRecords,
        },
      });
      setSubmitting(false);

      if (error) {
        notifications.error(error.message);
      }

      if (res) {
        const statusErrorMessage = getCreateInviteError(res.status);

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          invitationByEmailFormik.setErrors({email: statusErrorMessage});
          setApiError(statusErrorMessage);
          return;
        }

        await activeWorkspace.load();
        handleCloseClick();
        setApiError(null);

        notifications.success(t('settings_workspace_members_invitation_successfully_sent_notification'));
      }
    },
  });

  const transitions = useTransition(memberConfigOpen, {
    from: !memberConfigOpen
      ? {}
      : {
        opacity: 0,
        transform: memberConfigOpen ? 'translate3d(100%, 0, 0)' : 'translate3d(-100%, 0, 0)',
      },
    enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
    leave: {
      opacity: 0,
      transform: memberConfigOpen ? 'translate3d(-100%, 0, 0)' : 'translate3d(100%, 0, 0)',
    },
  });

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const onOpenInvitationAccess = React.useCallback(() => {
    setMemberConfigOpen(true);
  }, []);

  const goBackFromInvitationAccess = React.useCallback(() => {
    setMemberConfigOpen(false);
  }, []);

  const additionalModalHeight = apiError ? 20 : 0;

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      maxWidth="sm"
      fullWidth={true}
      title={memberConfigOpen ? t('Accesses') : t('settings_workspace_members_invite_by_email')}
      onBack={memberConfigOpen ? goBackFromInvitationAccess : undefined}
      onClose={handleCloseClick}
    >
      <DialogContent
        className="position-relative overflow-hidden"
        style={isMobile ? {} : {
          width: '600px',
          height: memberConfigOpen ? `${642 + additionalModalHeight}px` : `${287 + additionalModalHeight}px`,
        }}
      >
        {transitions((styles, step) =>
          step ? (
            <animated.div style={styles} className="animated-tab p-6 h-100">
              <InvitationAccessForm
                className="h-100"
                memberConfig={memberConfig}
                onClose={goBackFromInvitationAccess}
              />
            </animated.div>
          ) : (
            <animated.div style={styles} className="animated-tab p-6">
              <InvitationByEmailForm
                formik={invitationByEmailFormik}
                onOpenInvitationAccess={onOpenInvitationAccess}
                submitting={submitting}
              />
            </animated.div>
          ),
        )}
      </DialogContent>
    </ModalDialog>
  );
});

export default ModalInviteMemberByEmail;
