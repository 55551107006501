import React from 'react';

import Chat from '../../stores/Chat';
import getChatUploadLimits from '../../stores/Chat/utils/getChatUploadLimits';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import FileData from '../../utils/file/FileData';
import FileInput from './FileInput';
import useFileChangeHendler from './useFileChangeHendler';


interface MultiFileUploaderProps {
  chat: Chat;
  className?: string;
}

export const MultiFileUploader = React.forwardRef<HTMLInputElement, MultiFileUploaderProps>((
  {chat, className},
  ref,
) => {
  const {modals} = useStore();

  const limits = getChatUploadLimits(chat);

  const accept = chat.channel.isInstagram ? Array.from(new Set([
    ...limits.contentTypes.audio,
    ...limits.contentTypes.image,
    ...limits.contentTypes.video,
  ])).join(',') : undefined;

  const handleSend = (dataFiles: FileData[]) => {
    const photoCount = dataFiles?.filter((f) => f.image).length || 0;
    const videoCount = dataFiles?.filter((f) => f.video).length || 0;

    if (dataFiles.length === photoCount + videoCount) {
      modals.open(ModalType.UPLOAD_PHOTO_VIDEO_ASYNC, {dataFiles, chat});
    } else {
      modals.open(ModalType.UPLOAD_FILE_ASYNC, {dataFiles, chat});
    }
  };

  const handleFileChange = useFileChangeHendler(handleSend);

  return (
    <FileInput
      className={className}
      ref={ref}
      onChange={handleFileChange}
      multiple={true}
      accept={accept}
    />
  );
});

export default MultiFileUploader;
