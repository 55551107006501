import React from 'react';

import AvatarWrapper from './AvatarWrapper';
import Workspace from '../stores/Workspaces/Workspace';


interface WorkspaceAvatarProps {
  className?: string;
  size?: string;
  workspace: Workspace;
}

export default function WorkspaceAvatar({
  size,
  className,
  workspace,
}: WorkspaceAvatarProps) {
  return (
    <AvatarWrapper
      className={className}
      size={size}
      name={workspace.displayName || workspace.uuid}
      attachment={workspace.avatar ? {source: workspace.avatar} : undefined}
      channelID={workspace.id}
    />
  );
}