import {observer} from 'mobx-react';
import React from 'react';

import WorkspaceAvatar from '../../../../components/WorkspaceAvatar';
import {navigateToWorkspace} from '../../../../routes/Paths';
import Workspace from '../../../../stores/Workspaces/Workspace';
import useStore from '../../../../stores/useStore';
import {equalUint8Arrays, uint8ArrayToUuid} from '../../../../utils/arrayUtils';


interface WorkspaceBarItemProps {
  className?: string;
  workspace: Workspace;
}

export const WorkspaceBarItem: React.FC<WorkspaceBarItemProps> = observer(({
  workspace,
}) => {
  const {activeWorkspace} = useStore();

  const handleSelectWorkspace = () => {
    navigateToWorkspace(workspace.id);
  };

  return (
    <button
      key={workspace.uuid}
      data-workspace-id={workspace.uuid}
      data-avatar-reference={uint8ArrayToUuid(workspace.avatar?.reference)}
      type="button"
      className="workspace-bar__menu-item dropdown-item d-flex"
      onClick={handleSelectWorkspace}
    >
      <WorkspaceAvatar
        className="workspace-bar__menu-item-avatar"
        size="xs"
        workspace={workspace}
      />
      <span
        className="workspace-bar__menu-item-name body2-bold color-body-primary text-truncate"
        title={workspace.name || workspace.uuid}
      >
        {workspace.name || workspace.uuid}
      </span>
      {equalUint8Arrays(workspace.id, activeWorkspace.id) ? (
        <i className="workspace-bar__active tg-icon tg-icon-check-line ml-auto" />
      ) : null}
    </button>
  );
});

export default WorkspaceBarItem;