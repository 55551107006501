import React from 'react';

import Button from 'o-ui/Button';
import Link from 'o-ui/Link';
import CrossLine from 'o-ui/icons/CrossLine';

import cookieIcon from "../../assets/images/cookie.svg";
import {acceptAllCookies, rejectCookieSelection} from './cookiesStateManager';


const positionStyle: React.CSSProperties = {
  backgroundColor: 'var(--background-primary)',
  border:' 1px solid var(--body-separator)',
  borderRadius: '16px',
  boxShadow: '2px 2px 18px 0px rgba(0, 0, 0, 0.10)',
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  zIndex: 99999,
  transitionDuration: '500ms',
  padding: '15px',
};

interface ConsentDialogProps {
  onAcceptAllClick?: () => void;
  onCustomizeClick?: () => void;
  onRejectAllClick?: () => void;
  onCloseClick?: () => void;
}

export function ConsentDialog(props: ConsentDialogProps) {

  const handleAcceptAllClick = () => {
    acceptAllCookies();
    props.onAcceptAllClick?.();
  };

  const handleRejectAllClick = () => {
    rejectCookieSelection();
    props.onRejectAllClick?.();
  };

  return (
    <div
      style={{
        ...positionStyle,
        maxWidth: 460,
      }}
    >

      <div className="d-flex flex-row">
        <img
          src={cookieIcon}
          alt=""
          width={32}
          height={32}
        />

        <Button
          className="ml-auto color-body-placeholder"
          onClick={props.onCloseClick}
          size="extra-small"
          variant="text"
          name="close-button"
          aria-label="Close dialog"
        >
          <CrossLine />
        </Button>
      </div>

      <div>
        <div className="body1-bold mt-6">Your Privacy Matters to Us</div>
        <div className="body2-regular mt-2">
          We use cookies to enhance your experience, analyze site traffic, and deliver personalized content. You can accept all cookies, customize them to your preference, or reject non-essential ones.
        </div>
        <div className="body2-regular mt-2">
          <span>Please read our</span>
          <Link
            className="body2-regular color-brand-primary mx-1"
            href="/privacy-policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <span>for more details.</span>
        </div>
      </div>

      <div className="mt-4">
        <Button
          className="mr-2"
          onClick={handleAcceptAllClick}
          size="medium"
          color="primary"
          variant="contained"
        >
          Accept All
        </Button>
        <Button
          className="mr-2"
          onClick={props.onCustomizeClick}
          size="medium"
          color="secondary"
          variant="contained"
        >
          Customize
        </Button>
        <Button
          className="mr-2"
          onClick={handleRejectAllClick}
          size="medium"
          color="tertiary"
          variant="contained"
        >
          Reject all
        </Button>
      </div>
    </div>
  );
}

export default ConsentDialog;