import {action, makeObservable, observable} from 'mobx';
import Delta from 'quill-delta';

import {MAX_TELEGRAM_MESSAGE_TEXT_LENGTH} from '../../constants';
import Sources from '../../components/QuillTextInput/QuillCore/core/emmiterSources';
import QuillEditor from '../../components/QuillTextInput/QuillEditor';
import Chat from '../Chat';
import {MentionEntity, MentionUser} from '../MentionUser';
import Message from '../Message';
import TextEditorProxy from '../TextEditorProxy';
import chunkText, {IChunkText} from './utils/chunkText';
import convertDeltaToTextEntities from './utils/convertDeltaToTextEntities';
import trimGuardText from '../../utils/trimGuardText';

export const MENTION_SYMBOL = '@';
export const SHORTCUT_SYMBOL = '/';


export const trimMentionSymbol = (text: string): string => {
  return text.replace(MENTION_SYMBOL, '');
};

export const EMPTY_DELTA: Delta = new Delta({
  ops: [{insert: '\n'}],
});


export class EditorStore {
  constructor(private editorProxy: TextEditorProxy, private chat?: Chat) {
    makeObservable(this);
  }

  private get _editor() {
    return this.editorProxy;
  }

  get quill() {
    return this._editor.quill;
  }

  setEditorRef = (ref: QuillEditor | null) => {
    this._editor.setEditorRef(ref);
  };

  @observable contents: Delta = EMPTY_DELTA;

  @action saveContents = (contents: Delta) => {
    this.contents = contents;
  };

  focus = () => {
    this.quill?.setSelection(this.quill?.getLength(), 0);
  };

  getLength = (): number | undefined => {
    return this.quill?.getLength();
  };

  setSelection = (index?: number | null, length?: number, source?: Sources) => {
    this.quill?.setSelection(index || 0, length || 0, source);
  };

  getContents = () => {
    return this.quill?.getContents();
  };

  setContents = (contents: Delta, disableSelection: boolean = false) => {
    if (!this.quill) {
      console.warn('quill is undefined');
    }
    this.quill?.setContents(contents);
    if (!disableSelection) {
      this.quill?.setSelection(this.quill?.getLength(), 0);
    }
    this.saveContents(contents);
  };

  setMessage = (message: Message | null) => {
    if (message) {
      // const delta = convertTextEntitiesToDelta(message.text, message.textEntities);
      this.setContents(
        new Delta({
          ops: [{insert: message.text}],
        }),
      );
    } else {
      this.setContents(EMPTY_DELTA);
    }
  };

  setText = (text: string | null) => {
    if (text) {
      const delta = new Delta({
        ops: [{insert: text}],
      });
      this.setContents(delta);
    } else {
      this.setContents(EMPTY_DELTA);
    }
  };

  @observable mentionEntities: MentionEntity[] = [];

  get textEntities() {
    const text = this.quill?.getText().trim();
    const contents = this.quill?.getContents();
    if (text) {
      // need to set trimmed text before getContents
      this.quill?.setText(text);

      if (contents) {
        this.quill?.setContents(contents);
      }
    }

    return convertDeltaToTextEntities(contents, text);
  }

  get lines() {
    return this.quill?.getLines() || [];
  }

  @observable linesNumber: number = 0;

  @action setLinesNumber = (linesNumber: number) => {
    this.linesNumber = linesNumber;
  };

  get text() {
    let text = '';
    // this.lines?.forEach((line) => (text += line.domNode.innerText + '\n'));
    this.lines?.forEach(
      (line) => (text += line.domNode.innerText === '\n' ? line.domNode.innerText : line.domNode.innerText + '\n'),
    );
    return text.trim();
  }

  get selection() {
    const range = this.quill?.getSelection(true);
    return {index: range?.index || 0, length: range?.length || 0};
  }

  get currentMentionText(): string {
    const range = this.quill?.getSelection(true);
    const cursorPosition = range?.index || 0;

    const textBeforeCursor = this.quill?.getText(0, cursorPosition);

    if (
      textBeforeCursor &&
      textBeforeCursor.lastIndexOf(MENTION_SYMBOL) >= 0 &&
      !(
        /\s/g.test(textBeforeCursor.substr(textBeforeCursor.lastIndexOf(MENTION_SYMBOL))) &&
        textBeforeCursor[textBeforeCursor.length - 1] !== '\n'
      )
    ) {
      const mentionText = textBeforeCursor.substr(textBeforeCursor.lastIndexOf(MENTION_SYMBOL));
      return mentionText.trim();
    }

    return '';
  }

  get currentMentionName(): string {
    if (this.currentMentionText) {
      const mentionName = this.currentMentionText.substr(MENTION_SYMBOL.length);
      return mentionName.trim();
    }
    return '';
  }

  get currentShortcutText(): string {
    const range = this.quill?.getSelection(true);
    const cursorPosition = range?.index || 0;

    const textBeforeCursor = this.quill?.getText(0, cursorPosition)?.trim();

    if (
      textBeforeCursor &&
      textBeforeCursor.lastIndexOf(SHORTCUT_SYMBOL) === 0 &&
      !(
        /\s/g.test(textBeforeCursor.substr(textBeforeCursor.lastIndexOf(SHORTCUT_SYMBOL))) &&
        textBeforeCursor[textBeforeCursor.length - 1] !== '\n'
      )
    ) {
      const mentionText = textBeforeCursor.substr(textBeforeCursor.lastIndexOf(SHORTCUT_SYMBOL));
      return mentionText.trim();
    }

    return '';
  }

  get currentShortcutValue(): string {
    if (this.currentShortcutText) {
      const mentionName = this.currentShortcutText.substr(SHORTCUT_SYMBOL.length);
      return mentionName.trim();
    }
    return '';
  }

  store = () => {
    const contents = this.quill?.getContents();
    if (contents) {
      this.saveContents(contents);
    }

    const text = this.quill?.getText().trim() || '';
    this.chat?.store?.draftMessage?.save(text);
  };

  restore = () => {
    if (this.chat?.draft) {
      this.setText(this.chat.draft);
    } else {
      this.setContents(this.contents);
    }
  };

  @action reset = () => {
    this.mentionEntities = [];
    this._editor.reset();
  };

  @action insertText = (text: string) => {
    const range = this.quill?.getSelection(true);
    const cursorPosition = range?.index || 0;
    const selectionLength = range?.length || 0;

    if (selectionLength) {
      this.quill?.deleteText(cursorPosition, selectionLength);
    }

    this.quill?.insertText(cursorPosition, text);
    setTimeout(() => this.quill?.setSelection(cursorPosition + text.length, 0), 0);
  };

  @action insertMention = (mentionUser: MentionUser) => {
    const username = mentionUser.user.username ? MENTION_SYMBOL + mentionUser.user.username : '';
    /*
    if (!username) {
      username = mentionUser.user.id ? MENTION_SYMBOL + mentionUser.user.id.toString() : '';
    }
    */
    const mentionedUserData = {
      name: mentionUser.user.name,
      username,
      userid: mentionUser.user.id?.toString(),
    };

    const range = this.quill?.getSelection(true);
    const cursorPosition = range?.index || 0;

    const spaceLength = 1;
    const currentMentionTextLength = this.currentMentionText.length;
    const startCurPos = cursorPosition - currentMentionTextLength;
    const newMentionTextLength = (mentionUser?.mentionText?.length || 0) + MENTION_SYMBOL.length + spaceLength;

    this.quill?.deleteText(startCurPos, currentMentionTextLength);
    this.quill?.insertEmbed(startCurPos, 'mention', mentionedUserData, Sources.API);
    this.quill?.insertText(startCurPos + spaceLength, ' ');
    setTimeout(() => this.quill?.setSelection(startCurPos + newMentionTextLength, 0), 0);
  };

  getTextChunks = (chunkMaxLength = MAX_TELEGRAM_MESSAGE_TEXT_LENGTH): Array<IChunkText> => {
    const trimedText = trimGuardText(this.text);
    if (trimedText.length < chunkMaxLength) {
      return [{text: trimedText, textEntities: this.textEntities}];
    }

    const contents = this.quill?.getContents();

    const chunks = chunkText(trimedText, contents, chunkMaxLength);

    if (chunks.length > 1) {
      console.debug(`getTextChunks total text length=${trimedText.length}`, chunks)
      chunks.forEach((chunk, i) => {
        console.debug(`chunk ${i}: length=${chunk.text.length}`)
      });
    }

    return chunks;
  };
}

export default EditorStore;