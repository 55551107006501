import React from 'react';

import AuthLayout from '../layouts/AuthLayout';
import AuthSideMenuLayout from '../layouts/AuthSideMenuLayout';
import NonAuthLayout from '../layouts/NonAuthLayout';
import AcceptInvitePage from '../pages/Auth/AcceptInvitePage';
import ApprovePrivateRegistrationPage from '../pages/Auth/ApprovePrivateRegistrationPage';
import EmailVerificationCompletePage from '../pages/Auth/EmailVerificationCompletePage';
import EmailVerificationPage from '../pages/Auth/EmailVerificationPage';
import ExpiredInvitePage from '../pages/Auth/ExpiredInvitePage';
import ForgotPasswordPage from '../pages/Auth/ForgotPasswordPage';
import LoginPage from '../pages/Auth/LoginPage';
import Logout from '../pages/Auth/Logout';
import PrivateRegistrationPage from '../pages/Auth/PrivateRegistrationPage';
import RecoveryPasswordPage from '../pages/Auth/RecoveryPasswordPage';
import RegistrationCompletePage from '../pages/Auth/RegistrationCompletePage';
import RegistrationPage from '../pages/Auth/RegistrationPage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage';
import ChatPage from '../pages/Chat/ChatPage';
import OnboardingPage from '../pages/Onboarding/OnboardingPage';
import QuickOnboardingPage from '../pages/Onboarding/QuickOnboardingPage';
import ChannelsInstagramCallbackPage from '../pages/Settings/ChannelsInstagramCallbackPage';
import SettingsPage from '../pages/Settings/SettingsPage';
import SettingsRedirect from '../pages/Settings/SettingsRedirect';
import WidgetApiDocsPage from '../pages/WidgetApiDocs/WidgetApiDocsPage';
import BlockedWorkspaceAccess from '../pages/Workspace/BlockedWorkspaceAccess';
import Paths from '../routes/Paths';
import BillingRedirect from './redirects/BillingRedirect';
import LocalRedirect from './redirects/LocalRedirect';
import ResetPasswordRedirect from './redirects/ResetPasswordRedirect';

interface ReactPropTypes extends React.ReactPropTypes {
  children?: React.ReactNode;
}

export type RouteLayoutType = React.ComponentType<ReactPropTypes>;

export type RouteComponentType = React.ComponentType<ReactPropTypes>;

export interface IRoutePathProps {
  children?: React.ReactNode;
  path: string;
  isAuthProtected?: boolean;
  caseSensitive?: boolean;
  strict?: boolean;
}

export interface IRouteProps extends IRoutePathProps {
  layout: React.FC<React.PropsWithChildren>;
  component: React.FC<IRoutePathProps>;
}

export const authProtectedRoutes: IRouteProps[] = [
  {path: Paths.SpecificMessage, component: ChatPage, layout: AuthSideMenuLayout},
  {path: Paths.SpecificChat, component: ChatPage, layout: AuthSideMenuLayout},
  {path: Paths.Chat, component: ChatPage, layout: AuthSideMenuLayout},
  {path: Paths.UserChat, component: ChatPage, layout: AuthSideMenuLayout},
  {path: Paths.Channel, component: ChatPage, layout: AuthSideMenuLayout},
  {path: Paths.Team, component: ChatPage, layout: AuthSideMenuLayout},

  {path: Paths.PersonalInfo, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.ResetPasswordProfile, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.Channels, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.ChannelsInstagramCallback, component: ChannelsInstagramCallbackPage, layout: AuthSideMenuLayout},
  {path: Paths.WorkspaceMembers, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.Snippets, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.WorkspaceSettings, component: SettingsPage, layout: AuthSideMenuLayout},

  {path: Paths.Billing, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.BillingPayCancel, component: BillingRedirect, layout: AuthSideMenuLayout},
  {path: Paths.BillingPaySuccess, component: SettingsPage, layout: AuthSideMenuLayout},
  {path: Paths.BillingTariff, component: SettingsPage, layout: AuthSideMenuLayout},

  {path: Paths.RawBillingTariff, component: SettingsRedirect, layout: AuthSideMenuLayout},

  {path: Paths.DownloadApp, component: SettingsPage, layout: AuthSideMenuLayout},

  {path: Paths.BlockedWorkspaceAccess, component: BlockedWorkspaceAccess, layout: AuthLayout},

  {path: Paths.RegistrationComplete, component: RegistrationCompletePage, layout: AuthLayout},
  {path: Paths.Onboarding, component: OnboardingPage, layout: AuthLayout},
  {path: Paths.QuickOnboarding, component: QuickOnboardingPage, layout: AuthLayout},
  {path: Paths.RecoveryPassword, component: RecoveryPasswordPage, layout: AuthLayout},
  {path: Paths.AcceptInvite, component: AcceptInvitePage, layout: AuthLayout},
  {path: Paths.Default, component: LocalRedirect, layout: AuthLayout},
  {path: '*', component: LocalRedirect, layout: AuthLayout},
].map((item) => ({...item, isAuthProtected: true}));


export const publicRoutes: IRouteProps[] = [
  {path: Paths.Logout, component: Logout, layout: NonAuthLayout},
  {path: Paths.Login, component: LoginPage, layout: NonAuthLayout},
  {path: Paths.Registration, component: RegistrationPage, layout: NonAuthLayout},
  {path: Paths.RegistrationByInvite, component: PrivateRegistrationPage, layout: NonAuthLayout},
  {path: Paths.PrivateRegistration, component: PrivateRegistrationPage, layout: NonAuthLayout},
  {path: Paths.ApprovePrivateRegistration, component: ApprovePrivateRegistrationPage, layout: NonAuthLayout},
  {path: Paths.EmailVerification, component: EmailVerificationPage, layout: NonAuthLayout},
  {path: Paths.EmailVerificationComplete, component: EmailVerificationCompletePage, layout: NonAuthLayout},
  {path: Paths.ForgotPassword, component: ForgotPasswordPage, layout: NonAuthLayout},
  {path: Paths.ResetPasswordRedirect, component: ResetPasswordRedirect, layout: NonAuthLayout},
  {path: Paths.ResetPasswordNonAuth, component: ResetPasswordPage, layout: NonAuthLayout},
  {path: Paths.ExpiredInvite, component: ExpiredInvitePage, layout: NonAuthLayout},
  {path: Paths.WidgetApiDocs, component: WidgetApiDocsPage, layout: NonAuthLayout},
];

export const routes = [...publicRoutes, ...authProtectedRoutes];

export const authPageOpen = (): boolean => {
  return (
    window.location.pathname === Paths.Login ||
    window.location.pathname === Paths.Registration ||
    window.location.pathname === Paths.RegistrationComplete ||
    window.location.pathname.startsWith(Paths.EmailVerification.replace(':code', '')) ||
    window.location.pathname === Paths.EmailVerificationComplete ||
    window.location.pathname === Paths.ForgotPassword ||
    window.location.pathname.startsWith(Paths.ResetPasswordRedirect.replace(':code', '').replace(':email', ''))
  );
};

export const pageIsOpen = (path: string): boolean => {
  return window.location.pathname === path;
};
