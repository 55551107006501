import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import {FormHelperText} from 'o-ui';
import InputAdornment from 'o-ui/Input/InputAdornment';
import InputLabel from 'o-ui/Input/InputLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';

import {getUserResponseStatusError} from '../../../api/getErrorByType';
import {entities} from '../../../api/proto';
import SnackMessage from '../../../components/SnackMessage';
import {PASSWORD_MIN_LENGTH} from '../../../constants';
import useStore from '../../../stores/useStore';
import {formatDuration} from '../../../utils/format';

const {ChangePasswordResult} = entities;

interface IProps {
  className?: string;
  onSubmit?: () => void;
}

export interface IChangePasswordFormRef {
  submitForm?: () => void;
}

export const ChangePasswordForm = observer(
  React.forwardRef<IChangePasswordFormRef, IProps>((props, ref) => {
    const {notifications, userStore} = useStore();
    const {t} = useTranslation();

    const [apiError, setApiError] = React.useState<string | null | undefined>('');
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

    const handleClickShowCurrentPassword = () => {
      setShowCurrentPassword(!showCurrentPassword);
    };

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleClickShowPasswordConfirmation = () => {
      setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const formik = useFormik({
      initialValues: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        currentPassword: Yup.string().required(t('settings_personal_info_change_password_current_required')),
        newPassword: Yup.string()
          .required(t('settings_personal_info_change_password_new_required'))
          .min(
            PASSWORD_MIN_LENGTH,
            t('settings_personal_info_change_password_new_validation_size', {minLength: PASSWORD_MIN_LENGTH}),
          )
          .matches(/[0-9]/, t('settings_personal_info_change_password_new_validation_require_number'))
          .matches(/[a-z]/, t('settings_personal_info_change_password_new_validation_require_lowercase_letter'))
          .matches(/[A-Z]/, t('settings_personal_info_change_password_new_validation_require_uppercase_letter'))
          .matches(/[^\w]/, t('settings_personal_info_change_password_new_validation_require_symbol')),
        newPasswordConfirmation: Yup.string()
          .required(t('settings_personal_info_change_password_repeat_new_required'))
          .nullable()
          .oneOf([Yup.ref('newPassword'), null], t('settings_personal_info_change_password_repeat_new_validation')),
      }),
      onSubmit: async (values) => {
        setApiError('');
        const {error, res} = await userStore.changePassword(values);

        if (error) {
          setApiError(error.message);
          notifications.error(error.message);
        }

        if (res) {
          const statusErrorMessage = getUserResponseStatusError('changePassword', res.status);

          if (res.status === ChangePasswordResult.CPR_INVALID_OLD_PASSWORD && statusErrorMessage) {
            formik.setErrors({currentPassword: statusErrorMessage});
            return;
          }

          if (statusErrorMessage) {
            notifications.error(statusErrorMessage);
            setApiError(statusErrorMessage);
            return;
          }

          notifications.success(t('settings_personal_info_change_password_submit_success'));
          props.onSubmit && props.onSubmit();
        }
      },
      onReset: () => {
        setApiError('');
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
    }));

    const handleChange = (e: React.ChangeEvent) => {
      formik.handleChange(e);
      setApiError('');
    };

    return (
      <form className={cn(props.className)} onSubmit={formik.handleSubmit}>
        {userStore.changePasswordTimer.inProgress ? (
          <SnackMessage
            className="mb-5"
            message={t('notification_too_many_request_message')}
            subMessage={t('notification_too_many_request_sub_message', {
              time: formatDuration(userStore.changePasswordTimer.currentTime * 1000),
            })}
            variant="error"
            hideCloseIcon
          />
        ) : null}

        <InputLabel className="mb-2">{t('settings_personal_info_change_password_current_label')}</InputLabel>
        <OutlinedTextInput
          className="w-100"
          name="currentPassword"
          placeholder={t('settings_personal_info_change_password_current_placeholder')}
          type={showCurrentPassword ? 'text' : 'password'}
          error={!!(formik.touched.currentPassword && formik.errors.currentPassword)}
          errorHelper={formik.errors.currentPassword || ' '}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.currentPassword}
          required
          endAdornment={
            <InputAdornment position="end">
              <VisibilitySwitcher onClick={handleClickShowCurrentPassword} visible={showCurrentPassword} />
            </InputAdornment>
          }
        />

        <InputLabel className="mb-2 mt-3">{t('settings_personal_info_change_password_new_label')}</InputLabel>
        <OutlinedTextInput
          className="w-100"
          name="newPassword"
          placeholder={t('settings_personal_info_change_password_new_placeholder')}
          type={showPassword ? 'text' : 'password'}
          error={!!(formik.touched.newPassword && formik.errors.newPassword)}
          errorHelper={formik.errors.newPassword || ' '}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          required
          endAdornment={
            <InputAdornment position="end">
              <VisibilitySwitcher onClick={handleClickShowPassword} visible={showPassword} />
            </InputAdornment>
          }
        />

        <InputLabel className="mb-2">{t('settings_personal_info_change_password_repeat_new_label')}</InputLabel>
        <OutlinedTextInput
          className="w-100"
          name="newPasswordConfirmation"
          placeholder={t('settings_personal_info_change_password_repeat_new_placeholder')}
          type={showPasswordConfirmation ? 'text' : 'password'}
          error={!!(formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation)}
          errorHelper={formik.errors.newPasswordConfirmation || ' '}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPasswordConfirmation}
          required
          endAdornment={
            <InputAdornment position="end">
              <VisibilitySwitcher onClick={handleClickShowPasswordConfirmation} visible={showPasswordConfirmation} />
            </InputAdornment>
          }
        />
        <FormHelperText className="mb-3" error>
          {apiError || ' '}
        </FormHelperText>
      </form>
    );
  }),
);

export default ChangePasswordForm;
