import React from 'react';
import {useTranslation} from 'react-i18next';

const style: React.CSSProperties = {
  border: '1px solid rgba(255, 255, 255, 0.10)',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
};

export default function AuthBenefits() {
  const {t} = useTranslation();

  return (
    <div className="d-none d-lg-flex mt-6 mt-sm-auto w-100 flex-column">
      <div
        className="mt-4 p-4 radius-12 body1-bold color-body-tint d-flex aligne-items-center"
        style={style}
      >
        <span className="my-auto mr-3 w-6 h-6 font-size-12 tg-icon tg-icon-check-2-line" />
        {t('login_benefit_1')}
      </div>
      <div
        className="mt-4 p-4 radius-12 body1-bold color-body-tint d-flex aligne-items-center"
        style={style}
      >
        <span className="my-auto mr-3 w-6 h-6 font-size-12 tg-icon tg-icon-check-2-line" />
        {t('login_benefit_2')}
      </div>
      <div
        className="mt-4 p-4 radius-12 body1-bold color-body-tint d-flex aligne-items-center"
        style={style}
      >
        <span className="my-auto mr-3 w-6 h-6 font-size-12 tg-icon tg-icon-check-2-line" />
        {t('login_benefit_3')}
      </div>
      <div
        className="mt-4 p-4 radius-12 body1-bold color-body-tint d-flex aligne-items-center"
        style={style}
      >
        <span className="my-auto mr-3 w-6 h-6 font-size-12 tg-icon tg-icon-check-2-line" />
        {t('login_benefit_4')}
      </div>
    </div>
  );
}