import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import MobileStepper from 'o-ui/MobileStepper';

import {fileUploader} from '../../../../api/fileUploader';
import {IMCAttachment, IMCWebWidgetConfig, MCWebWidgetConfig, entities} from '../../../../api/proto';
import {WEB_WIDGET_COLORS, WEB_WIDGET_FIRST_MESSAGES} from '../../../../constants';
import Channel from '../../../../stores/Channel';
import {generateWebWidgetScriptUrl} from '../../../../stores/Channel/WebWidgetConfig';
import useStore from '../../../../stores/useStore';
import compressImage from '../../../../utils/file/compressImages';
import {FileData} from '../../../../utils/file/fileReaders';
import {formatAttachment} from '../../../../utils/file/formatAttachment';
import ChannelCreationStepType from '../ChannelCreationStepType';
import WebWidgetCreationModalHeader from './WebWidgetCreationModalHeader';
import WebWidgetCreationResult from './WebWidgetCreationResult';
import WebWidgetPreview from './WebWidgetPreview';
import WebWidgetSettingsForm, {IWebWidgetSettingsFormRef, WebWidgetSettingStep} from './WebWidgetSettingsForm';

const TOTAL_STEPS = 4;

interface IProps {
  className?: string;
  modalHeader?: boolean;
  onClose?: () => void;
  isOnboarding?: boolean;
  onChangeOnboardingStep?: (step: ChannelCreationStepType) => void;
  type?: entities.OzekonChannelType | null;
  onChangeType?: (type: entities.OzekonChannelType) => void;
}

export const WebWidgetCreationForm = observer((props: IProps) => {
  const {t} = useTranslation();
  const {notifications, channels} = useStore();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [activeStep, setActiveStep] = React.useState<WebWidgetSettingStep>(WebWidgetSettingStep.BASE_SETTING);
  const [newChannel] = React.useState<Channel | null>(null);

  const [scriptUrl, setScriptUrl] = React.useState<string | null>(null);
  const [apiCode, setAPICode] = React.useState<string | null>(null);

  const formRef = React.useRef<IWebWidgetSettingsFormRef>(null);
  const formWrapRef = React.useRef<HTMLDivElement>(null);
  const formSettingsWrapRef = React.useRef<HTMLDivElement>(null);

  const [color, setColor] = React.useState<string>(WEB_WIDGET_COLORS[0]);
  const [companyName, setCompanyName] = React.useState<string>(t('Company name'));
  const [firstMessage, setFirstMessage] = React.useState<string>(WEB_WIDGET_FIRST_MESSAGES[0]);
  const [avatarFile, setAvatarFile] = React.useState<FileData | null>(null);
  const [helloSnippets, setHelloSnippets] = React.useState<MCWebWidgetConfig.IHelloSnippet[]>([]);

  const [chatButtonSize, setChatButtonSize] = React.useState<string>('');
  const [chatButtonPosition, setChatButtonPosition] = React.useState<React.CSSProperties>({});

  const handleBack = () => {
    if (activeStep === WebWidgetSettingStep.SNIPPETS_SETTING) {
      setActiveStep(WebWidgetSettingStep.FIRST_MESSAGE_SETTING);
    } else {
      setActiveStep(WebWidgetSettingStep.BASE_SETTING);
    }
    scrollToTop();
  };

  const scrollToTop = () => {
    if (formSettingsWrapRef.current) {
      formSettingsWrapRef.current.scrollTop = 0;
    }
    if (formWrapRef.current) {
      formWrapRef.current.scrollTop = 0;
    }
  };

  const handleNext = () => {
    if (formRef.current?.submitForm) {
      formRef.current.submitForm();
      scrollToTop();
    }
  };

  const handleSubmit = async (name: string, config: IMCWebWidgetConfig, fileLogo?: FileData | null) => {
    if (activeStep === WebWidgetSettingStep.BASE_SETTING) {
      setActiveStep(WebWidgetSettingStep.FIRST_MESSAGE_SETTING);
      scrollToTop();
      return;
    }
    if (activeStep === WebWidgetSettingStep.FIRST_MESSAGE_SETTING) {
      setActiveStep(WebWidgetSettingStep.SNIPPETS_SETTING);
      scrollToTop();
      return;
    }

    setLoading(true);

    let displayAvatar: IMCAttachment | null = null;

    if (fileLogo) {
      displayAvatar = formatAttachment(fileLogo);
      fileLogo.attachment = displayAvatar;

      const dataFile = await compressImage(fileLogo);

      const {res, error} = await fileUploader.upload({dataFile});

      if (error) {
        displayAvatar = null;
      }

      if (res && displayAvatar?.source) {
        displayAvatar.source.reference = res.attachmentID;
      }
    }

    const {error, res} = await channels.webWidget.createWidget({
      shownFields: {name},
      config: {
        ...config,
        displayAvatar,
      },
    });

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      const APICode = res.webWidgetFlow?.APICode;

      setAPICode(APICode || '');
      setScriptUrl(generateWebWidgetScriptUrl(APICode));
      setActiveStep(WebWidgetSettingStep.DONE);

      props.onChangeOnboardingStep?.(ChannelCreationStepType.SUCCESS);
    }

    setLoading(false);
  };

  const handleCompanyNameChange = (value: string) => {
    setCompanyName(value);
  };

  const handleColorChange = (value: string) => {
    setColor(value);
  };

  const handleFirstMessageChange = (value: string) => {
    setFirstMessage(value);
  };

  const handleAvatarFileChange = (file: FileData) => {
    setAvatarFile(file);
  };

  const handleHelloSnippets = (snippets: MCWebWidgetConfig.IHelloSnippet[]) => {
    setHelloSnippets(snippets);
  };

  const handleChatButtonSizeChange = (value: string) => {
    setChatButtonSize(value);
  };

  const handleChatButtonPositionChange = (cssProps: React.CSSProperties) => {
    setChatButtonPosition(cssProps);
  };

  return (
    <div
      className={cn(
        'channel-dialog-form',
        {
          'isOnboarding': props.isOnboarding,
          'with-header': props.modalHeader,
        },
        props.className,
      )}
    >
      {props.modalHeader ? <WebWidgetCreationModalHeader onClose={props.onClose} /> : null}

      {apiCode ? (
        <WebWidgetCreationResult
          className={cn('p-6', props.isOnboarding ? '' : 'w-860 in-modal')}
          style={props.isOnboarding ? {maxWidth: 580} : {}}
          scriptUrl={scriptUrl}
          apiCode={apiCode}
          isOnboarding={props.isOnboarding}
        />
      ) : (
        <div
          ref={formWrapRef}
          className={cn(
            'web-widget-creation-form__main-row d-flex flex-row',
            props.isOnboarding ? '' : 'w-860 in-modal',
          )}
        >
          <div
            ref={formSettingsWrapRef}
            className="web-widget-creation-form__main-row__left w-100 border-primary-right p-6"
          >
            <WebWidgetSettingsForm
              ref={formRef}
              step={activeStep}
              colorOptions={WEB_WIDGET_COLORS}
              welcomeMessageOptions={WEB_WIDGET_FIRST_MESSAGES}
              newChannel={newChannel}
              onSubmit={handleSubmit}
              onChangeCompanyName={handleCompanyNameChange}
              onChangeColor={handleColorChange}
              onChangeFirstMessage={handleFirstMessageChange}
              onChangeLogo={handleAvatarFileChange}
              onChangeSnippets={handleHelloSnippets}
              type={props.type}
              isOnboarding={props.isOnboarding}
              onChangeType={props.onChangeType}
              onChangeChatButtonSize={handleChatButtonSizeChange}
              onChangeChatButtonPosition={handleChatButtonPositionChange}
              onRest={scrollToTop}
            />
          </div>
          <div className="web-widget-creation-form__main-row__right w-100 p-0 position-relative">
            <WebWidgetPreview
              color={color}
              companyName={companyName}
              welcomeMessage={firstMessage}
              avatarUrl={avatarFile?.objectUrl}
              helloSnippets={helloSnippets.filter((s) => s.enabled)}
              chatButtonSize={chatButtonSize}
              chatButtonPosition={chatButtonPosition}
            />
          </div>
        </div>
      )}
      {apiCode && props.isOnboarding ? null : (
        <DialogActions>
          {apiCode ? (
            <Button className="ml-auto" variant="contained" color="primary" size="large" onClick={props.onClose}>
              {t('settings_web_widget_manage_form_close_btn')}
            </Button>
          ) : (
            <>
              {!props.isOnboarding ? (
                <div className="w-25 web-widget-actions-cancel">
                  <Button variant="contained" color="secondary" size="large" onClick={props.onClose}>
                    {t('settings_web_widget_manage_form_cancel_btn')}
                  </Button>
                </div>
              ) : null}

              <MobileStepper
                className={cn('mobile-stepper mx-auto', {
                  [`mobile-stepper--selected-${activeStep}`]: true,
                  'justify-content-center': !props.isOnboarding,
                })}
                variant="dots"
                steps={TOTAL_STEPS}
                position="static"
                activeStep={activeStep}
                style={{maxWidth: 400, flexGrow: 1}}
                nextButton={null}
                backButton={null}
              />

              {activeStep !== WebWidgetSettingStep.BASE_SETTING ? (
                <div className={cn('text-right web-widget-actions-next', !props.isOnboarding && 'w-25')}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleBack}
                    disabled={loading}
                  >
                    {t('settings_web_widget_manage_form_back_btn')}
                  </Button>
                  <Button
                    className="ml-3"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleNext}
                    loading={loading}
                  >
                    {t('settings_web_widget_manage_form_next_btn')}
                  </Button>
                </div>
              ) : (
                <Button
                  className="ml-auto"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleNext}
                  loading={loading}
                >
                  {t('settings_web_widget_manage_form_next_btn')}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </div>
  );
});

export default WebWidgetCreationForm;
