import Long from 'long';

import EventEmitter from '../../api/EventEmitter';
import {api, MCMethod, MCMethodGetChat, MCMethodGetChatsPreview} from '../../api/proto';
import {equalArraysOfUint8Arrays, equalUint8Arrays, findLastIndex, uint8ArrayToBase64} from '../../utils/arrayUtils';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import IRawChatsStore from './IRawChatsStore';
import {
  getOffset,
  getSliceRange,
  mergeArraysByOffsetType,
  PAGE_SIZE,
  PagingOffset,
  PagingOffsetType,
  PagingPage,
} from './pagingUtils';
import {IRawChat} from './RawChat';
import RawChatsStoreEvent from './RawChatsStoreEvent';
import RawChatsStoreMode from './RawChatsStoreMode';
import isRaisingChat from './utils/isRaisingChat';
import {applyChatUpdates, mergeChatUpdates} from './utils/mergeChatsUpdates';

export class RawChatsBase extends EventEmitter implements IRawChatsStore {
  protected initializing_: boolean = false;
  protected isInit_: boolean = false;
  protected partialCollection_: boolean = false;
  protected rawChats_: IRawChat[] = [];
  protected chatsIdNumbersDict_: {[id: string]: boolean} = {};

  /* totalUnreadCount section */
  protected _totalUnreadCount: number = 0;

  public get totalUnreadCount(): number {
    return this._totalUnreadCount;
  }

  protected setTotalUnreadCount = (totalUnreadCount: number) => {
    console.debug(`%c----->RawChatsBase.setTotalUnreadCount`, 'color: pink', totalUnreadCount);
    this._totalUnreadCount = totalUnreadCount;
    this.emit(RawChatsStoreEvent.UPDATE_TOTAL_UNREAD_COUNT, this, totalUnreadCount);
  };

  protected incTotalUnreadCount = (count: number) => {
    console.debug(`%c----->RawChatsBase.incTotalUnreadCount`, 'color: pink', count);
    this.setTotalUnreadCount(this._totalUnreadCount + count);
  };

  protected decTotalUnreadCount = (count: number) => {
    console.debug(`%c----->RawChatsBase.decTotalUnreadCount`, 'color: pink', count);
    this.setTotalUnreadCount(this._totalUnreadCount - count);
  };
  /* end totalUnreadCount section */

  /* total section */
  protected _total: number = 0;

  public get total(): number {
    return this._total;
  }

  protected setTotal = (total: number) => {
    console.debug(`%c----->RawChatsBase.setTotal`, 'color: pink', total);
    this._total = total;
    this.emit(RawChatsStoreEvent.UPDATE_TOTAL, this, total);
  };

  protected incTotal = (count: number) => {
    console.debug(`%c----->RawChatsBase.incTotal`, 'color: pink', count);
    this.setTotal(this._total + count);
  };

  protected decTotal = (count: number) => {
    console.debug(`%c----->RawChatsBase.decTotal`, 'color: pink', count);
    this.setTotal(this._total - count);
  };
  /* end total section */

  protected has = (id?: Long | null): boolean => {
    return !!id && this.chatsIdNumbersDict_[id.toString()];
  };

  public clear = () => {
    console.debug(`%c----->RawChatsBase.clear`, 'color: red', this.storeKey);
    this.isInit_ = false;
    this.partialCollection_ = false;
    this.rawChats_ = [];
    this.chatsIdNumbersDict_ = {};
    this.emit(RawChatsStoreEvent.CLEAR, this);
  };

  constructor(
    protected workspace: WorkspaceStore,
    public channelIDs: Uint8Array[],
    public storeKey: string,
    protected mode?: RawChatsStoreMode,
    protected status?: MCMethodGetChatsPreview.MCChatStatus,
  ) {
    super();
  }

  public get unreadOnly(): boolean {
    return !!this.mode &&
      [
        RawChatsStoreMode.UNREAD_ONLY,
        RawChatsStoreMode.ASSIGNED_TO_ME_UNREAD_ONLY,
        RawChatsStoreMode.ASSIGNED_TO_OTHER_UNREAD_ONLY,
      ].includes(this.mode);
  }

  protected includeChannel = (channelID?: Uint8Array | null): boolean => {
    if (!channelID) {
      return false;
    }
    return this.channelIDs.some((id) => equalUint8Arrays(id, channelID));
  };

  protected equalsChannel = (channelID?: Uint8Array | null): boolean => {
    if (!channelID) {
      return false;
    }
    return equalArraysOfUint8Arrays(this.channelIDs, [channelID]);
  };

  public equalsChannels = (channelIDs?: Uint8Array[] | null): boolean => {
    if (!channelIDs) {
      return false;
    }
    return equalArraysOfUint8Arrays(this.channelIDs, channelIDs);
  };

  public find = (chatID?: Long | null): IRawChat | null => {
    return this.rawChats_.find((raw) => chatID && raw.id?.equals(chatID)) || null;
  };

  protected findIndex_ = (chatID?: Long | null) => {
    return chatID ? findLastIndex<IRawChat>(this.rawChats_, ({id}) => !!id?.equals(chatID)) : -1;
  };

  public init = async () => {
    if (this.isInit_ || this.initializing_) {
      return;
    }
    await this.load();
  };

  public load = async (chatID?: Long | null, prevPage?: boolean): Promise<PagingPage<IRawChat>> => {
    const offsetRange = getOffset(chatID, prevPage);

    const foundChats = this.getSlice_(chatID, offsetRange.offsetBefore, offsetRange.offsetAfter);
    console.debug(
      `%c----->RawChatsBase.loadChats foundChats`,
      'color: green',
      foundChats,
      ' prevPage=',
      prevPage,
      this.storeKey,
    );

    if (foundChats) {
      this.emit(RawChatsStoreEvent.LOAD_CHATS, foundChats, prevPage);
      return {
        result: foundChats,
        offsetType: offsetRange.offsetType,
      };
    }

    if (!this.channelIDs.length) {
      return {
        result: [],
        offsetType: offsetRange.offsetType,
      };
    }

    this.initializing_ = true;
    const {res} = await this.getChatsPreview_(chatID, offsetRange.offsetBefore, offsetRange.offsetAfter);
    const chats = res?.methodResponse?.getChats?.chats || [];

    this.setTotalUnreadCount(res?.methodResponse?.getChats?.totalUnreadCount?.toNumber() || 0);
    this.setTotal(res?.methodResponse?.getChats?.total?.toNumber() || 0);

    this.initializing_ = false;
    this.isInit_ = true;
    if (offsetRange.offsetType === PagingOffsetType.MIDDLE_PAGE) {
      this.partialCollection_ = true;
      console.debug(`%c----->RawChatsBase partial mode enabled!`, 'color: red', this.storeKey);
    } else if (offsetRange.offsetType === PagingOffsetType.NEXT_PAGE && chats.length < PAGE_SIZE) {
      this.partialCollection_ = false;
      console.debug(`%c----->RawChatsBase partial mode disabled!`, 'color: red', this.storeKey);
    }

    const chat = chats.find((chat) => chatID && chat.id?.equals(chatID));
    console.debug(
      `%c----->RawChatsBase->getChatsPreview_ result (chat found - ${!!chat})`,
      chat ? 'color: green' : 'color: red',
      chats.map((c) => c.id?.toNumber()),
      chat,
      this.storeKey,
    );

    const processedChats = this.processChats_(chats, offsetRange);

    return {
      result: processedChats,
      offsetType: offsetRange.offsetType,
    };
  };

  private getChatsPreview_ = async (chatID?: Long | null, offsetBefore?: Long, offsetAfter?: Long) => {
    return await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChatsPreview: new MCMethodGetChatsPreview({
              channelIDs: this.channelIDs,
              fetch: true,
              offsetID: chatID,
              offsetBefore,
              offsetAfter,
              unreadOnly: this.unreadOnly,
              assignedToMeOnly:
                this.mode &&
                [RawChatsStoreMode.ASSIGNED_TO_ME_ONLY, RawChatsStoreMode.ASSIGNED_TO_ME_UNREAD_ONLY].includes(
                  this.mode,
                ),
              assignedToOthersOnly:
                this.mode &&
                [RawChatsStoreMode.ASSIGNED_TO_OTHER_ONLY, RawChatsStoreMode.ASSIGNED_TO_OTHER_UNREAD_ONLY].includes(
                  this.mode,
                ),
              status: this.status,
            }),
          }),
        }),
      },
      'channelsResponse',
    );
  };

  protected getSlice_ = (chatID?: Long | null, offsetBefore?: Long, offsetAfter?: Long): IRawChat[] | null => {
    console.debug(
      `%c----->RawChatsBase->getSlice_ chatID`,
      'color: green',
      chatID?.toNumber(),
      offsetBefore?.toNumber(),
      offsetAfter?.toNumber(),
      this.storeKey,
    );
    if (!this.rawChats_.length) {
      return null;
    }

    if (!chatID) {
      return this.rawChats_.slice(0, offsetBefore?.toNumber() || PAGE_SIZE);
    }

    const index = this.findIndex_(chatID);
    if (index === -1) {
      return null;
    }

    const range = getSliceRange(index, offsetBefore, offsetAfter);
    console.debug(`%c----->RawChatsBase->getSlice_ range`, 'color: blue', range, this.storeKey);

    const foundChats: IRawChat[] | null = this.rawChats_.slice(range.start, range.end);
    console.debug(
      `%c----->RawChatsBase->getSlice_ result`,
      'color: red',
      foundChats.map((c) => c.id?.toNumber()),
      this.storeKey,
    );

    return foundChats?.length ? foundChats : null;
  };

  protected updateChat_(rawChat: IRawChat): void {
    const idx = this.findIndex_(rawChat.id);
    console.debug(
      `%c----->RawChatsBase->updateChat_ partial=${this.partialCollection_} ${rawChat?.id?.toString()} idx=${idx} %c${this.storeKey}`,
      'color: gray',
      'color: #afb0b6',
      this.chatsIdNumbersDict_,
      rawChat,
    );

    if (idx >= 0) {
      const currentChat = this.rawChats_[idx];

      const updatedChat = mergeChatUpdates(currentChat, rawChat, true);
      const raising = isRaisingChat(currentChat, updatedChat);

      if (raising && !this.partialCollection_) {
        console.debug(
          `%c----->RawChatsBase->updateChat_ raise chat ${currentChat?.id?.toString()} %c${this.storeKey}`,
          'color: red',
          'color: #afb0b6',
          this.storeKey,
          updatedChat,
        );
        this.rawChats_.splice(idx, 1);
        this.rawChats_.unshift(updatedChat);
      } else if (raising && this.partialCollection_) {
        this.removeChat_(rawChat, idx);
      } else {
        console.debug(
          `%c----->RawChatsBase->updateChat_ replace chat ${currentChat?.id?.toString()} %c${this.storeKey}`,
          'color: red',
          'color: #afb0b6',
          this.storeKey,
          updatedChat,
        );
        this.rawChats_[idx] = updatedChat;
      }

      this.emit(RawChatsStoreEvent.UPDATE_CHAT, this, updatedChat);
    } else {
      this.addNewChat_(rawChat);
    }
  }

  protected updateChatPartially_(updates: IRawChat, rawChat: IRawChat, readUpdate?: boolean): void {
    const idx = this.findIndex_(updates.id);
    const debugFlags = `partial=${this.partialCollection_} readUpdate=${readUpdate} ${updates?.id?.toString()} idx=${idx}`;
    console.debug(
      `%c----->RawChatsBase->updateChatPartially_ ${debugFlags} %c${this.storeKey}`,
      'color: gray',
      'color: #afb0b6',
      this.chatsIdNumbersDict_,
      updates,
      rawChat,
    );

    if (updates.lastMessage?.serviceMessage && !updates.messagesExcludedFromUnreadRange) {
      console.debug(
        `%c----->RawChatsBase->updateChatPartially_ ${debugFlags} %c${this.storeKey}`,
        'color: red',
        'color: #afb0b6',
        this.chatsIdNumbersDict_,
        updates,
        rawChat,
      );
    }

    if (idx >= 0) {
      const currentChat = this.rawChats_[idx];

      const updatedChat = {...currentChat, ...updates};
      const raising = isRaisingChat(currentChat, updatedChat);

      if (raising && !this.partialCollection_) {
        console.debug(
          `%c----->RawChatsBase->updateChatPartially_ raise chat ${currentChat?.id?.toString()} %c${this.storeKey}`,
          'color: red',
          'color: #afb0b6',
          this.storeKey,
          updatedChat,
        );
        this.rawChats_.splice(idx, 1);
        this.rawChats_.unshift(updatedChat);
      } else if (raising && this.partialCollection_) {
        this.removeChat_(updates, idx);
      } else {
        this.rawChats_[idx] = updatedChat;
        console.debug(
          `%c----->RawChatsBase->updateChatPartially_ replace chat ${currentChat?.id?.toString()} %c${this.storeKey}`,
          'color: orange',
          'color: #afb0b6',
          this.storeKey,
          updatedChat,
        );
      }

      this.emit(RawChatsStoreEvent.UPDATE_CHAT, this, updatedChat);
    } else if (!readUpdate) {
      const updatedChat = applyChatUpdates(rawChat, updates);
      this.addNewChat_(updatedChat);
    }
  }

  protected removeChat_ = (rawChat: IRawChat, idx?: number) => {
    console.debug(
      `%c----->RawChatsBase.removeChat_ ${rawChat?.id?.toString()}! %c${this.storeKey} idx=${idx}`,
      'color: red',
      'color: #afb0b6',
    );
    const index = idx || this.findIndex_(rawChat.id);
    console.debug(
      `%c----->RawChatsBase.removeChat_ ${rawChat?.id?.toString()}! %c${this.storeKey} index=${index}`,
      'color: red',
      'color: #afb0b6',
      this.rawChats_.map(({id}) => id?.toString()),
    );
    if (index < 0) {
      return;
    }

    this.rawChats_.splice(index, 1);
    delete this.chatsIdNumbersDict_[rawChat.id?.toString() || ''];
    console.debug(
      `%c----->RawChatsBase.removeChat_ ${rawChat?.id?.toString()}! %c${this.storeKey} done`,
      'color: red',
      'color: #afb0b6',
      this.rawChats_.map(({id}) => id?.toString()),
      this.chatsIdNumbersDict_,
    );

    this.emit(RawChatsStoreEvent.REMOVE_CHAT, this, rawChat);
  };

  protected filterChats_ = (fn: (rawChat: IRawChat) => boolean) => {
    this.rawChats_ = this.rawChats_.filter((rawChat: IRawChat) => {
      const leave = fn(rawChat);
      if (!leave) {
        delete this.chatsIdNumbersDict_[rawChat.id?.toString() || ''];
        this.emit(RawChatsStoreEvent.REMOVE_CHAT, this, rawChat);
      }
      return leave;
    });
  };

  protected addNewChat_(newRawChat: IRawChat): void {
    if (this.partialCollection_) {
      console.debug(
        `%c----->RawChatsBase->addNewChat_ ${newRawChat?.id?.toString()} partialCollection mode! %c${this.storeKey
        }`,
        'color: red',
        'color: #afb0b6',
        newRawChat,
        this.chatsIdNumbersDict_,
      );
      return;
    }

    if (newRawChat.id) {
      console.debug(
        `%c----->RawChatsBase->addNewChat_ ${newRawChat?.id?.toString()} %c${this.storeKey}`,
        'color: green',
        'color: #afb0b6',
        newRawChat,
      );

      if (this.has(newRawChat.id) && this.findIndex_(newRawChat.id) >= 0) {
        this.updateChat_(newRawChat);
        return;
      }

      this.chatsIdNumbersDict_[newRawChat.id.toString()] = true;
      this.rawChats_.unshift(newRawChat);

      this.emit(RawChatsStoreEvent.ADD_NEW_CHAT, this, newRawChat);
    }
  }

  private processChats_ = (rawChats: IRawChat[], pagingOffset: PagingOffset): IRawChat[] => {
    if (!rawChats.length) {
      return [];
    }

    if (rawChats.length < PAGE_SIZE) {
      this.partialCollection_ = false;
    }

    const newChats: IRawChat[] = [];
    rawChats.forEach((raw) => {
      if (!raw.id) {
        console.debug(`%c----->RawChatsBase->addChats_ null id!`, 'color: red', raw, this.storeKey);
      } else if (raw.id && !this.has(raw.id)) {
        newChats.push(raw);
        this.chatsIdNumbersDict_[raw.id.toString()] = true;
      } else {
        console.debug(
          `%c----->RawChatsBase->addChats_ dup entry!`,
          'color: red',
          raw.id?.toString(),
          this.storeKey,
        );
      }
    });

    if (!newChats.length) {
      return [];
    }

    this.rawChats_ = mergeArraysByOffsetType<IRawChat>(this.rawChats_, newChats, pagingOffset.offsetType);
    console.debug(`%c----->RawChatsBase->processChats_ ${this.storeKey}`, 'color: gray');

    this.emit(RawChatsStoreEvent.LOAD_CHATS, this, newChats, pagingOffset.offsetType);

    return newChats;
  };

  private getChatPromises_ = new Map<string, Promise<IRawChat | null>>();

  protected getChatPromiseKey_ = (chatID?: Long | null, channelID?: Uint8Array | null): string => {
    return `${channelID?.toString() || ''}_${chatID?.toString() || ''}`;
  };

  protected getChatPromise_ = (chatID?: Long | null, channelID?: Uint8Array | null) => {
    return this.getChatPromises_.get(this.getChatPromiseKey_(chatID, channelID)) || null;
  };

  public get = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    let promise = this.getChatPromise_(chatID, channelID);
    console.debug(
      `%c----->RawChatsBase.getChat_ ${chatID?.toString()} %c ${uint8ArrayToBase64(channelID)}`,
      'color: orange',
      'color: #afb0b6',
    );

    if (!promise) {
      promise = this.getChatRequest_(chatID, channelID)
        .catch((err) => {
          console.debug('get chat Error: ', err);
          throw err;
        })
        .finally(() => {
          this.getChatPromises_.delete(this.getChatPromiseKey_(chatID, channelID));
        });

      this.getChatPromises_.set(this.getChatPromiseKey_(chatID, channelID), promise);
    }

    return promise;
  };

  private getChatRequest_ = async (chatID?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    console.debug(
      `%c----->RawChatsBase getChatRequest_ ${chatID?.toString()} %c${this.storeKey}`,
      'color: orange',
      'color: #afb0b6',
    );
    const {res} = await this.workspace.request<api.ChannelsResponse>(
      {
        channelsRequest: new api.ChannelsRequest({
          workspaceId: this.workspace.id,
          action: new MCMethod({
            getChat: new MCMethodGetChat({
              chatID,
              channelID,
            }),
          }),
        }),
      },
      'channelsResponse',
    );

    const chat = res?.methodResponse?.getChat?.chat;
    return chat ? {...chat, isExtended: true} : null;
  };
}

export default RawChatsBase;
