import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import {entities} from '../../../api/proto';
import BillingHistoryItem from '../../../stores/Billing/BillingHistoryItem';
import downloadFile from '../../../utils/downloadFile';
import {BILLING_DATE_TIME_FORMAT, formatDateTime} from '../../../utils/format';
import formatMoney from '../../../utils/format/formatMoney';
import PaymentMethodIcon from './PaymentMethodIcon';

interface BillingHistoryTableProps {
  className?: string;
  item: BillingHistoryItem;
}

export const BillingHistoryTableRow: React.FC<BillingHistoryTableProps> = observer(({
  className,
  item,
}) => {
  const {t} = useTranslation();

  const getInvoiceStatusTitle = (status?: (entities.PaymentStatus | null)) => {
    switch (status) {
      case entities.PaymentStatus.PAID:
        return t('settings_billing_history_payment_paid');
      case entities.PaymentStatus.FAILED:
        return t('settings_billing_history_payment_failed');
      case entities.PaymentStatus.PENDING:
        return t('settings_billing_history_payment_pending');
    }
    return '';
  };

  const renderInvoiceStatus = (status?: (entities.PaymentStatus | null)) => {
    return (
      <div className={cn("body2-regular mt-1", {
        'color-success-500': status === entities.PaymentStatus.PAID,
        'color-error-500': status === entities.PaymentStatus.FAILED,
        'color-warning-500': status !== entities.PaymentStatus.PAID &&
          status !== entities.PaymentStatus.FAILED,
      })}
      >
        {getInvoiceStatusTitle(status)}
      </div>
    );
  };

  const handleDownloadClick = () => {
    if (item.invoicePDF) {
      downloadFile({url: item.invoicePDF});
    }
  };

  return (
    <div
      className={cn(
        'billing-history-table__row',
        'px-5 pb-5 mt-5',
        'd-flex flex-row',
        'border-primary radius-8',
        className,
      )}
      data-stripe-session-id={item.stripeSessionID}
      data-tariff-id={item.tariffID?.toString()}
    >
      <div className="d-flex flex-column flex-wrap">

        <div className="d-flex flex-sm-row flex-column flex-wrap">
          <div className="d-flex flex-column mt-5 mr-6" style={{minWidth: 130}}>
            <div className="label-3 text-uppercase">{t('settings_billing_history_col_plan')}</div>
            <div className="body2-regular mt-1">{item.tariff?.name || '-'}</div>
          </div>

          <div className="d-flex flex-column mt-5 mr-6">
            <div className="label-3 text-uppercase">{t('settings_billing_history_col_invoice')}</div>
            <div className="body2-regular mt-1">{item.invoiceID || '-'}</div>
          </div>

          <div className="d-flex flex-column mt-5 mr-6">
            <div className="label-3 text-uppercase">{t('settings_billing_history_col_payment_method')}</div>
            <div className="body2-regular mt-1">
              {item.cardBrand ? (<PaymentMethodIcon type={item.cardBrand} />) : '-'}
            </div>
          </div>

          <div className="d-flex flex-column mt-5 mr-6">
            <div className="label-3 text-uppercase">{t('settings_billing_history_col_status')}</div>
            {renderInvoiceStatus(item.paymentStatus)}
          </div>

          <div className="d-flex flex-column mt-5 mr-6">
            <div className="label-3 text-uppercase">{t('settings_billing_history_col_total')}</div>
            <div className="body2-regular mt-1">{formatMoney({
              value: item.amount,
              currency: item.currency,
            })}</div>
          </div>
        </div>

        <div className="body3-regular color-body-tertiary mt-5 mt-sm-1">
          {formatDateTime(item.billingDate, true, BILLING_DATE_TIME_FORMAT)}
        </div>

      </div>

      {item.invoicePDF ? (
        <div className="d-flex flex-column mt-5 ml-auto">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleDownloadClick}
          >
            {t('settings_billing_history_button_download')}
          </Button>
        </div>
      ) : null}
    </div>
  );
});

export default BillingHistoryTableRow;