import cn from 'classnames';
import React from 'react';

interface ButtonSizeSelectorProps {
  className?: string;
  value?: string | null;
  items?: {caption: string; value: string}[] | null;
  onChange?: (value: string) => void;
}

export const ButtonSizeSelector: React.FC<ButtonSizeSelectorProps> = (props) => {
  const {value} = props;

  const handleChange = (val: string) => {
    props.onChange && props.onChange(val);
  };

  return (
    <div className={cn('d-flex flex-wrap', props.className)}>
      {props.items?.map((item, i) => (
        <div
          key={i}
          className={cn('d-flex align-items-center radius-10 cursor-pointer px-3 py-2 mb-1', {
            'border-brand-tint3': item.value !== value,
            'color-brand-primary': item.value !== value,
            'bg-brand-tint': item.value !== value,
            'bg-brand-primary': item.value === value,
            'color-body-tint': item.value === value,
            'mr-1': i + 1 !== props.items?.length,
          })}
          onClick={() => handleChange(item.value)}
        >
          {item.caption || item.value}
          {item.value === value ? <span className="tg-icon tg-icon-check-3-line font-size-8 ml-2" /> : null}
        </div>
      ))}
    </div>
  );
};

export default ButtonSizeSelector;
