import React from 'react';
import {useTranslation} from 'react-i18next';

import FormControlLabel from 'o-ui/FormControlLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Radio from 'o-ui/Radio';
import RadioGroup from 'o-ui/RadioGroup';
import {MAX_FORM_FIELD_LENGTH} from '../../../../constants';


interface WebWidgetFirstMessageProps {
  className?: string;
  value?: string | null;
  options?: string[] | null;
  onChange?: (template: string) => void;
}

export const WebWidgetFirstMessage = ({
  className,
  value,
  options,
  onChange,
}: WebWidgetFirstMessageProps) => {
  const {t} = useTranslation();
  const [customMessageMode, setCustomMessageMode] = React.useState<boolean>(
    !options?.some((o) => value === o),
  );
  const [customMessage, setCustomMessage] = React.useState<string>(value || '');

  const handleOptionClick = React.useCallback((template: string) => {
    setCustomMessageMode(false);
    onChange?.(template);
  }, [
    onChange,
  ]);

  const handleCustomMessageChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomMessage(e.target.value);
    onChange?.(e.target.value);
  }, [
    onChange,
  ]);

  const handleCustomClick = React.useCallback(() => {
    setCustomMessageMode(true);
    onChange?.(customMessage);
  }, [
    onChange,
    customMessage,
  ]);

  return (
    <div className={className}>
      <RadioGroup value={customMessageMode ? 'custom' : value}>
        {options?.map((template) => (
          <FormControlLabel
            key={template}
            value={template}
            control={<Radio />}
            label={template}
            onClick={() => handleOptionClick(template)}
          />
        ))}
        <FormControlLabel
          value="custom"
          control={<Radio />}
          label={t('settings_web_widget_manage_form_custom_message_label') as string}
          onClick={handleCustomClick}
        />
        {customMessageMode ? (
          <OutlinedTextInput
            className="w-100 mb-3"
            value={customMessage}
            onChange={handleCustomMessageChange}
            maxLength={MAX_FORM_FIELD_LENGTH}
          />
        ) : null}
      </RadioGroup>
    </div>
  );
};

export default WebWidgetFirstMessage;
