import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import IconButton from 'o-ui/Button/IconButton';
import {Emoji} from 'o-ui/EmojiPicker/EmojiPicker';
import useDebouncedCallback from 'o-ui/hooks/useDebouncedCallback';

import {IMCTextEntities} from '../../../../api/proto';
import {QuillTextInput} from '../../../../components/QuillTextInput';
import QuillEditor from '../../../../components/QuillTextInput/QuillEditor';
import Chat from '../../../../stores/Chat';
import Message from '../../../../stores/Message';
import Peer from '../../../../stores/Peer';
import ReplyBar from '../ReplyBar';
import ChatEmojiButton from './ChatEmojiButton';
import ChatUploadMenu from './ChatUploadMenu';
// import ChatBotMenu from './ChatBotMenu';
import useCompactMode from '../../../../hooks/useCompactMode';
import useCanSendMessages from '../../../../stores/Chat/utils/useCanSendMessages';
import convertTextEntitiesToDelta from '../../../../stores/EditorStore/utils/convertTextEntitiesToDelta';
import {Snippet} from '../../../../stores/Snippets';
import {randomUint8Array} from '../../../../utils/arrayUtils';
import PeersList from '../PeersList';
import SnippetsBar, {SnippetsBarRef} from '../SnippetsBar/SnippetsBar';
import ChatSnippetButton from './ChatSnippetButton';


interface ChatInputProps {
  chat: Chat;
  className?: string | null;
  editingMessage?: Message | null;
  replyMessage?: Message | null;
  forwardMessages?: Message[] | null;
  onChangeLines?: (countLines: number) => void;
  onSendMessage: (message: string, replyMessage?: Message | null, textEntities?: IMCTextEntities[] | null) => void;
}

export const ChatInput = observer((props: ChatInputProps) => {
  const {t} = useTranslation();
  const [textInputIsEmpty, setTextInputIsEmpty] = React.useState(true);
  const currentMentionName = React.useRef<string>('');
  const snippetsBarRef = React.useRef<SnippetsBarRef>(null);
  const [currentMentionPeers, setCurrentMentionPeers] = React.useState<Peer[]>([]);
  const compactMode = useCompactMode();

  const canSendMessages = useCanSendMessages(props.chat);

  const measuredRef = React.useCallback(
    (node: QuillEditor | null) => {
      if (node) {
        props.chat.store?.editor?.setEditorRef(node);
      }
    },
    [props],
  );

  const [peersBarIsOpen, setPeersBarIsOpen] = React.useState<boolean>(false);
  const [snippetsBarIsOpen, setSnippetsBarIsOpen] = React.useState<boolean>(false);
  const [snippetShortcutMode, setSnippetShortcutMode] = React.useState<boolean>(false);

  const [countLines, setCountLines] = React.useState(0);

  const searchPeers = useDebouncedCallback(async (query: string) => {
    const peers = await props.chat.store?.peers?.search(query);
    if (peers && currentMentionName.current === query) {
      setCurrentMentionPeers(peers);
    }
  }, 400);

  const handleChange = (newHtml: string, newCountLines: number) => {
    const newTrimmedTextMessage = props.chat.store?.editor?.text.trim();

    if (props.chat.store?.editor?.currentMentionText) {
      currentMentionName.current = props.chat.store?.editor?.currentMentionName;
      searchPeers(props.chat.store?.editor?.currentMentionName);
      setPeersBarIsOpen(true);
    } else if (props.chat.store?.editor?.currentShortcutText) {
      setSnippetsBarIsOpen(true);
      if (!snippetsBarIsOpen) {
        setSnippetShortcutMode(true);
      }
      if (snippetShortcutMode) {
        snippetsBarRef.current?.onChangeFilter(props.chat.store?.editor?.currentShortcutValue);
      }
    } else {
      currentMentionName.current = '';
      setCurrentMentionPeers([]);
      setPeersBarIsOpen(false);

      if (snippetShortcutMode) {
        closeSnippetBar();
      }
    }

    if (newTrimmedTextMessage) {
      setTextInputIsEmpty(false);
    } else {
      setTextInputIsEmpty(true);
    }

    if (newCountLines !== countLines) {
      setCountLines(newCountLines);
      props.onChangeLines?.(newCountLines);
    }
  };

  const handleSnippetButtonClick = () => {
    setSnippetShortcutMode(false);
    setSnippetsBarIsOpen((val) => {
      snippetsBarRef.current?.onChangeFilter('');
      return !val;
    });
  };

  const closeSnippetBar = () => {
    setSnippetsBarIsOpen(false);
    setSnippetShortcutMode(false);
    snippetsBarRef.current?.onChangeFilter('');
  };

  const handleMentionSelect = React.useCallback(
    (user: Peer) => {
      props.chat.store?.editor?.insertMention({
        user: user,
        mentionText: currentMentionName.current,
        cursorPosition: 0,
      });

      setPeersBarIsOpen(false);
    },
    [props.chat.store?.editor],
  );

  const handleAddSnippetToInput = React.useCallback(
    (snippet: Snippet) => {
      const staticDelta = convertTextEntitiesToDelta(snippet.text || '', snippet.entities || []);

      if (staticDelta) {
        props.chat.store?.editor?.quill?.setContents(staticDelta);
        props.chat.store?.editor?.quill?.setSelection(props.chat.store?.editor?.quill?.getLength(), 0);
      }

      closeSnippetBar();
    },
    [props.chat.store?.editor],
  );

  const addEmoji = (e: Emoji) => {
    const emoji = e.getNative();
    setTextInputIsEmpty(false);

    props.chat.store?.editor?.insertText(emoji);
  };

  const handleAddSticker = (stickerInfo: any) => {
    console.debug(`TODO: not implemented handler "handleAddSticker" args:`, stickerInfo);
  };

  const sendMessageTemplate = () => {
    const chunks = props.chat.store?.editor?.getTextChunks();

    props.chat.store?.editor?.reset();

    chunks?.forEach(({text, textEntities}) => {
      if (!textInputIsEmpty || props.forwardMessages) {
        props.onSendMessage(textInputIsEmpty ? '' : text, props.replyMessage, textEntities);
      }
    });
  };

  const sendMessageOnEnter = () => {
    sendMessageTemplate();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessageTemplate();
  };

  const handleCancelReply = () => {
    props.chat.store?.setReplyMessage(null);
  };

  // const handleCancelEditing = () => {
  //   props.chat.store?.setEditingMessage(null);
  // };

  // const handleCancelForward = () => {
  //   props.chat.store?.setForwardMessages(null);
  // };

  React.useEffect(() => {
    const listener = (e) => {
      if (e.ctrlKey && (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)) {
        e.preventDefault();
        e.stopPropagation();

        props.chat.store?.suggestionStore?.load({
          id: randomUint8Array(),
          options: [{text: props.chat.store?.editor?.quill?.getText().trim()}],
        });
      }
    };
    window.document.addEventListener('keydown', listener);

    return () => {
      window.document.removeEventListener('keydown', listener);
    };
  }, [
    props.chat.store?.suggestionStore,
    props.chat.store?.editor?.quill,
  ]);

  return (
    <div
      className={cn(
        'chat-input',
        {'compact-mode': compactMode},
      )}
    >
      <div
        className={cn('chat-input__peers-list collapse', {
          show: peersBarIsOpen,
        })}
      >
        <PeersList chat={props.chat} peers={currentMentionPeers} onSelect={handleMentionSelect} />
      </div>

      <div
        className={cn('chat-input__snippets-bar collapse', {
          show: canSendMessages && snippetsBarIsOpen,
        })}
      >
        <SnippetsBar ref={snippetsBarRef} shortcutMode={snippetShortcutMode} onAddToMessage={handleAddSnippetToInput} />
      </div>

      {props.replyMessage ? (
        <ReplyBar
          icon="reply-fill"
          message={props.replyMessage}
          onClick={props.chat.store?.navigateToMessage}
          onClose={handleCancelReply}
          textSelection={props.chat.store?.textSelection}
        />
      ) : null}

      <form
        className={cn(
          'chat-input__form',
          {'chat-input__form--compact-mode': compactMode},
          props.className,
        )}
        onSubmit={handleSubmit}
      >
        <QuillTextInput
          ref={measuredRef}
          className="chat-input__input"
          placeholder={t('chat_user_chat_enter_message_title')}
          defaultValue={props.chat?.store?.editor?.contents}
          onChange={handleChange}
          onEnter={sendMessageOnEnter}
        />
        {/* {props.chat.user?.isBot ? <ChatBotMenu chat={props.chat} /> : null} */}
        <ChatEmojiButton
          className="chat-input__actions-button chat-input__actions-button--emoji mr-3 ml-2 line-height-22"
          buttonClassName="chat-input__actions-button"
          chat={props.chat}
          onAddEmoji={addEmoji}
          onAddSticker={handleAddSticker}
          hideStickers={true}
        />
        <ChatUploadMenu
          className="chat-input__actions-button chat-input__actions-button--upload mr-3"
          chat={props.chat}
        />
        <ChatSnippetButton
          className="chat-input__actions-button--snippets"
          onClick={handleSnippetButtonClick}
          isOpen={snippetsBarIsOpen}
        />
        <IconButton
          className={cn(
            'chat-input__send-button btn-icon line-height-24 font-size-24 ml-auto mr-3',
            {invisible: !(!textInputIsEmpty || props.replyMessage || props.forwardMessages)},
          )}
          onClick={sendMessageOnEnter}
        >
          <i className="tg-icon tg-icon-send-fill color-brand-primary line-height-20 font-size-20 m-2" />
        </IconButton>
      </form>
    </div>
  );
});

export default ChatInput;