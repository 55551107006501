import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import ModalType from '../../../../stores/ModalType';
import {Snippet} from '../../../../stores/Snippets';
import {DEFAULT_SNIPPET_TYPE, SnippetType, filterSnippetsList} from '../../../../stores/Snippets/SnippetsStore';
import useStore from '../../../../stores/useStore';
import NoSnippetsItem from '../../../Settings/Snippets/NoSnippetsItem';
import SnippetsBarHeader from './SnippetsBarHeader';
import SnippetsBarItem from './SnippetsBarItem';

export interface SnippetsBarRef {
  onChangeFilter(value: string): void;
}

interface SnippetsBarProps {
  shortcutMode?: boolean;
  onAddToMessage(snippet: Snippet): void;
}

const SnippetsBar = observer(
  React.forwardRef<SnippetsBarRef, SnippetsBarProps>((props, ref) => {
    const {activeWorkspace, modals, userStore} = useStore();

    const [snippetType, setSnippetType] = React.useState<SnippetType>();
    const [snippetsList, setSnippetsList] = React.useState<Snippet[]>([]);

    const {personalSnippets, workspaceSnippets, getSnippetsStore} = activeWorkspace;

    const [filter, setFilter] = React.useState<string>('');

    React.useEffect(() => {
      let list: Snippet[] = [];

      if (snippetType) {
        list = getSnippetsStore(snippetType).list;
      } else {
        list = personalSnippets.list.concat(workspaceSnippets.list);
      }

      setSnippetsList(filterSnippetsList(list, filter, props.shortcutMode));
    }, [props.shortcutMode, getSnippetsStore, snippetType, personalSnippets.list, workspaceSnippets.list, filter]);

    const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(e.target.value);
    };

    const handleChangeTab = (e: React.ChangeEvent, snippetType: SnippetType) => {
      setSnippetType(snippetType);
      setFilter('');
    };

    const handleCreateSnippet = () => {
      setFilter('');
      modals.open(ModalType.CREATE_SNIPPET, {
        snippet: new Snippet({}, snippetType || DEFAULT_SNIPPET_TYPE),
      });
    };

    React.useImperativeHandle(ref, () => ({
      onChangeFilter: (value: string) => {
        setFilter(value);
      },
    }));

    const allowCreate = snippetType === SnippetType.Personal || userStore.isAdmin || userStore.isOwner;

    return (
      <div
        className={cn('snippets-bar', {
          shortcutMode: props.shortcutMode && snippetsList.length,
        })}
      >
        {props.shortcutMode && snippetsList.length ? null : (
          <SnippetsBarHeader
            selectedType={snippetType}
            onChangeTab={handleChangeTab}
            filter={filter}
            onSearch={handleChangeFilter}
          />
        )}

        {snippetsList.length ? (
          <div className="snippets-bar__list">
            {snippetsList.map((snippet) => (
              <SnippetsBarItem
                key={snippet.id.toString()}
                snippet={snippet}
                onClick={props.onAddToMessage}
              />
            ))}
          </div>
        ) : (
          <NoSnippetsItem
            snippetType={snippetType || DEFAULT_SNIPPET_TYPE}
            onCreateSnippet={handleCreateSnippet}
            isSearchQuery={!!filter}
            showCreateButton={allowCreate}
          />
        )}
      </div>
    );
  }),
);

export default SnippetsBar;
