import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';

import CookieConsent from '../../components/CookieConsent';
import Div100vh from '../../components/Div100vh';
import {useStore} from '../../stores/AppStore';
import NightModeSwitcher from '../Settings/NightModeSwitcher';
import AuthInfo from './AuthInfo';
import InviteMessage from './InviteMessage';
import LoginForm, {LoginFormNotification} from './LoginForm';

type LocationState = {
  notificationType?: LoginFormNotification;
  email?: string;
  from?: Location;
};

const LoginPage: React.FC = observer(() => {
  const location = useLocation();
  const state = location.state as LocationState;

  const {
    layOutStore,
    lightThemeChosen,
    activeWorkspace: {invites},
  } = useStore();
  const {t} = useTranslation();

  const invite = invites.registrationInvite;

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side relative">
        <div className="p-7 absolute top-0 right-0">
          <NightModeSwitcher checked={!lightThemeChosen} onChange={layOutStore.theme.toggleCurrentTheme} />
        </div>

        <div className="auth-page__right-side-form">
          <div className="auth-page__title">
            {invite?.email ? t('login_form_invite_by_email_title') : t('login_form_title')}
          </div>

          {invite ? <InviteMessage invite={invite} className="mb-6" /> : null}

          <LoginForm
            className="mb-auto"
            notificationType={state?.notificationType}
            email={state?.email}
            location={state?.from}
          />
        </div>
      </div>
      <CookieConsent />
    </Div100vh>
  );
});

export default LoginPage;
