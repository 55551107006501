import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';

import {MCWebWidgetConfig} from '../../../../api/proto';
import useAsyncLoading from '../../../../hooks/useAsyncLoading';
import useAttachment from '../../../../hooks/useAttachment';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import WebWidgetPreview from '../WebWidgetCreation/WebWidgetPreview';
import WebWidgetSnippetsList, {
  WebWidgetSnippet,
} from '../WebWidgetCreation/WebWidgetSnippetsList/WebWidgetSnippetsList';

interface WebWidgetSnippetSettingsProps {
  channel: Channel;
  onClose?: () => void;
}

export const WebWidgetSnippetSettings = observer(({channel, onClose}: WebWidgetSnippetSettingsProps) => {
  const {t} = useTranslation();
  const {notifications, channels} = useStore();

  const webWidgetConfig = channel.webWidgetConfig;
  const {src: avatarSrc} = useAttachment(webWidgetConfig?.displayAvatar, channel.id);

  const [helloSnippets, setHelloSnippets] = React.useState<MCWebWidgetConfig.IHelloSnippet[]>(
    webWidgetConfig?.helloSnippets || [],
  );
  const [formChanged, setFormChanged] = React.useState<boolean>(false);

  const [handleSubmit, loading] = useAsyncLoading(async () => {
    const newWebWidgetConfig = {...webWidgetConfig?.raw, helloSnippets};

    const {error, res} = await channels.updateChannel({
      channel,
      channelID: channel.id,
      shownFields: channel.shownFields,
      webWidgetConfig: newWebWidgetConfig,
    });

    if (error) {
      notifications.error(error.message);
    }

    if (res) {
      onClose?.();
    }
  });

  const handleChangeSnippets = (snippets: WebWidgetSnippet[]) => {
    setHelloSnippets(snippets);
    setFormChanged(true);
  };

  return (
    <>
      <div className="d-flex flex-row w-860 webwidget-settings">
        <div className="border-primary-right p-7 webwidget-settings__left">
          <WebWidgetSnippetsList
            className="pb-3"
            snippets={helloSnippets}
            onChange={handleChangeSnippets}
          />
        </div>
        <div className="webwidget-settings__right p-4">
          <WebWidgetPreview
            color={webWidgetConfig?.displayColor}
            companyName={webWidgetConfig?.displayCompanyName}
            welcomeMessage={webWidgetConfig?.displayWelcomeMessage}
            avatarUrl={avatarSrc}
            helloSnippets={helloSnippets.filter((s) => s.enabled)}
            chatButtonSize={webWidgetConfig?.chatButtonSize?.toString()}
            chatButtonPosition={{bottom: 8}}
          />
        </div>
      </div>

      <DialogActions>
        <Button variant="contained" color="secondary" size="large" onClick={onClose} disabled={loading}>
          {t('settings_web_widget_manage_form_cancel_btn')}
        </Button>
        <Button
          className="ml-auto"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          disabled={!formChanged || loading || channel.loading}
          loading={loading || channel.loading}
        >
          {t('settings_web_widget_manage_form_save_btn')}
        </Button>
      </DialogActions>
    </>
  );
});

export default WebWidgetSnippetSettings;
