import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import Avatar from 'o-ui/Avatar';
import ChannelRoundIcon from '../../../../components/ChannelRoundIcon';
import {Message, MessageClickEventHandler} from '../../../../stores/Message';
import useStore from '../../../../stores/useStore';
import {getDateOrTime} from '../../../../utils/format';
import useAvatarAttachment from '../../useAvatarAttachment';
import ChatChannelTypeIcon from './ChatItem/ChatChannelTypeIcon';
import ChatLastMessageFileIcon from './ChatItem/ChatLastMessageFileIcon';
import ChatLastMessageReadStatus from './ChatItem/ChatLastMessageReadStatus';
import ChatLastMessageText from './ChatItem/ChatLastMessageText';
import ChatLastMessageUser from './ChatItem/ChatLastMessageUser';

interface FoundMessageItemProps {
  message: Message;
  onClick?: MessageClickEventHandler;
  style?: React.CSSProperties;
}

export const FoundMessageItem: React.FC<FoundMessageItemProps> = observer(({message, onClick, style}) => {
  const {chatsView} = useStore();
  const avatar = chatsView.search.chatForSearch ? message.avatar : message.chat.avatar;
  const {src} = useAvatarAttachment(avatar, message.chat.channelID);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick?.(message);
  };

  const active =
    message.chat.store?.active &&
    message.chat.store?.foundMessageId &&
    message.id.equals(message.chat.store?.foundMessageId);

  const title = chatsView.search.chatForSearch ? message.senderName : message.chat.displayTitle;

  return (
    <div
      id={`found-message-${message.chat.id.toString()}-${message.id.toString()}`}
      className={cn('chat-item found-message-item', {active})}
      style={style}
    >
      <div className={cn('chat-item__body')} onClick={handleClick}>
        {message.isUnknownSender ? (
          <ChannelRoundIcon
            className="chat-item__avatar"
            type={message.chat.channel.type}
          />
        ) : (
          <Avatar
            className="chat-item__avatar"
            src={src}
            name={message.senderName}
          />
        )}
        <div className="chat-item__data">
          <div className="chat-item__head">
            <span className="chat-item__title">{title}</span>

            <ChatChannelTypeIcon className="chat-item__source-icon" type={message.chat.channel?.type} />

            <span className="chat-item__head-right">
              <span className="chat-item__last-message-time">
                {message?.stamp ? getDateOrTime(message?.stamp) : null}
              </span>
              {/* Doesn't have design how to display in found message item */}
              {/* <MessageTagsBar message={message} /> */}
            </span>
          </div>

          <div className="chat-item__last-message d-flex mb-0">
            {chatsView.search.chatForSearch ? null : (
              <ChatLastMessageUser
                className="chat-item__last-message-user pr-2"
                lastChatMessage={message}
              />
            )}

            <ChatLastMessageFileIcon
              className="chat-item__last-message-file-icon mr-2"
              lastChatMessage={message}
            />
            <ChatLastMessageText
              className="chat-item__last-message-text"
              lastChatMessage={message}
            />

            <ChatLastMessageReadStatus
              className="chat-item__last-message-read-status"
              lastChatMessage={message}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default FoundMessageItem;
