import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import moment from 'moment';

import browserStorage from 'o-ui/utils/browserStorage';
import {generatePath} from 'react-router-dom';
import {IConnectionOpenEvent, NetworkErrorResponse, NetworkEvent, NetworkResponse} from '../../api/network';
import {api, entities} from '../../api/proto';
import i18n from '../../i18n';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {authPageOpen, pageIsOpen} from '../../routes/routes';
import {APILayer} from '../../stores/APILayer';
import {equalUint8Arrays, uint8ArrayToBase64} from '../../utils/arrayUtils';
import {AppStore} from '../AppStore';
import {ModalType} from '../ModalType';
import TimerStore from '../TimerStore';
import WorkspaceMember from '../Workspaces/WorkspaceMember';
import User from './User';

const VERIFY_EMAIL_NOTIFICATION_TIMESTAMP_KEY = 'verifyEmailNextTryAtTimestamp';
const DEFAULT_DELAY_VERIFY_EMAIL_NOTIFICATION = 300000; // in ms. 5 minute

export const logoutBroadcastChannel = new BroadcastChannel('logout');

export class UserStore extends APILayer {
  @observable user: User | null = null;

  @observable isInit: boolean = false;

  @action setInit = (init: boolean) => {
    this.isInit = init;
  };

  @computed get userId(): Long | null {
    return this.user?.userId || null;
  }

  @computed get isLoggedIn(): boolean {
    return !!this.user?.isLoggedIn;
  }

  @action setUser = (userData: api.IUserData, userDetails: api.IGetDetailsResponse) => {
    this.user = new User(userData, userDetails, this);
  };

  isCurrentOperator = (operatorID?: Long | null): boolean => {
    return !!(operatorID && this.user?.userId?.equals(operatorID));
  };

  @computed get currentMember(): WorkspaceMember | null {
    return this.app.activeWorkspace.currentMember;
  }

  @computed get isOwner(): boolean {
    return this.currentMember?.isOwner || false;
  }

  @computed get isAdmin(): boolean {
    return this.currentMember?.isAdmin || false;
  }

  @computed get isOperator(): boolean {
    return this.currentMember?.isOperator || false;
  }

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.api.on(NetworkEvent.CONNECTION_OPEN, this.onWsOpen_);
    this.api.on('userResponse.userData', this.handleUserData);

    logoutBroadcastChannel.addEventListener('message', () => {
      this.logoutRequest_();
    });
  }

  protected handleUserData = (userData?: api.IUserData | null) => {
    if (userData?.status && !User.isLoggedIn(userData.status)) {
      console.debug(`%c UserStore.handleUserData`, 'color: orange', userData);
      this.resetLoginSession_();
    }
  };

  private getUserDetailsRequest_ = () => {
    return new api.GetDetailsRequest({
      fields: [
        entities.UserProfileFieldType.UPFT_EMAIL,
        entities.UserProfileFieldType.UPFT_FIRST_NAME,
        entities.UserProfileFieldType.UPFT_LAST_NAME,
      ],
    });
  };

  protected onWsOpen_ = (e: IConnectionOpenEvent) => {
    if (e.initial) {
      this.initLogin_();
    }
  };

  protected initLogin_ = async () => {
    console.debug(`%c -->UserStore:initLogin`, 'color: orange');

    this.setLoading(true);

    const {res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    if (res) {
      this.processUserLogin(res, true);
    }

    this.setLoading(false);
  };

  loginUserTimer = new TimerStore();

  login = async (data: {email: string; password: string}, fromLocation?: Location): Promise<NetworkResponse<api.ILoginResponse>> => {
    if (this.loading) {
      return new NetworkErrorResponse<api.ILoginResponse>('In process');
    }

    const {res, error} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          login: new api.LoginRequest({
            type: entities.AuthType.AT_EMAIL,
            email: data.email,
            password: data.password,
          }),
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    this.loginUserTimer.runByNextTryAtTimestamp(res?.login?.nextTryAtTimestamp);

    if (res && res.login?.status === entities.UserLoginResult.LS_OK) {
      this.processUserLogin(res, false, fromLocation);
    }

    return {error, res: res?.login};
  };

  @action processUserLogin = async (res: api.IUserResponse, init: boolean, fromLocation?: Location) => {
    if (res.userData && res.getDetails) {
      this.setUser(res.userData, res.getDetails);
    }

    const invites = this.app.activeWorkspace.invites;

    if (this.user?.isLoggedIn) {
      if (!invites.registrationInvite || !invites.registrationInvite?.isCurrentUser) {
        invites.clearRegistrationInvite();
      } else {
        await invites.acceptInvite(invites.registrationInvite);
      }

      this.initVerifyEmailNotification();

      const cashedId = this.app.workspaces.getCashedWorkspaceId();
      await this.app.workspaces.load();

      if (!equalUint8Arrays(cashedId, this.app.workspaces.currentWorkspaceId)) {
        this.app.routingHistory.resetToInitState();
      }
    }

    if (res?.newToken?.length && (pageIsOpen(Paths.Root) || pageIsOpen(Paths.Login)) && this.app.workspaces.isInit) {
      if (fromLocation) {
        browserHistory.replace(fromLocation.pathname);
      } else {
        browserHistory.replace(
          generatePath(Paths.PersonalInfo, {
            workspaceId: encodeURIComponent(uint8ArrayToBase64(this.app.activeWorkspace.id)),
          }),
        );
      }
    }

    this.setInit(true);

    if (!init) {
      this.app.anal.loginEvent();
    }
  };

  logout = (noRedirect?: boolean) => {
    logoutBroadcastChannel.postMessage('logout');
    return this.logoutRequest_(noRedirect);
  };

  private logoutRequest_ = async (noRedirect?: boolean) => {
    const res = this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          logout: new api.LogoutRequest({
            type: api.LogoutRequest.LogoutType.LT_CURRENT_TOKEN,
          }),
        }),
      },
      'userResponse',
    );

    this.resetLoginSession_(noRedirect);

    return res;
  };

  @action protected resetLoginSession_ = (noRedirect: boolean = false) => {
    this.app.reset();

    if (!authPageOpen() && !noRedirect) {
      browserHistory.push(Paths.Login);
    }
  };

  registerUserTimer = new TimerStore();

  register = async (
    user: {email: string; password: string;},
    inviteID?: Uint8Array | null,
  ) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          register: new api.RegisterRequest({
            type: entities.AuthType.AT_EMAIL,
            email: user.email,
            password: user.password,
            passwordConfirm: user.password,
            termsAccepted: true,
            inviteID,
          }),
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    this.registerUserTimer.runByNextTryAtTimestamp(res?.register?.nextTryAtTimestamp);

    if (res) {
      this.processRegister_(res);

      this.app.anal.signupEvent();
    }

    return {error, res: res?.register};
  };

  @action protected processRegister_ = async (res: api.IUserResponse) => {
    if (res.userData && res.getDetails) {
      this.setUser(res.userData, res.getDetails);
    }

    if (this.user?.isLoggedIn) {
      await this.initLogin_();
    }

    this.setInit(true);
  };

  forgotPasswordTimer = new TimerStore();

  forgotPassword = async (email: string) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          forgotPassword: new api.ForgotPasswordRequest({
            email,
          }),
        }),
      },
      'userResponse',
    );

    this.forgotPasswordTimer.runByNextTryAtTimestamp(res?.forgotPassword?.nextTryAtTimestamp);

    return {error, res: res?.forgotPassword};
  };

  resetPasswordTimer = new TimerStore();

  resetPassword = async (user: {email: string; code: string; newPassword: string; newPasswordConfirm: string}) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          resetPassword: new api.ResetPasswordRequest({
            email: user.email,
            code: user.code,
            newPassword: user.newPassword,
            newPasswordConfirm: user.newPasswordConfirm,
          }),
        }),
      },
      'userResponse',
    );

    this.resetPasswordTimer.runByNextTryAtTimestamp(res?.resetPassword?.nextTryAtTimestamp);

    return {error, res: res?.resetPassword};
  };

  changePasswordTimer = new TimerStore();

  changePassword = async (data: {currentPassword: string; newPassword: string}) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          changePassword: new api.ChangePasswordRequest({
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
          }),
        }),
      },
      'userResponse',
    );

    this.changePasswordTimer.runByNextTryAtTimestamp(res?.changePassword?.nextTryAtTimestamp);

    return {error, res: res?.changePassword};
  };

  changeEmailTimer = new TimerStore();

  changeEmail = async (data: {newEmail: string; password: string}) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          changeEmail: new api.ChangeEmailRequest({
            newEmail: data.newEmail,
            password: data.password,
          }),
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    this.changeEmailTimer.runByNextTryAtTimestamp(res?.changeEmail?.nextTryAtTimestamp);

    if (res) {
      this.processUpdateUserEmail(res);
    }

    return {error, res: res?.changeEmail};
  };

  @action processUpdateUserEmail = (res: api.IUserResponse) => {
    if (res.getDetails) {
      this.user?.setUserDetails(res.getDetails);
    }
    this.user?.setEmailNotConfirmed(res.userData?.emailNotConfirmed);
  };

  resendConfirmationEmailCodeTimer = new TimerStore();

  resendConfirmationEmailCode = async () => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          resendConfirmEmail: new api.ResendConfirmEmailRequest({
            resend: true,
          }),
        }),
      },
      'userResponse',
    );

    this.resendConfirmationEmailCodeTimer.runByNextTryAtTimestamp(res?.resendConfirmEmail?.nextTryAtTimestamp);

    return {error, res: res?.resendConfirmEmail};
  };

  confirmEmailByVerificationCodeTimer = new TimerStore();

  confirmEmailByVerificationCode = async (code: string) => {
    if (this.loading) {
      return new NetworkErrorResponse<api.IConfirmEmailResponse>('In process');
    }

    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          confirmEmail: new api.ConfirmEmailRequest({
            code,
          }),
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    if (res?.userData) {
      this.processConfirmUserEmail(res.userData);
    }

    if (res?.getDetails) {
      this.processUpdateUserDetails(res?.getDetails);
    }

    this.confirmEmailByVerificationCodeTimer.runByNextTryAtTimestamp(res?.confirmEmail?.nextTryAtTimestamp);

    return {error, res: res?.confirmEmail};
  };

  @action processConfirmUserEmail = (userData: api.IUserData) => {
    this.user?.setEmailNotConfirmed(userData.emailNotConfirmed);
  };

  updateUserDetails = async (details: {firstName: string; lastName: string}) => {
    const {error, res} = await this.request<api.IUserResponse>(
      {
        userRequest: new api.UserRequest({
          changeDetails: new api.ChangeDetailsRequest({
            updates: [
              new entities.UserProfileField({
                type: entities.UserProfileFieldType.UPFT_FIRST_NAME,
                value: new entities.OptValue({
                  strValue: details.firstName,
                }),
              }),
              new entities.UserProfileField({
                type: entities.UserProfileFieldType.UPFT_LAST_NAME,
                value: new entities.OptValue({
                  strValue: details.lastName,
                }),
              }),
            ],
          }),
          getDetails: this.getUserDetailsRequest_(),
        }),
      },
      'userResponse',
    );

    if (res?.getDetails) {
      this.processUpdateUserDetails(res?.getDetails);
    }

    return {error, res: res?.changeDetails};
  };

  processUpdateUserDetails = (res: api.IGetDetailsResponse) => {
    this.user?.setUserDetails(res);
    this.app.externalApiCallHandler.updateUser(this.user);
  };

  @observable emailVerificationSnackbarId: string | null = null;

  @action setEmailVerificationSnackbarId = (id: string | null) => {
    this.emailVerificationSnackbarId = id;
  };

  initVerifyEmailNotification = () => {
    let delay = DEFAULT_DELAY_VERIFY_EMAIL_NOTIFICATION;
    const storedNextShowTimestamp = browserStorage.getItem(VERIFY_EMAIL_NOTIFICATION_TIMESTAMP_KEY);

    const getDelay = (nextTryTimestamp: number): number => {
      return moment.unix(nextTryTimestamp).diff(moment());
    };

    if (storedNextShowTimestamp) {
      const nextTry = getDelay(Number(storedNextShowTimestamp));

      if (nextTry > 0) {
        delay = nextTry;
      }
    }
    let timeout: NodeJS.Timeout;

    const showWithDelay = (delay: number) => {
      timeout = setTimeout(() => {
        if (this.user?.isEmailNotConfirmed && !this.emailVerificationSnackbarId) {
          const snackbarId = this.app.notifications.info(
            i18n.t('settings_personal_info_verify_email_notification_message'),
            i18n.t('settings_personal_info_verify_email_notification_sub_message'),
            {autoHideDuration: null},
            [
              {
                title: i18n.t('settings_personal_info_verify_email_notification_confirm_now'),
                variant: 'contained',
                onClick: () => {
                  this.app.modals.open(ModalType.PROFILE_VERIFICATION_EMAIL, {}, true, true);
                  clearTimeout(timeout);
                },
              },
              {
                title: i18n.t('settings_personal_info_verify_email_notification_later'),
                variant: 'text',
                onClick: () => {
                  const nextShowTimestamp = moment().clone().add(5, 'days').unix();
                  browserStorage.setItem(VERIFY_EMAIL_NOTIFICATION_TIMESTAMP_KEY, nextShowTimestamp.toString());
                  this.app.notifications.closeSnackbar(snackbarId);
                  this.setEmailVerificationSnackbarId(null);

                  if (this.user?.isEmailNotConfirmed) {
                    showWithDelay(getDelay(nextShowTimestamp));
                  } else {
                    clearTimeout(timeout);
                  }
                },
              },
            ],
            true,
          );

          this.setEmailVerificationSnackbarId(snackbarId);
        }
      }, delay);
    };

    if (this.user?.isEmailNotConfirmed) {
      showWithDelay(delay);
    }
  };

  @action reset = () => {
    this.user = null;
    this.isInit = false;
    this.app.externalApiCallHandler.updateUser(null);
  };
}

export default UserStore;
