import cn from 'classnames';
import React from 'react';

import Link from 'o-ui/Link';
import {ICookieInfo} from './cookieCategories';

interface CookieTableRowProps {
  className?: string;
  cookie: ICookieInfo;
}

export const CookieTableRow: React.FC<CookieTableRowProps> = ({
  className,
  cookie,
}) => {
  return (
    <div className={cn('cookie-table__row', className)}>
      <div className="cookie-table__cell body3-regular cookie-table__cell--name">
        {cookie.name}
      </div>
      <div className="cookie-table__cell body3-regular cookie-table__cell--provider">
        {cookie.providerLink ? (
          <Link
            className="body3-regular color-brand-primary text-underline"
            href={cookie.providerLink}
            target="_blank"
          >
            {cookie.provider}
          </Link>
        ) : (
          <span className="body3-regular color-brand-primary">{cookie.provider}</span>
        )}
        <div className="body3-regular color-brand-primary">{cookie.domain}</div>
      </div>
      <div className="cookie-table__cell body3-regular cookie-table__cell--expiration">
        {cookie.expiration}
      </div>
      <div className="cookie-table__cell body3-regular cookie-table__cell--description">
        {cookie.description}
      </div>
    </div>
  );
};

export default CookieTableRow;