import {COMPACT_MODE_MEDIA_QUERY, MOBILE_MODE_MEDIA_QUERY} from '../constants';
import useMedia from './useMedia';

export function useCompactMode(): boolean {
  const compactMode = useMedia(COMPACT_MODE_MEDIA_QUERY);
  const isMobile = useMedia(MOBILE_MODE_MEDIA_QUERY);

  return compactMode && !isMobile;
}

export default useCompactMode;