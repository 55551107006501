export interface ICookieInfo {
  name: string;
  description?: string | null;
  provider?: string | null;
  providerLink?: string | null;
  domain?: string | null;
  expiration?: string | null;
}

export interface ICookieCategory {
  id: string;
  title?: string | null;
  description?: string | null;
  cookies?: ICookieInfo[] | null;
}

export const necessaryCookieCategory: ICookieCategory = {
  id: 'necessary',
  title: 'Strictly Necessary',
  description: 'These are essential for the website to function and cannot be switched of',
  cookies: [
    {
      name: 'greetingsToken',
      description: 'The token of the user authorization session in headchat.',
      provider: '',
      providerLink: '',
      domain: window.location.host,
      expiration: 'Session',
    },
    {
      name: 'ow-sesid',
      description: 'Cookie is set by Headchat to remember user conversation in support system.',
      provider: '',
      providerLink: '',
      domain: window.location.host,
      expiration: 'Session',
    },
    {
      name: 'ow-exuid',
      description: 'Cookie is set by Headchat to remember user conversation in support system.',
      provider: '',
      providerLink: '',
      domain: window.location.host,
      expiration: 'Session',
    },
    {
      name: 'ow-vistm',
      description: 'Cookie is set by Headchat to remember user conversation in support system.',
      provider: '',
      providerLink: '',
      domain: window.location.host,
      expiration: 'Session',
    },
    {
      name: 'CookieScriptConsent',
      description: 'This cookie is used by Headchat.io service to remember visitor cookie consent preferences. It is necessary for Headchat.io cookie banner to work properly.',
      provider: '',
      providerLink: '',
      domain: window.location.host,
      expiration: '1 year',
    },
  ],
};

export const performanceCookieCategory: ICookieCategory = {
  id: 'performance',
  title: 'Performance',
  description: 'These help us understand how visitors interact with the website by collecting and reporting information anonymously.',
  cookies: [
    {
      name: '_ga',
      description: 'This cookie name is associated with Google Universal Analytics - which is a significant update to Google\'s more commonly used analytics service.This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier.It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
      provider: 'Google LLC',
      providerLink: 'https://policies.google.com/privacy',
      domain: window.location.host,
      expiration: '1 year',
    },
    {
      name: '_ga_xxxxxxxxxx',
      description: 'This cookie is used by Google Analytics to persist session state.',
      domain: window.location.host,
      expiration: '1 year',
    },
  ],
};

export const targetingCookieCategory: ICookieCategory = {
  id: 'targeting',
  title: 'Targeting',
  description: 'These cookies are set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites.',
  cookies: [
    {
      name: '_fbp',
      description: 'Used by Meta to deliver a series of advertisement products such as real time bidding from third party advertisers',
      provider: 'Meta Platform Inc.',
      providerLink: 'https://www.facebook.com/policy.php',
      domain: window.location.host,
      expiration: '3 months',
    },
    {
      name: '_gcl_au',
      description: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services',
      provider: 'Google LLC',
      providerLink: 'https://policies.google.com/privacy',
      domain: window.location.host,
      expiration: '3 months',
    },
    {
      name: '_rdt_uuid',
      description: 'This cookie is set by Reddit and is used for remarketing on reddit.com',
      provider: 'Reddit, Inc.',
      providerLink: 'https://www.reddit.com/policies/privacy-policy',
      domain: window.location.host,
      expiration: '3 months',
    },
  ],
};

export const cookieCategories: ICookieCategory[] = [
  necessaryCookieCategory,
  performanceCookieCategory,
  targetingCookieCategory,
];

export default cookieCategories;

export const necessaryCategories = [
  necessaryCookieCategory.id,
];