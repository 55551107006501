import Long from 'long';

import EventEmitter from '../../api/EventEmitter';
import {IUpdate, MCMethodGetChatsPreview, entities} from '../../api/proto';
import {ChannelsUpdaterEvent} from '../Channel/ChannelsUpdater';
import {convertToMessagePreview} from '../Message/utils';
import WorkspaceStore from '../Workspaces/WorkspaceStore';
import ChatsPoolEvent from './ChatsPoolEvent';
import {IRawChat} from './RawChat';
import RawChatsStore from './RawChatsStore';
import RawChatsStoreEvent from './RawChatsStoreEvent';
import RawChatsStoreMode from './RawChatsStoreMode';
import {formatChannelKey} from './utils/formatChannelIdsKey';
import formatStoreKey from './utils/formatStoreKey';


interface IChatsPool {
  getChatsStore: (channelID: Uint8Array[]) => RawChatsStore;
}

export default class ChatsPool extends EventEmitter implements IChatsPool {
  constructor(protected workspace: WorkspaceStore) {
    super();
    workspace.updater.on(ChannelsUpdaterEvent.UPDATE, this.processNewUpdate_);
  }

  private chatsStores_: {[storeKey: string]: RawChatsStore} = {};

  private createChatsStore_ = (
    channelIDs: Uint8Array[],
    storeKey: string,
    mode?: RawChatsStoreMode,
    status?: MCMethodGetChatsPreview.MCChatStatus,
    type?: entities.OzekonChannelType | null,
    userId?: Long | null,
  ): RawChatsStore => {
    console.debug(
      `%c----->ChatsPool.createChatsStore new RawChatsStore`,
      'color: green; font-weight: bold;',
      storeKey,
      mode,
    );
    const newStore = new RawChatsStore(
      this.workspace,
      channelIDs,
      storeKey,
      mode,
      status,
      type,
      userId,
    );

    newStore.on(RawChatsStoreEvent.UPDATE_CHAT, this.onUpdateChat_);
    newStore.on(RawChatsStoreEvent.UPDATE_TOTAL_UNREAD_COUNT, this.onUpdateTotalUnreadCount_);

    return newStore;
  };

  private getMode_ = (
    unreadOnly?: boolean,
    assignedToMeOnly?: boolean,
    assignedToOtherOnly?: boolean,
  ): RawChatsStoreMode | undefined => {
    if (assignedToOtherOnly && unreadOnly) {
      return RawChatsStoreMode.ASSIGNED_TO_OTHER_UNREAD_ONLY;
    }

    if (assignedToMeOnly && unreadOnly) {
      return RawChatsStoreMode.ASSIGNED_TO_ME_UNREAD_ONLY;
    }

    if (assignedToOtherOnly) {
      return RawChatsStoreMode.ASSIGNED_TO_OTHER_ONLY;
    }

    if (assignedToMeOnly) {
      return RawChatsStoreMode.ASSIGNED_TO_ME_ONLY;
    }

    if (unreadOnly) {
      return RawChatsStoreMode.UNREAD_ONLY;
    }

    return undefined;
  };

  public getChatsStore = (
    channelIDs: Uint8Array[],
    unreadOnly?: boolean,
    assignedToMeOnly?: boolean,
    assignedToOtherOnly?: boolean,
    status?: MCMethodGetChatsPreview.MCChatStatus,
    type?: entities.OzekonChannelType | null,
    userId?: Long | null,
  ): RawChatsStore => {
    const mode = this.getMode_(unreadOnly, assignedToMeOnly, assignedToOtherOnly);

    const storeKey = formatStoreKey(channelIDs, mode, status);
    console.debug(`%c----->ChatsPool.getChatsStore`, 'color: gray', storeKey, mode);

    if (!this.chatsStores_[storeKey]) {
      this.chatsStores_[storeKey] = this.createChatsStore_(
        channelIDs,
        storeKey,
        mode,
        status,
        type,
        userId,
      );
    }

    return this.chatsStores_[storeKey];
  };

  public find = (chatId?: Long | null, channelID?: Uint8Array | null): IRawChat | null => {
    if (!chatId) {
      return null;
    }

    let rawChat: IRawChat | null = null;
    const channelKey = formatChannelKey(channelID);

    if (channelKey && this.chatsStores_[channelKey]) {
      rawChat = this.chatsStores_[channelKey].find(chatId);
    }

    if (channelKey && !rawChat) {
      for (const key in this.chatsStores_) {
        if (key.indexOf(channelKey) >= 0) {
          rawChat = this.chatsStores_[key].find(chatId);
          if (rawChat) {
            break;
          }
        }
      }
    }

    if (!channelKey && !rawChat) {
      for (const key in this.chatsStores_) {
        rawChat = this.chatsStores_[key].find(chatId);
        if (rawChat) {
          break;
        }
      }
    }

    return rawChat;
  };

  public get = async (chatId?: Long | null, channelID?: Uint8Array | null): Promise<IRawChat | null> => {
    if (!chatId) {
      return null;
    }

    let rawChat: IRawChat | null = null;
    const channelKey = formatChannelKey(channelID);
    //console.debug(`%c----->ChatsPool.get ${chatId?.toString()} %c ${channelKey}`, 'color: green', 'color: #afb0b6');

    if (channelKey && this.chatsStores_[channelKey]) {
      //console.debug(`%c----->ChatsPool.get in target channel ${chatId?.toString()} %c ${channelKey}`, 'color: green', 'color: #afb0b6');
      rawChat = await this.chatsStores_[channelKey].get(chatId, channelID);
    }

    if (channelKey && !rawChat) {
      //console.debug(`%c----->ChatsPool.get ather channels ${Object.keys(this.chatsStores_).length} ${chatId?.toString()} %c ${channelKey}`, 'color: orange', 'color: #afb0b6', Object.keys(this.chatsStores_));
      for (const key in this.chatsStores_) {
        if (key.indexOf(channelKey) >= 0) {
          //console.debug(`%c----->ChatsPool.get ${chatId?.toString()} %c ${channelKey} in store ${key}`, 'color: orange', 'color: #afb0b6');
          rawChat = await this.chatsStores_[key].get(chatId, channelID);
          if (rawChat) {
            //console.debug(`%c----->ChatsPool.get ${chatId?.toString()} %c ${channelKey} found!!!`, 'color: orange', 'color: #afb0b6', rawChat);
            break;
          }
        }
      }
    }

    if (!channelKey && !rawChat) {
      //console.debug(`%c----->ChatsPool.get no channel ${chatId?.toString()} %c ${channelKey}`, 'color: red', 'color: #afb0b6');
      for (const key in this.chatsStores_) {
        //console.debug(`%c----->ChatsPool.get no channel ${chatId?.toString()} %c ${channelKey} in store ${key}`, 'color: red', 'color: #afb0b6');
        rawChat = await this.chatsStores_[key].get(chatId, channelID);
        if (rawChat) {
          //console.debug(`%c----->ChatsPool.get no channel ${chatId?.toString()} %c ${channelKey} found!!!`, 'color: red', 'color: #afb0b6', rawChat);
          break;
        }
      }
    }

    return rawChat;
  };

  public markAllAsRead = (channelID?: Uint8Array | null) => {
    if (!channelID) {
      return;
    }

    const channelKey = formatChannelKey(channelID);
    for (const key in this.chatsStores_) {
      if (key.indexOf(channelKey) >= 0) {
        this.chatsStores_[key].markAllAsRead(channelID);
      }
    }
  };

  public reset = () => {
    for (const key in this.chatsStores_) {
      this.chatsStores_[key].off(RawChatsStoreEvent.UPDATE_CHAT, this.onUpdateChat_);
      this.chatsStores_[key].off(RawChatsStoreEvent.UPDATE_TOTAL_UNREAD_COUNT, this.onUpdateTotalUnreadCount_);
    }
    this.chatsStores_ = {};
  };

  protected onUpdateChat_ = (rawChatsStore: RawChatsStore, rawChat: IRawChat) => {
    this.emit(RawChatsStoreEvent.UPDATE_CHAT, rawChatsStore, rawChat);
  };

  protected onUpdateTotalUnreadCount_ = (rawChatsStore: RawChatsStore, totalUnreadCount: number) => {
    this.emit(RawChatsStoreEvent.UPDATE_TOTAL_UNREAD_COUNT, rawChatsStore, totalUnreadCount);
  };

  private processNewUpdate_ = async (update: IUpdate) => {
    // console.debug(`%c-->ChatsPool.processNewUpdate_ ${uint8ArrayToBase64(update.channelID)}`, 'color: #afb0b6', update);
    const {channelID} = update;
    const userId = this.workspace.app.userStore.user?.userId;

    if (update.updateChangedReadPointer?.chatID) {
      const {
        chatID,
        operatorID,
        inReadPointer,
        outReadPointer,
        messagesExcludedFromUnreadRange,
      } = update.updateChangedReadPointer;

      const isCurrentUserUpdate = !operatorID || userId?.equals(operatorID);
      const isCurrentUserInReadPointer = !!inReadPointer?.greaterThan(0) && isCurrentUserUpdate;
      const isCurrentUserOutReadPointer = !!outReadPointer?.greaterThan(0);

      if (isCurrentUserInReadPointer && isCurrentUserOutReadPointer) {
        this.emit(
          ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
          {
            id: chatID,
            channelID,
            inReadPointer,
            outReadPointer,
            messagesExcludedFromUnreadRange,
          },
          update,
        );
      } else if (isCurrentUserInReadPointer) {
        this.emit(
          ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
          {
            id: chatID,
            channelID,
            inReadPointer,
            messagesExcludedFromUnreadRange,
          },
          update,
        );
      } else if (isCurrentUserOutReadPointer) {
        this.emit(
          ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
          {
            id: chatID,
            channelID,
            outReadPointer,
          },
          update,
        );
      }
      return;
    }

    if (update.updateNewChatPreview?.chat) {
      this.emit(ChatsPoolEvent.ANY_CHAT_UPDATE, update.updateNewChatPreview?.chat, update);
      return;
    }

    if (update.updateChatChangedRestrictions) {
      const {chatID, restrictions} = update.updateChatChangedRestrictions;
      this.emit(
        ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
        {
          id: chatID,
          channelID,
          restrictions,
        },
        update,
      );
      return;
    }

    if (update.updateNewMessageSuggestions) {
      const {chatID, suggestion} = update.updateNewMessageSuggestions;
      this.emit(
        ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
        {
          id: chatID,
          channelID,
          nextOperatorMessageSuggestion: suggestion,
        },
        update,
      );
      return;
    }

    if (update.updateChannelCounterReset) {
      const {operatorID} = update.updateChannelCounterReset;
      const isCurrentUserUpdate = !operatorID || userId?.equals(operatorID);
      if (!isCurrentUserUpdate) {
        return;
      }

      this.emit(ChatsPoolEvent.CHANNEL_COUNTER_RESET, {channelID});
      return;
    }

    if (update.updateEditedMessage?.message) {
      const {message} = update.updateEditedMessage;
      this.emit(
        ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
        {
          id: message.chatID,
          channelID,
          lastMessage: convertToMessagePreview(message),
          lastMessageSentAt: message.sentAt,
        },
        update,
      );
      return;
    }

    if (update.updateNewMessage?.message) {
      const {message} = update.updateNewMessage;

      let messagesExcludedFromUnreadRange: Long | null | undefined = null;
      const isOperatorsMessage = message.operatorID?.greaterThan(0);
      const isOwnMessage = userId && message.operatorID?.equals(userId);
      if (message.serviceMessage || isOperatorsMessage) {
        const localRawChat = this.find(message.chatID, channelID);
        const foundRawChat = localRawChat || await this.get(message.chatID, channelID);

        if (localRawChat && (!isOwnMessage || message.serviceMessage)) {
          messagesExcludedFromUnreadRange = (foundRawChat?.messagesExcludedFromUnreadRange || Long.ZERO).add(1);
        } else {
          messagesExcludedFromUnreadRange = foundRawChat?.messagesExcludedFromUnreadRange;
        }
      }

      this.emit(
        ChatsPoolEvent.ANY_CHAT_PARTIAL_UPDATE,
        messagesExcludedFromUnreadRange ? {
          id: message?.chatID,
          channelID,
          lastMessage: convertToMessagePreview(message),
          lastMessageSentAt: message.sentAt,
          messagesExcludedFromUnreadRange,
        } : {
          id: message?.chatID,
          channelID,
          lastMessage: convertToMessagePreview(message),
          lastMessageSentAt: message.sentAt,
        },
        update,
      );
      return;
    }

    if (update?.entities?.chats?.length) {
      update?.entities?.chats?.forEach((chat) => {
        this.emit(ChatsPoolEvent.ANY_CHAT_UPDATE, chat, update);
      });
      return;
    }
  };
}