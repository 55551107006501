export enum Paths {
  Default = '/',
  Root = '/',

  Login = '/login',
  Logout = '/logout',
  Registration = '/registration',
  RegistrationComplete = '/registrationComplete',
  RegistrationByInvite = '/invite/:code',
  PrivateRegistration = '/privateRegistration/:code',
  ApprovePrivateRegistration = '/approvePrivateRegistration/:code',
  RegistrationRequest = '/registrationRequest', // with landing settings registrations
  RegistrationRequestComplete = '/registrationRequestComplete', // with landing settings registrations
  RegistrationByInvitation = '/registrationByInvitation/:code',

  EmailVerification = '/emailVerification/:code',
  EmailVerificationComplete = '/emailVerificationComplete',

  ForgotPassword = '/forgotPassword',
  ResetPasswordRedirect = '/resetPassword/:code/:email',
  ResetPasswordNonAuth = '/reset-password-direct/:code/:email',
  ResetPasswordProfile = '/settings-reset-password/:code/:email',
  RecoveryPassword = '/recovery-password/:code/:email',

  Onboarding = '/onboarding',
  QuickOnboarding = '/quickOnboarding',

  Team = '/team',

  DownloadApp = '/downloadApp',

  Chat = '/:workspaceId/chat',
  Channel = '/:workspaceId/chat/:channelId',
  SpecificChat = '/:workspaceId/chat/:channelId/:chatId',
  SpecificMessage = '/:workspaceId/chat/:channelId/:chatId/:messageId',
  UserChat = '/:workspaceId/user/:channelId?/:userId?',

  Settings = '/:workspaceId/settings/:page',
  PersonalInfo = '/:workspaceId/settings/profile',
  Channels = '/:workspaceId/settings/channels/:channelType?/:channelScreenType?',
  ChannelsInstagramCallback = '/channels/instagram/auth-callback',
  Snippets = '/:workspaceId/settings/snippets',
  Tags = '/:workspaceId/settings/tags',
  WorkspaceMembers = '/:workspaceId/settings/members',
  WorkspaceSettings = '/:workspaceId/settings/workspace',

  Billing = '/:workspaceId/settings/billing',
  BillingPaySuccess = '/:workspaceId/settings/billing/pay-success/stripe/:tariffId',
  BillingPayCancel = '/:workspaceId/settings/billing/pay-cancel/stripe/:tariffId',
  BillingTariff = '/:workspaceId/settings/billing/tariff/:tariffId',

  RawBillingTariff = '/settings/billing/tariff/:tariffId',

  BlockedWorkspaceAccess = '/blockedWorkspaceAccess',
  AcceptInvite = '/acceptInvite',
  ExpiredInvite = '/expiredInvite',

  WidgetApiDocs = '/widget-api-docs',

  StarterPage = '/starter',
}

export default Paths;

export type WorkspacePathType =
  | Paths.PersonalInfo
  | Paths.Channels
  | Paths.WorkspaceMembers
  | Paths.Snippets
  | Paths.Billing
  | Paths.BillingPayCancel
  | Paths.BillingPaySuccess
  | Paths.Tags
  | Paths.WorkspaceSettings
  | Paths.Chat;

export type RawPathParams = {
  code?: string;
  messageId?: string;
  chatId?: string;
  channelId?: string;
  channelType?: string;
  channelScreenType?: string;
  tariffId?: string;
  userId?: string;
  email?: string;
}

type WorkspacePathParamsPart = {
  workspaceId?: string;
}

export type WorkspacePathParams = RawPathParams & WorkspacePathParamsPart;

export interface UserChatRouteParams {
  workspaceId?: string;
  channelId?: string;
  chatId?: string;
  userId?: string;
  messageId?: string;
  avatarId?: string;
}