import React, {useEffect, useRef} from 'react';
import Cropper from 'cropperjs';
import cleanImageProps from './cleanImageProps';

const REQUIRED_IMAGE_STYLES = {opacity: 0, maxWidth: '100%'};

export interface ImageCropperElement extends HTMLImageElement {
  cropper: Cropper;
}

type ImageCropperRef =
  | ((instance: HTMLImageElement | ImageCropperElement | null) => void)
  | React.MutableRefObject<HTMLImageElement | ImageCropperElement | null>
  | null;

interface ImageCropperDefaultOptions {
  scaleX?: number;
  scaleY?: number;
  enable?: boolean;
  zoomTo?: number;
  rotateTo?: number;
}

export interface ImageCropperProps
  extends ImageCropperDefaultOptions,
  Cropper.Options<HTMLImageElement>,
  Omit<React.HTMLProps<HTMLImageElement>, 'data' | 'ref' | 'crossOrigin'> {
  crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined;
  on?: (eventName: string, callback: () => void | Promise<void>) => void | Promise<void>;
  onInitialized?: (instance: Cropper) => void | Promise<void>;
}

export const applyDefaultOptions = (cropper: Cropper, options: ImageCropperDefaultOptions = {}): void => {
  const {enable = true, scaleX = 1, scaleY = 1, zoomTo = 0, rotateTo} = options;
  enable ? cropper.enable() : cropper.disable();
  cropper.scaleX(scaleX);
  cropper.scaleY(scaleY);
  rotateTo !== undefined && cropper.rotateTo(rotateTo);
  zoomTo > 0 && cropper.zoomTo(zoomTo);
};

/**
 * sourced from: https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 */
const useCombinedRefs = (...refs: ImageCropperRef[]): React.RefObject<ImageCropperElement> => {
  const targetRef = useRef<ImageCropperElement>(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const ImageCropper = React.forwardRef<ImageCropperElement | HTMLImageElement, ImageCropperProps>(({...props}, ref) => {
  const {
    dragMode = 'crop',
    src,
    style,
    className,
    crossOrigin,
    scaleX,
    scaleY,
    enable,
    zoomTo,
    rotateTo,
    alt = 'picture',
    ready,
    onInitialized,
    ...rest
  } = props;
  const defaultOptions: ImageCropperDefaultOptions = {scaleY, scaleX, enable, zoomTo, rotateTo};
  const innerRef = useRef<HTMLImageElement>(null);
  const combinedRef = useCombinedRefs(ref, innerRef);

  useEffect(() => {
    if (combinedRef.current?.cropper && typeof zoomTo === 'number') {
      combinedRef.current.cropper.zoomTo(zoomTo);
    }
  }, [
    combinedRef,
    zoomTo,
  ]);

  useEffect(() => {
    if (combinedRef.current?.cropper && typeof src !== 'undefined') {
      combinedRef.current.cropper.reset().clear().replace(src);
    }
  }, [
    combinedRef,
    src,
  ]);

  useEffect(() => {
    const currentCombinedRef = combinedRef.current;
    if (currentCombinedRef !== null) {
      const cropper = new Cropper(currentCombinedRef, {
        dragMode,
        ...rest,
        ready: (e) => {
          if (e.currentTarget !== null) {
            applyDefaultOptions(e.currentTarget.cropper, defaultOptions);
          }
          ready?.(e);
        },
      });
      onInitialized?.(cropper);
    }

    return () => {
      currentCombinedRef?.cropper?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    combinedRef,
  ]);

  const imageProps = cleanImageProps({...rest, crossOrigin, src, alt});

  return (
    <div style={style} className={className}>
      <img
        {...imageProps}
        style={REQUIRED_IMAGE_STYLES}
        ref={combinedRef}
      />
    </div>
  );
});

export default ImageCropper;
