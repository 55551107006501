import {
  MAX_IMAGE_UPLOAD_ASPECT_RATIO,
  MAX_IMAGE_UPLOAD_MB,
  MAX_IMAGE_UPLOAD_WIDTH_HEIGHT_SUM,
  MIN_IMAGE_UPLOAD_ASPECT_RATIO,
} from '../../config';
import FileData from './FileData';
import {bytesToMegaBytes} from './fileSize';

export default function validateImageSize(dataFile: FileData): boolean {
  if (!dataFile?.image || !dataFile?.file) {
    return false;
  }

  const fileSizeMb = bytesToMegaBytes(dataFile.file.size || 0);
  const imageRatio = dataFile.image.height / dataFile.image.width;

  if (fileSizeMb > MAX_IMAGE_UPLOAD_MB) {
    return false;
  }

  if (imageRatio > MAX_IMAGE_UPLOAD_ASPECT_RATIO || imageRatio < MIN_IMAGE_UPLOAD_ASPECT_RATIO) {
    return false;
  }

  if (dataFile.image.width + dataFile.image.height > MAX_IMAGE_UPLOAD_WIDTH_HEIGHT_SUM) {
    return false;
  }

  return true;
}