import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import CookieConsent from '../../components/CookieConsent';
import Div100vh from '../../components/Div100vh';
import AuthInfo from './AuthInfo';
import InviteMessage from './InviteMessage';
import PrivateRegistrationForm from './PrivateRegistrationForm';
import useRegistrationInviteLink from './useRegistrationInviteLink';

type RouteParams = {
  code?: string;
};

const PrivateRegistrationPage: React.FC = observer(() => {
  const {code = ''} = useParams<RouteParams>();
  const {t} = useTranslation();

  const invite = useRegistrationInviteLink(code);

  return (
    <Div100vh className="auth-page">
      <div className="auth-page__left-side">
        <AuthInfo />
      </div>

      <div className="auth-page__right-side">
        <div className="h1-bold color-body-primary mt-auto mb-6">{t('registration_title')}</div>

        <InviteMessage invite={invite} className="mb-6" pageType="registration" />

        <PrivateRegistrationForm className="mb-auto" code={code} email={invite?.email} />
      </div>
      <CookieConsent />
    </Div100vh>
  );
});

export default PrivateRegistrationPage;
