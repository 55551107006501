import React from 'react';
import useStore from '../../stores/useStore';
import ConsentDialog from './ConsentDialog';
import CookieButton from './CookieButton';
import CustomizeConsentDialog from './CustomizeConsentDialog';
import {hasConsentShown, isAcceptedOrRejectedConsent, markConsentShown} from './cookiesStateManager';

enum StepType {
  DEFAULT = 0,
  DIALOG = 1,
  CUSTOMIZATION = 2,
}

export function CookieConsent() {
  const [step, setStep] = React.useState(StepType.DEFAULT);
  const {anal} = useStore();

  React.useEffect(() => {
    setStep(isAcceptedOrRejectedConsent() ? StepType.DEFAULT : StepType.DIALOG);

    if (!hasConsentShown()) {
      anal.cookiebannerFirstShownEvent();
    }
    markConsentShown();
  }, [anal]);

  const handleAcceptAllClick = () => {
    setStep(StepType.DEFAULT);
    anal.cookiebannerAcceptAllClick();
  };

  const handleCustomizeClick = () => {
    setStep(StepType.CUSTOMIZATION);
    anal.cookiebannerCustomizeClick();
  };

  const handleRejectAllClick = () => {
    setStep(StepType.DEFAULT);
    anal.cookiebannerRejectAllClick();
  };

  const handleRejectNonEssentialClick = () => {
    setStep(StepType.DEFAULT);
    anal.cookiebannerRejectNonEssentialClick();
  };

  const handleSaveClick = () => {
    setStep(StepType.DEFAULT);
    anal.cookiebannerSaveClick();
  };

  const handleCloseClick = () => {
    setStep(StepType.DEFAULT);
    anal.cookiebannerCloseClick();
  };

  const handleOpenClick = () => {
    setStep(StepType.DIALOG);
    anal.cookiebannerOpenClick();
  };

  switch (step) {
    case StepType.DIALOG:
      return (
        <ConsentDialog
          onAcceptAllClick={handleAcceptAllClick}
          onCustomizeClick={handleCustomizeClick}
          onRejectAllClick={handleRejectAllClick}
          onCloseClick={handleCloseClick}
        />
      );
    case StepType.CUSTOMIZATION:
      return (
        <CustomizeConsentDialog
          onBackClick={() => setStep(StepType.DIALOG)}
          onSaveClick={handleSaveClick}
          onAcceptAllClick={handleAcceptAllClick}
          onRejectNonEssentialClick={handleRejectNonEssentialClick}
          onCloseClick={handleCloseClick}
        />
      );
  }

  return (
    <CookieButton onClick={handleOpenClick} />
  );
}

export default CookieConsent;