import React from 'react';

import Switch from 'o-ui/Switch';
import CookieTable from './CookieTable';
import {ICookieCategory} from './cookieCategories';
import {isNecessaryCookie} from './cookiesStateManager';

interface CookieGroupItemProps {
  cookieGroup: ICookieCategory;
  checked?: boolean;
  onChange?: (cookieGroup: ICookieCategory) => void;
}

export function CookieGroupItem({
  cookieGroup,
  checked,
  onChange,
}: CookieGroupItemProps) {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleChange = () => onChange?.(cookieGroup);
  const handleCollapse = () => setCollapsed(!collapsed);

  return (
    <div className="d-flex flex-row align-items-start bg-white radius-12 border-body-separator p-3 mt-2 w-100">
      <div className="d-flex flex-column flex-wrap w-100">
        <div className="body2-bold">{cookieGroup.title}</div>
        <div className="body2-regular mt-1">{cookieGroup.description}</div>

        {collapsed ? null : (
          <CookieTable
            className="mt-1"
            cookies={cookieGroup.cookies}
          />
        )}

        <div
          className="cursor-pointer body2-regular color-brand-primary d-flex flex-row align-items-center mt-1"
          onClick={handleCollapse}
        >
          {collapsed ? 'Show cookies' : 'Hide cookies'}
          {collapsed ? (
            <i className="tg-icon tg-icon-chevron-down-line font-size-6 ml-2" />
          ) : (
            <i className="tg-icon tg-icon-chevron-up-line font-size-6 ml-2" />
          )}
        </div>
      </div>
      <div className="pl-4 ml-auto">
        <Switch
          checked={checked || isNecessaryCookie(cookieGroup)}
          onChange={handleChange}
          disabled={isNecessaryCookie(cookieGroup)}
        />
      </div>
    </div>
  );
}

export default CookieGroupItem;