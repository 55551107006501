import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import Button from 'o-ui/Button';
import WorkspaceAvatar from '../../components/WorkspaceAvatar';
import Paths, {navigateToWorkspace} from '../../routes/Paths';
import ModalType from '../../stores/ModalType';
import useStore from '../../stores/useStore';

import logotypeWhite from '../../assets/images/logotype-white.svg';
import logotype from '../../assets/images/logotype.svg';

export const BlockedWorkspaceAccess: React.FC = observer(() => {
  const {t} = useTranslation();

  const {activeWorkspace, modals, workspaces, lightThemeChosen} = useStore();
  const workspacesList = workspaces.list;

  const handleOpenWorkspaceCreating = () => {
    modals.open(ModalType.WORKSPACE_CREATING);
  };

  const handleSelectWorkspace = (workspace) => {
    navigateToWorkspace(workspace.id);
  };

  return (
    <div className="blocked-workspace">
      <div className="blocked-workspace__header">
        <img
          className="auth-info__logo-img"
          src={lightThemeChosen ? logotype : logotypeWhite}
          alt=""
        />
      </div>

      <div className="blocked-workspace__content">
        <div className="blocked-workspace__title">{t('workspace_blocked_access_page_title')}</div>
        <div className="blocked-workspace__sub-title">
          {activeWorkspace.isBlocked ? t('workspace_blocked_access_page_sub_title_for_workspace', {
            name: activeWorkspace.name
          }) : t('workspace_blocked_access_page_sub_title')}
        </div>
        <div className="blocked-workspace__description">
          <p>
            {activeWorkspace.isBlocked ? t('workspace_blocked_access_page_restricted_info_for_workspace', {
              name: activeWorkspace.name
            }) : t('workspace_blocked_access_page_restricted_info')}
          </p>
          <p>{t('workspace_blocked_access_page_ask_admin_info')}</p>
        </div>

        <div className="mt-7">
          {workspacesList.length ? (
            <div className="workspaces-list">
              {workspacesList.map((workspace) => (
                <button
                  key={workspace.uuid}
                  data-workspace-id={workspace.uuid}
                  data-workspace-id-b64={workspace.base64Id}
                  type="button"
                  className="button-base workspaces-list__menu-item d-flex"
                  onClick={() => handleSelectWorkspace(workspace)}
                >
                  <WorkspaceAvatar
                    className="workspaces-list__menu-item-avatar mr-3"
                    size="xs"
                    workspace={workspace}
                  />
                  <span
                    className="workspaces-list__menu-item-name body2-bold color-body-primary text-truncate"
                    title={workspace.displayName}
                  >
                    {workspace.displayName}
                  </span>
                  <i className="tg-icon-chevron-right-line body4-bold ml-auto mr-3"></i>
                </button>
              ))}
            </div>
          ) : null}
        </div>
        <div className="mt-7 d-flex flex-row">
          {workspacesList.length ? null : (
            <Button
              className="mr-3"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleOpenWorkspaceCreating}
            >
              {t('workspace_blocked_access_page_create_workspace_btn')}
            </Button>
          )}
          <Button
            variant="outlined"
            color="tertiary"
            size="large"
            component={Link}
            to={Paths.Logout}
          >
            {t('workspace_blocked_access_page_logout_btn')}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default BlockedWorkspaceAccess;
