import React from 'react';
import CookieGroupItem from './CookieGroupItem';
import cookieCategories, {ICookieCategory} from './cookieCategories';

interface CookieGroupListProps {
  selected?: string[],
  onChange?: (cookieGroup: ICookieCategory) => void;
}

export function CookieGroupList({
  selected,
  onChange,
}: CookieGroupListProps) {
  return (
    <>
      {cookieCategories.map((cookieGroup) => (
        <CookieGroupItem
          key={cookieGroup.id}
          cookieGroup={cookieGroup}
          onChange={onChange}
          checked={selected?.includes(cookieGroup.id)}
        />
      ))}
    </>
  );
}

export default CookieGroupList;