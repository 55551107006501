import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';

import successTickImg from '../assets/images/check_icon.png';
import useMobileMode from '../hooks/useMobileMode';
import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate';
import ResetPasswordForm from '../pages/Auth/ResetPasswordForm';
import Paths from '../routes/Paths';
import {ModalItem} from '../stores/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

enum ResetPasswordStep {
  SET_NEW_PASSWORD,
  SUCCESS,
}

export const ModalProfileResetPassword: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);
  const isMobile = useMobileMode();

  const workspaceNavigate = useWorkspaceNavigate();

  const [step, setStep] = React.useState<ResetPasswordStep>(ResetPasswordStep.SET_NEW_PASSWORD);

  const onClose = () => {
    workspaceNavigate(Paths.PersonalInfo);
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const onChangedPassword = () => {
    setStep(ResetPasswordStep.SUCCESS);
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('reset_password_title')}
      className="modal-profile-reset-password"
      maxWidth="md"
      onClose={onClose}
    >
      <DialogContent className="px-6 pt-6" style={isMobile ? {} : {width: '600px'}}>
        {step === ResetPasswordStep.SET_NEW_PASSWORD ? (
          <ResetPasswordForm
            className="mb-5"
            code={props.modal.data.code || ''}
            email={props.modal.data.email || ''}
            isProfile
            onSuccess={onChangedPassword}
          />
        ) : (
          <div className="modal-profile-forgot-password-success">
            <div className="title-with-icon">
              <img className="icon" src={successTickImg} alt="" />
              <div className="text h3-medium pb-3">{t('reset_password_success_title')}</div>
            </div>
            <div className="mb-auto">
              <div className="auth-page__sub-title">{t('reset_password_success_subtitle')}</div>
            </div>
            <DialogActions className="p-0 pt-6 pb-6">
              <Button variant="contained" color="primary" size="large" onClick={handleCloseClick}>
                {t('modal_close_btn')}
              </Button>
            </DialogActions>
          </div>
        )}
      </DialogContent>
    </ModalDialog>
  );
});

export default ModalProfileResetPassword;
