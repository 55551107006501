import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';

import useStore from '../../../stores/useStore';
import BillingStatus from './BillingStatus';
import NextPaymentInfo from './NextPaymentInfo';

interface BillingSubscriptionInfoProps {
  className?: string;
}

export const BillingSubscriptionInfo: React.FC<BillingSubscriptionInfoProps> = observer(({
  className,
}) => {
  const {activeWorkspace: {billing}} = useStore();

  return billing.showSubscriptionInfo ? (
    <div
      className={cn(
        'bg-background-primary d-flex flex-column rounded-0 rounded-sm-3 border-primary',
        className,
      )}
    >
      <BillingStatus className="border-primary-bottom" />

      <NextPaymentInfo className="w-100" />
    </div>
  ) : null;
});

export default BillingSubscriptionInfo;