import cn from 'classnames';
import React from 'react';
import {observer} from 'mobx-react';

import AvatarWrapper from '../../../../components/AvatarWrapper';
import useCompactMode from '../../../../hooks/useCompactMode';
import Chat from '../../../../stores/Chat';


interface ChatProfileInfoProps {
  className?: string;
  chat: Chat;
}

export const ChatProfileInfo = observer(({className, chat}: ChatProfileInfoProps) => {
  const compactMode = useCompactMode();

  return (
    <div className={cn('chat-profile-info', className)}>
      <AvatarWrapper
        size={compactMode ? 'md' : 'lg'}
        className="chat-profile-info__avatar"
        attachment={chat?.avatar}
        channelID={chat?.channelID}
        name={chat.displayTitle}
      />

      <div className="chat-profile-info__body">
        <div className="chat-profile-info__title">{chat.displayTitle}</div>
      </div>
    </div>
  );
});

export default ChatProfileInfo;
