import cn from 'classnames';
import React from 'react';
import {ICookieInfo} from './cookieCategories';
import CookieTableRow from './CookieTableRow';


interface CookieTableProps {
  className?: string;
  cookies?: ICookieInfo[] | null;
}

export const CookieTable: React.FC<CookieTableProps> = ({
  className,
  cookies,
}) => {

  return (
    <div className={cn('cookie-table', className)}>
      <div className="cookie-table__row cookie-table__row--head">
        <div className="cookie-table__cell label-3 cookie-table__cell--name">
          Name
        </div>
        <div className="cookie-table__cell label-3 cookie-table__cell--provider">
          Provider
        </div>
        <div className="cookie-table__cell label-3 cookie-table__cell--expiration">
          Expiration
        </div>
        <div className="cookie-table__cell label-3 cookie-table__cell--description">
          Description
        </div>
      </div>
      <div className="cookie-table__wrapper">
        {cookies?.map((cookie) => (
          <CookieTableRow
            key={cookie.name}
            cookie={cookie}
          />
        ))}
      </div>
    </div>
  );
};

export default CookieTable;
