import React from 'react';

import AvatarWrapper from '../../../../components/AvatarWrapper';
import Chat from '../../../../stores/Chat';
import {MENTION_SYMBOL} from '../../../../stores/EditorStore';
import Peer from '../../../../stores/Peer';


interface PeersListItemProps {
  chat: Chat;
  peer: Peer;
  onClick?: (peer: Peer) => void;
}

export const PeersListItem: React.FC<PeersListItemProps> = React.memo(({
  chat,
  peer,
  onClick,
}) => {
  const handleClick = () => onClick?.(peer);

  return (
    <div className="peers-list__item" onClick={handleClick}>
      <div className="peers-list__item_icon">
        <AvatarWrapper
          attachment={peer?.avatar}
          channelID={chat?.channelID}
          name={peer.fullName}
        />
      </div>
      {peer.fullName ? <div className="peers-list__item_name">{peer.fullName}</div> : null}

      {peer.username ? (
        <div className="peers-list__item_username">{`${MENTION_SYMBOL}${peer.username}`}</div>
      ) : null}
    </div>
  );
});

export default PeersListItem;
