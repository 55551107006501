import {observer} from 'mobx-react-lite';
import React from 'react';
import {Navigate, generatePath, useMatch} from 'react-router-dom';

import Paths from '../../routes/Paths';
import useStore from '../../stores/useStore';


export const ResetPasswordRedirect: React.FC = observer(() => {
  const {userStore} = useStore();

  const match = useMatch(Paths.ResetPasswordRedirect);

  const code = match?.params.code || '';
  const email = match?.params.email || '';

  if (!userStore?.user?.isInit) {
    return null;
  }

  if (userStore?.user.isLoggedIn) {
    return (
      <Navigate
        to={
          userStore?.user?.email?.toLowerCase() === email.toLowerCase()
            ? generatePath(Paths.ResetPasswordProfile, {code, email})
            : generatePath(Paths.RecoveryPassword, {code, email})
        }
        replace
      />
    );
  } else {
    return (
      <Navigate
        to={generatePath(Paths.ResetPasswordNonAuth, {code, email})}
        replace
      />
    );
  }
});

export default ResetPasswordRedirect;