import Cropper from 'cropperjs';
import React from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import DialogContent from 'o-ui/Dialog/DialogContent';

import {ImageCropper, ImageCropperElement} from '../components/ImageCropper';
import ValueSlider from '../components/ValueSlider';
import {ModalItem} from '../stores/ModalsStore';
import blobToFile from '../utils/file/blobToFile';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

function convertPercentsToRatio(value: number, minRatio: number) {
  return minRatio + (value / 100);
}
function convertRatioToPercents(value: number, minRatio: number) {
  return Math.round((value - minRatio) * 100);
}

interface IProps {
  modal: ModalItem;
}

export const ModalUploadAvatar = (props: IProps) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const dataFile = props.modal.data.dataFiles?.find((dataFile) => dataFile.image);

  const cropperRef = React.useRef<ImageCropperElement>(null);
  const [zoomPercents, setZoomPercents] = React.useState<number>(0);
  const initRatio = React.useRef<number>(1);

  const onInitialized = () => {
    const cropper = cropperRef.current?.cropper;
    const imageData = cropper?.getImageData();
    const height = imageData?.height || 0;
    const width = imageData?.width || 0;

    const size = height < width ? height : width;
    const top = height > width ? (height - width) / 2 : 0;
    const left = height < width ? (width - height) / 2 : 0;

    initRatio.current = getZeroRatio();

    cropper?.setCropBoxData({
      top,
      left,
      height: size,
      width: size,
    });
  };

  const getZeroRatio = () => {
    const imageData = cropperRef.current?.cropper?.getImageData();
    return (imageData?.height || 0) / (imageData?.naturalHeight || 0);
  };

  const onZoom = (val) => {
    const ratio = convertPercentsToRatio(val, initRatio.current);
    cropperRef.current?.cropper.zoomTo(ratio);
  };

  const handleZoom = (e: Cropper.ZoomEvent) => {
    const percents = convertRatioToPercents(e.detail.ratio, initRatio.current);
    if (percents >= 0) {
      setZoomPercents(percents);
    }
  };

  const handleSubmit = () => {
    if (!dataFile) return;

    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper?.getCroppedCanvas();
    const cropBoxData = cropper?.getCropBoxData();

    if (cropBoxData?.width !== dataFile?.width || cropBoxData?.height !== dataFile?.height) {
      croppedCanvas?.toBlob((blob) => {
        if (!blob) return;

        props.modal.trigger('submit', {
          dataFiles: [{
            ...dataFile,
            file: blobToFile(blob, dataFile.fileName),
            objectUrl: URL.createObjectURL(blob),
          }],
        });
        handleCloseClick();
      }, dataFile?.mimeType);

    } else {
      props.modal.trigger('submit', {dataFiles: [dataFile]});
      handleCloseClick();
    }
  };

  const handleChange = () => {
    props.modal.trigger('change', props.modal.data);
    handleCloseClick();
  };

  const handleCloseClick = () => {
    modalRef.current?.close();
  };


  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      title={t('Upload Logo')}
      maxWidth="smr"
      fullWidth={true}
      scroll="body"
      PaperProps={{
        className: 'modal--upload overflow-initial',
      }}
    >
      <DialogContent className="p-6 d-flex flex-column justify-content-center">
        <div
          className="p-4 d-flex justify-content-center position-relative radius-8"
          style={{backgroundColor: '#0d2548'}}
        >
          {dataFile ? (
            <ImageCropper
              src={dataFile.objectUrl}
              style={{maxHeight: '50vh', maxWidth: '100%'}}
              initialAspectRatio={1}
              viewMode={2}
              aspectRatio={1}
              guides={false}
              center={true}
              autoCrop={true}
              ref={cropperRef}
              zoom={handleZoom}
              zoomOnWheel={false}
              ready={onInitialized}
            />
          ) : null}
        </div>

        <ValueSlider
          className="mt-5"
          value={zoomPercents}
          onChange={onZoom}
          step={10}
        />
      </DialogContent>
      <div className="px-6 pb-6 d-flex align-items-center justify-content-space-between">
        <Button className="mr-auto" variant="contained" color="tertiary" size="large" onClick={handleChange}>
          {t('Change')}
        </Button>
        <Button className="mr-3" variant="contained" color="secondary" size="large" onClick={handleCloseClick}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </div>
    </ModalDialog>
  );
};

export default ModalUploadAvatar;
