import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import PhoneInput from 'o-ui/Input/PhoneInput';
import Select, {SelectChangeEvent} from 'o-ui/Select';

import {IMCChannelShownFields, TelegramAuthStageResponse, entities} from '../../../../api/proto';
import SnackMessage, {SnackMessageProps} from '../../../../components/SnackMessage';
import useChannelTypesList from '../../../../hooks/useChannelTypesList';
import useMountedState from '../../../../hooks/useMountedState';
import Channel from '../../../../stores/Channel';
import useStore from '../../../../stores/useStore';
import getQueryStringParam from '../../../../utils/getQueryStringParam';

interface IProps {
  title?: string;
  subTitle?: string;
  type: entities.OzekonChannelType;
  newChannel?: Channel | null;
  codeHash?: string | null;
  onChangeForm?: () => void;
  onChangeType?: (type: entities.OzekonChannelType) => void;
  onSubmit?: (newChannel: Channel, codeHash?: string | null) => void;
  reactivationMode?: boolean;
}

export const TelegramDefinitionForm = observer((props: IProps) => {
  const isMounted = useMountedState();
  const {t} = useTranslation();
  const {channels, notifications} = useStore();
  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const channelTypesList = useChannelTypesList();

  const [status, setStatus] = React.useState<TelegramAuthStageResponse.EnterPhone.Result | null | undefined>();

  const telegram = getQueryStringParam('emulate') ? channels.telegramEmulator : channels.telegram;

  const openPhoneNumberConfirmation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.newChannel) {
      props.onSubmit?.(props.newChannel, props.codeHash);
    }
  };

  const createChannel = async (data: {phone: string; shownFields?: IMCChannelShownFields | null}) => {
    setApiError('');
    const {error, res, channel} = await telegram.createChannel({
      shownFields: data.shownFields,
      phone: data.phone,
    });

    if (!isMounted()) {
      return;
    }

    if (error) {
      setApiError(error.message);
      notifications.error(error.message);
    }

    const status = res?.telegramAuthFlow?.auth?.enterPhone?.status;

    if (status === TelegramAuthStageResponse.EnterPhone.Result.CODE_SENT) {
      if (channel) {
        props.onSubmit?.(channel, res?.telegramAuthFlow?.auth?.enterPhone?.codeHash);
      }
      setStatus(null);
    } else {
      setStatus(status);
    }
  };

  const reactivateChannel = async (data: {channel: Channel; phone: string}) => {
    setApiError('');
    const {error, res} = await telegram.reactivateChannel({channel: data.channel, phone: data.phone});

    if (!isMounted()) {
      return;
    }

    if (error) {
      setApiError(error.message);
      notifications.error(error.message);
    }

    const status = res?.status;

    if (status === TelegramAuthStageResponse.EnterPhone.Result.CODE_SENT) {
      props.onSubmit?.(data.channel, res?.codeHash);

      setStatus(null);
    } else {
      setStatus(status);
    }
  };

  const formikCreate = useFormik({
    initialValues: {
      name: props.newChannel?.name || '',
      phone: props.newChannel?.phone || '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string().required(t('settings_channel_creation_form_channel_name_required')),
      phone: Yup.string().required(t('settings_channel_creation_form_channel_phone_required')),
    }),
    onSubmit: async (data) => {
      if (props.reactivationMode) {
        if (props.newChannel) {
          await reactivateChannel({channel: props.newChannel, phone: data.phone});
        }
      } else {
        await createChannel({phone: data.phone, shownFields: {name: data.name}});
      }
    },
  });

  const handleChangeAccountName = (e: React.ChangeEvent) => {
    props.onChangeForm?.();
    formikCreate.handleChange(e);
  };

  const handleChangePhoneNumber = (e: {currentTarget: {name?: string; value: string}}) => {
    if (props.newChannel?.phone !== e.currentTarget.value) {
      props.onChangeForm?.();
    }
    formikCreate.setFieldValue('phone', e.currentTarget.value, false);
  };

  const handleChangeType = (e: SelectChangeEvent) => {
    const type = parseFloat(e.target.value);
    props.onChangeType?.(type);
  };

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13) {
      formikCreate.submitForm();
    }
  };

  const getNotification = ({message, subMessage, ...rest}: Partial<SnackMessageProps>): JSX.Element => {
    return (
      <SnackMessage
        className="mb-3"
        message={message}
        subMessage={subMessage}
        variant="error"
        hideCloseIcon
        {...rest}
      />
    );
  };

  const getStatusNotification = (status?: TelegramAuthStageResponse.EnterPhone.Result | null): JSX.Element | null => {
    switch (status) {
      case TelegramAuthStageResponse.EnterPhone.Result.CODE_SENT:
        return getNotification({message: 'notification_telegram_phone_blocked_message', variant: 'success'});
      case TelegramAuthStageResponse.EnterPhone.Result.PHONE_BANNED:
        return getNotification({
          message: t('notification_telegram_phone_blocked'),
          subMessage: t('notification_telegram_phone_blocked_description'),
        });
      case TelegramAuthStageResponse.EnterPhone.Result.PHONE_INCORRECT:
        return getNotification({
          message: t('notification_telegram_need_to_register'),
          subMessage: t('notification_telegram_need_to_register_description'),
        });
      case TelegramAuthStageResponse.EnterPhone.Result.PHONE_RATE_LIMIT:
        return getNotification({
          message: t('notification_telegram_many_requests'),
          subMessage: t('notification_telegram_many_requests_description'),
        });
      case TelegramAuthStageResponse.EnterPhone.Result.UNKNOWN:
        return getNotification({
          message: t('notification_telegram_auth_error'),
          subMessage: t('notification_telegram_auth_error_description'),
        });
      default:
        return null;
    }
  };

  return (
    <>
      <form
        className="channel-dialog-form__form custom-scroll"
        onSubmit={formikCreate.handleSubmit}
        onKeyDown={handleFormKeyDown}
      >
        {props.title ? <div className="h1-bold color-body-primary overflow-hidden overflow-ellipsis">{props.title}</div> : null}
        {props.subTitle ? <div className="body1-regular color-body-primary pb-6">{props.subTitle}</div> : null}

        {getStatusNotification(status)}

        {props.onChangeType ? (
          <Select
            className="w-100 mb-5"
            selectClassName="w-100"
            label={t('settings_channel_creation_form_channel_label')}
            onChange={handleChangeType}
            value={props.type?.toString()}
            items={channelTypesList}
            required
          />
        ) : null}

        <FormLabel className="body3-regular color-body-tertiary mb-2">
          {t('settings_channel_creation_form_channel_name_label')}
        </FormLabel>
        <OutlinedTextInput
          className="w-100"
          name="name"
          placeholder={t('settings_channel_creation_form_channel_name_placeholder')}
          onChange={handleChangeAccountName}
          onBlur={formikCreate.handleBlur}
          value={formikCreate.values.name}
          errorHelper={formikCreate.errors.name || ' '}
          required
          disabled={props.reactivationMode}
        />

        <FormLabel className="body3-regular color-body-tertiary mb-2 text-capitalize">
          {t('settings_channel_creation_form_channel_phone_label')}
        </FormLabel>
        <PhoneInput
          className="w-100"
          name="phone"
          variant="outlined"
          onChange={handleChangePhoneNumber}
          onBlur={formikCreate.handleBlur}
          value={formikCreate.values.phone}
          required
          errorHelper={apiError || formikCreate.errors.phone || ' '}
          disabled={props.reactivationMode}
        />
      </form>
      <div className="channel-dialog-form__footer">
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={formikCreate.submitForm}
          loading={telegram.loading}
        >
          {t('settings_channel_creation_form_send_code')}
        </Button>
        {props.newChannel ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="ml-auto"
            onClick={openPhoneNumberConfirmation}
          >
            {t('settings_channel_creation_form_enter_code')}
          </Button>
        ) : null}
      </div>
    </>
  );
});

export default TelegramDefinitionForm;
